import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableHead, TableRow, TableCell, TablePagination, TableSortLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      // backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
    parentTableHeaderStyle: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
}));

export default function useTable(records, headCell, filterFn) {
  const classes = useStyles();

  const pages = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TablContainer = (props) => (
    // <Table className={classes.table} stickyHeader aria-label="sticky table">
    //     {props.children}
    // </Table>
    <Table className={classes.table}>{props.children}</Table>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow loader="bubble-spin" color="#21309D" size={9}>
          <TableCell> </TableCell>
          <TableCell key={headCell[0].id} align="left" sortDirection={orderBy === headCell[0].id ? order : false}>
            {headCell[0].disableSorting ? (
              headCell[0].label
            ) : (
              <TableSortLabel
                active={orderBy === headCell[0].id}
                style={{ fontWeight: 'bold' }}
                direction={orderBy === headCell[0].id ? order : 'asc'}
                onClick={() => {
                  handleSortRequest(headCell[0].id);
                }}
              >
                {headCell[0].label}
              </TableSortLabel>
            )}
          </TableCell>

          <TableCell key={headCell[1].id} align="center" sortDirection={orderBy === headCell[1].id ? order : false}>
            {headCell[1].disableSorting ? (
              headCell[1].label
            ) : (
              <TableSortLabel
                active={orderBy === headCell[1].id}
                style={{ fontWeight: 'bold' }}
                direction={orderBy === headCell[1].id ? order : 'asc'}
                onClick={() => {
                  handleSortRequest(headCell[1].id);
                }}
              >
                {headCell[1].label}
              </TableSortLabel>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const newOrder = comparator(a[0], b[0]);
      if (newOrder !== 0) return newOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, newOrderBy) {
    if (b[newOrderBy] < a[newOrderBy]) {
      return -1;
    }
    if (b[newOrderBy] > a[newOrderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(newOrder, newOrderBy) {
    return newOrder === 'desc'
      ? (a, b) => descendingComparator(a, b, newOrderBy)
      : (a, b) => -descendingComparator(a, b, newOrderBy);
  }

  const recordsAfterPagingAndSorting = () => {
    return stableSort(filterFn.fn(records), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage,
    );
  };

  return {
    TablContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
  };
}
