import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
// import Box from '@mui/material/Box';
// import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';
import AxiosInstance from '../../api/axios';
import SectorTable from './AnalyticTableHead';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  subTableHeader: {
    backgroundColor: '#F0F0F0',
    fontWeight: 'bold',
  },
  subTableContent: {
    backgroundColor: '#F8F8F8',
  },
  parentTableHeaderStyle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
});

// eslint-disable-next-line react/function-component-definition
export default function AnalyticsReport(props) {
  const [analyticsReport, setAnalyticsReport] = useState([]);
  const [loaderIndex, setloaderIndex] = useState(-1);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const classes = useRowStyles();

  const headCells = [
    { id: 'reportName', label: 'Report' },
    { id: 'published_report', label: 'Published Date' },
    { id: 'count', label: 'No. of Downloads' },
  ];

  const {
    TablContainer,
    TblHead,
    // recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
  } = SectorTable(analyticsReport, headCells, filterFn);

  const loadSector = async () => {
    const url = `analytics/report?day=${props.filterValue}`;
    AxiosInstance.get(url)
      .then(function (response) {
        const finalRes = response.data.data;
        // eslint-disable-next-line array-callback-return
        finalRes.map((item) => {
          // eslint-disable-next-line no-param-reassign
          item.extraData = [];
          // eslint-disable-next-line no-param-reassign
          item.showCollapse = false;
        });
        setAnalyticsReport(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const getCollapseData = (report, index, isOpen) => {
    setloaderIndex(index);
    const reportList = _.cloneDeep(stableSort(analyticsReport, getComparator(order, orderBy)));
    // let reportList = _.cloneDeep(analyticsReport);
    if (!isOpen) {
      const isData = reportList[index].extraData;
      if (isData && isData.length) {
        reportList[index].showCollapse = true;
        setAnalyticsReport(reportList);
        setloaderIndex(-1);
      } else {
        const url = `/analytics/userList?sc_report_id=${report.sc_report_id}&type=${report.type}&day=${props.filterValue}`;
        AxiosInstance.get(url)
          .then(function (response) {
            reportList[index].extraData = response.data.data;
            reportList[index].showCollapse = true;
            setAnalyticsReport(reportList);
            setloaderIndex(-1);
          })
          .catch(function (error) {
            setloaderIndex(-1);
          });
      }
    } else {
      reportList[index].showCollapse = false;
      setAnalyticsReport(reportList);
      setloaderIndex(-1);
    }
  };

  const renderRow = (report, rowIndex) => {
    const isOpen = !!report.showCollapse;
    return (
      <Fragment key={rowIndex}>
        <TableRow className={classes.root}>
          <TableCell>
            <span>
              {loaderIndex === rowIndex ? (
                <CircularProgress size={25} />
              ) : (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => getCollapseData(report, rowIndex, isOpen)}
                >
                  {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )}
            </span>
          </TableCell>
          <TableCell component="th" scope="row">
            {report.reportName}
          </TableCell>
          <TableCell align="center">{report.published_report}</TableCell>

          <TableCell align="center">{report.count}</TableCell>
        </TableRow>
        {isOpen && report && report.extraData && report.extraData.length ? (
          <>
            <TableRow className={classes.subTableHeader}>
              <TableCell />
              <TableCell component="th" scope="row" style={{ fontSize: '13px', fontWeight: 'bold' }}>
                Name
              </TableCell>
              <TableCell style={{ fontSize: '13px', fontWeight: 'bold' }} align="center">
                Date Of Download
              </TableCell>
              <TableCell align="center" />
            </TableRow>
            {report.extraData.map((subItem, index) => {
              return (
                <TableRow className={classes.subTableContent} key={index}>
                  <TableCell />
                  <TableCell component="th" scope="row">
                    {`${subItem.first_name} ${subItem.last_name}`}
                  </TableCell>
                  <TableCell align="center">{moment(subItem.d_Date).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  <TableCell />
                </TableRow>
              );
            })}
          </>
        ) : null}
      </Fragment>
    );
  };

  useEffect(() => {
    loadSector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadSector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterValue]);

  return (
    <div hidden={props.value !== props.index} className="Analytic_table">
      {props.value === props.index && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            {/* <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell className={classes.parentTableHeaderStyle}>Report</TableCell>
            <TableCell align="center" className={classes.parentTableHeaderStyle}>Published Date</TableCell>
            <TableCell align="center" className={classes.parentTableHeaderStyle}>No. of Downloads</TableCell>
          </TableRow>
        </TableHead> */}
            <TblHead />
            <TableBody>
              {stableSort(analyticsReport, getComparator(order, orderBy)).map((item, index) => renderRow(item, index))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
