import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCompnayForm from './AddCompnayForm';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
  dialogWrapper: {
    maxWidth: '748px',
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function PopUpCompany(props) {
  const classes = useStyles();
  const [mobileMenuAnchorE1, setMobileMenuAnchorE1] = useState(null);
  const {
    title,
    childern,
    openPopup,
    setOpenPopUp,
    editRecord,
    loadCompany,
    setOpen,
    open,
    getSectorList,
    countryList,
  } = props;

  const closeMobileMenu = (event) => {
    setMobileMenuAnchorE1(null);
  };
  if (editRecord !== null) {
    const recordLength = editRecord.reports.length;
  }

  return (
    <>
      {editRecord === null && (
        <div className="PopupModel">
          {/* <Dialog open ={openPopup} classes={{ paper: classes.dialogWrapper }} maxWidth="sm" fullWidth */}
          <Dialog open={openPopup} classes={{ paper: classes.dialogWrapper }}>
            {/* <ActionButton 
                color="secondary"
                onClick={() => {setOpenPopUp(false)}}
                > */}
            {/* </ActionButton> */}
            <DialogTitle style={{ paddingTop: '3%', paddingLeft: '3%' }}>
              <Box
                onClick={() => {
                  setOpenPopUp(false);
                }}
              >
                <CloseIcon style={{ float: 'right' }} />
              </Box>
              <Typography variant="h5" component="span" style={{ paddingLeft: '4%' }}>
                Company
              </Typography>
            </DialogTitle>
            <DialogContent className="PopupModel" dividers>
              {/* <AddCompnayForm  openPopup ={openPopup} setOpenPopUp ={setOpenPopUp}loadSector={loadSector} editRecord={editRecord} sectorData={sectorData}/> */}
              <AddCompnayForm
                openPopup={openPopup}
                open={open}
                setOpenPopUp={setOpenPopUp}
                loadCompany={loadCompany}
                editRecord={editRecord}
                setOpen={setOpen}
                getSectorList={getSectorList}
                countryList={countryList}
              />
            </DialogContent>
          </Dialog>
        </div>
      )}

      {editRecord != null && (
        <div className="PopupModel">
          <Dialog
            open={openPopup}
            classes={{ paper: classes.dialogWrapper }}
            // <Dialog open ={openPopup} classes={{ paper: classes.dialogWrapper }}
          >
            {/* <ActionButton 
                color="secondary"
                onClick={() => {setOpenPopUp(false)}}
                > */}
            {/* </ActionButton> */}
            <DialogTitle style={{ paddingTop: '3%', paddingLeft: '3%' }}>
              <Box
                onClick={() => {
                  setOpenPopUp(false);
                }}
              >
                <CloseIcon style={{ float: 'right' }} />
              </Box>
              <Typography variant="h5" component="span" style={{ paddingLeft: '4%' }}>
                Company
              </Typography>
            </DialogTitle>
            <DialogContent className="PopupModel" dividers>
              {/* <AddCompnayForm  openPopup ={openPopup} setOpenPopUp ={setOpenPopUp}loadSector={loadSector} editRecord={editRecord} sectorData={sectorData}/> */}
              <AddCompnayForm
                openPopup={openPopup}
                open={open}
                setOpenPopUp={setOpenPopUp}
                loadCompany={loadCompany}
                editRecord={editRecord}
                setOpen={setOpen}
                getSectorList={getSectorList}
                countryList={countryList}
              />
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
}
