import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import * as utils from '../../utils/index';

const useStyles = makeStyles(() => ({
  commentText: {
    fontSize: '0.875rem',
    lineHeight: '1.43',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: '5px',
    textAlign: 'justify',
  },
}));

const TEXT_TRUNCATE_LENGTH = 170;

const CommentText = (props) => {
  const { text } = props;

  const [expanded, setExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleRowToggle = () => {
    setExpanded(!expanded);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const classes = useStyles();

  if (!text) {
    return null;
  }

  if (text.length <= TEXT_TRUNCATE_LENGTH) {
    <div
      className={classes.commentText}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text.replace(/\n/g, '<br />'),
      }}
    />;
  }

  return windowWidth >= 600 || expanded ? (
    <div>
      <div
        className={classes.commentText}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n/g, '<br />'),
        }}
      />
      {windowWidth < 600 && (
        <span
          className="view-more"
          role="presentation"
          style={{ float: 'left' }}
          onClick={() => {
            handleRowToggle();
          }}
        >
          Show Less
        </span>
      )}
    </div>
  ) : (
    <div>
      <div
        className={classes.commentText}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: utils.truncateText(text, TEXT_TRUNCATE_LENGTH)?.replace(/\n/g, '<br />'),
        }}
      />
      <span
        className="view-more"
        role="presentation"
        style={{ float: 'left' }}
        onClick={() => {
          handleRowToggle();
        }}
      >
        Show More
      </span>
    </div>
  );
};

CommentText.propTypes = {
  text: PropTypes.string,
};

export default CommentText;
