import { Grid, TextField, Box, ListItemText } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import _without from 'lodash/without';
import Checkbox from '@mui/material/Checkbox';

import { REPORT_NOTIFICATION_TYPES } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '3%',
      // width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '95%',
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: 'menu',
};

// eslint-disable-next-line react/function-component-definition
export default function EmailData(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    selectedSectors,
    setSelectedSectors,
    setSelectedCountry,
    subject,
    setSubject,
    mailBody,
    setMailBody,
    sectorsList,
    selectedCountry,
    countryList,
    sectorName,
    notificationType,
  } = props;
  const handleChange = (event) => {
    setSelectedSectors(event.target.value);
  };

  const handleDelete = (e, value) => {
    setSelectedCountry((current) => _without(current, value));
  };

  const handleSectorDelete = (e, value) => {
    setSelectedSectors((current) => _without(current, value));
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  function getStyles(name) {
    return {
      fontWeight:
        sectorName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  if (notificationType === REPORT_NOTIFICATION_TYPES.DISABLE) return null;

  return (
    <Grid container justify="center" alignContent="center">
      <Grid item xs={12} className="mailDivStyle" style={{ paddingBottom: '3%' }}>
        <TextField
          label="Email Subject"
          // variant="filled"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          style={{ width: '95%' }}
        />
      </Grid>
      <Grid item xs={12} className="mailDivStyle">
        <TextField
          id="outlined-multiline-static"
          label="Email Body"
          multiline
          rows={4}
          variant="outlined"
          value={mailBody}
          style={{ width: '95%' }}
          onChange={(e) => setMailBody(e.target.value)}
        />
      </Grid>
      {notificationType === REPORT_NOTIFICATION_TYPES.SECTORS_AND_COUNTRIES && (
        <>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Box alignItems="center" display="flex">
                <InputLabel id="demo-mutiple-chip-label">Select Sectors To Send Email</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={selectedSectors}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          onDelete={(e) => handleSectorDelete(e, value)}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {sectorsList.map((item) => (
                    <MenuItem key={item.id} value={item.name} style={getStyles(item, sectorName, theme)}>
                      <Checkbox checked={selectedSectors.indexOf(item.name) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
                <Tooltip title="You can select multiple sectors" arrow>
                  <HelpIcon style={{ width: '1.2rem' }} />
                </Tooltip>
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Box alignItems="center" display="flex">
                <InputLabel id="demo-mutiple-chip-label">Select Countries To Send Email</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          // onDelete={handleDelete(value)}
                          onDelete={(e) => handleDelete(e, value)}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {countryList.map((item) => (
                    <MenuItem key={item.id} value={item.name} style={getStyles(item, sectorName, theme)}>
                      <Checkbox checked={selectedCountry.indexOf(item.name) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
                <Tooltip title="You can select multiple countries" arrow>
                  <HelpIcon style={{ width: '1.2rem' }} />
                </Tooltip>
              </Box>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
}
