import { useState } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Box, Typography, AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { routePathNames } from '../../utils/constants';
import whiteLogo from '../../assets/logo/logo-blue.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingBottom: '4%',
    height: '67px',
  },
  navLink: {
    lineHeight: '1.75',
    letterSpacing: ' 0.02857em',
  },
  desktopMenu: {
    display: 'flex',
    textAlign: 'right',
    float: 'right',
    textAlignLast: 'center',
    '@media (max-width: 1023px)': {
      display: 'none',
    },
  },
  mobileMenuButton: {
    '@media (min-width: 1024px)': {
      display: 'none',
    },
  },
}));

const useStylesIcon = makeStyles((theme) => ({
  sectionDesktopIcon: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const mystyle = {
  backgroundColor: '#21309D',
};

// eslint-disable-next-line react/function-component-definition
export default function Header() {
  const navigate = useNavigate();

  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const [mobileMenuAnchorE1, setMobileMenuAnchorE1] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMenuAnchorE1);

  const openMobileMenu = (event) => {
    setMobileMenuAnchorE1(event.currentTarget);
  };

  const signOut = () => {
    localStorage.clear();
    navigate(routePathNames.HOMEPAGE);
  };

  const closeMobileMenu = () => {
    setMobileMenuAnchorE1(null);
  };

  const mobileMenu = (
    <Menu anchorEl={mobileMenuAnchorE1} id="mobile-menu" keepMounted open={isMobileMenuOpen}>
      <Box style={{ float: 'right', padding: '2%' }}>
        <CloseIcon style={{ float: 'right' }} onClick={closeMobileMenu} />
      </Box>
      <MenuItem component={Link} to={routePathNames.ADMIN_USERS}>
        Users
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.ADMIN_RESEARCH}>
        Latest Research
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.ADMIN_ALPHA_GENERATION}>
        Alpha Generation
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.ADMIN_COMPANIES}>
        Companies
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.ADMIN_SECTOR}>
        Sectors
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.ADMIN_ANALYTICS}>
        Analytics
      </MenuItem>
      <MenuItem onClick={signOut}>Sign Out</MenuItem>
    </Menu>
  );
  return (
    <div className={classes.root}>
      <AppBar style={mystyle} position="fixed">
        <Toolbar style={{ display: 'flex' }}>
          <img src={whiteLogo} style={{ display: 'flex' }} alt="IronBlue" />
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            <div className={classes.desktopMenu}>
              {/* <Button  color="inherit" component={Link} onClick={closeMobileMenu} to={routePathNames.ADMIN_USERS}>Users</Button>
                        <Button  color="inherit"  component={Link} onClick={closeMobileMenu} to={routePathNames.ADMIN_SECTOR}>Sectors</Button>
                        <Button  color="inherit" component={Link} tonClick={closeMobileMenu} to={routePathNames.ADMIN_COMPANIES}>Companies</Button>
                        <Button  color="inherit" component={Link} onClick={closeMobileMenu} to={routePathNames.ADMIN_ANALYTICS}>Analytics</Button>
                        <Button  color="inherit" onClick={signOut} >
                        <Tooltip title="Sign Out" arrow><ExitToAppIcon/></Tooltip>
                        </Button> */}
              <Box className="navHedaer">
                {' '}
                <NavLink
                  end
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.ADMIN_USERS}
                >
                  <span className="MuiButton-label"> Users</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  end
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.ADMIN_RESEARCH}
                >
                  <span className="MuiButton-label"> Latest Research</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  end
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.ADMIN_ALPHA_GENERATION}
                >
                  <span className="MuiButton-label"> Alpha Generation</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  end
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.ADMIN_COMPANIES}
                >
                  <span className="MuiButton-label"> Companies</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  end
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.ADMIN_SECTOR}
                >
                  <span className="MuiButton-label"> Sectors</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  end
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.ADMIN_ANALYTICS}
                >
                  <span className="MuiButton-label"> Analytics</span>
                </NavLink>
              </Box>
              <Button color="inherit" onClick={signOut}>
                <Tooltip title="Sign Out" arrow>
                  <ExitToAppIcon />
                </Tooltip>
              </Button>
            </div>
          </Typography>
          <div className={classes.mobileMenuButton}>
            <IconButton style={{ padding: '12px' }} color="inherit" onClick={openMobileMenu}>
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {mobileMenu}
    </div>
  );
}
