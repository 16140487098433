import PropTypes from 'prop-types';
import { Typography, Table, TableBody, TableRow, TableCell, TableHead, Box, Paper } from '@mui/material';

const StocksTable = (props) => {
  const { companies, heading, classes } = props;

  return (
    <>
      <Box className={classes.tableHeader}>
        <Typography variant="h6" className={classes.subheading}>
          {heading}
        </Typography>
      </Box>
      <Paper className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow loader="bubble-spin" color="#21309D" size={9}>
              <TableCell className={classes.col1} align="left">
                Company Name
              </TableCell>
              <TableCell className={classes.col2} align="center">
                RIC
              </TableCell>
              <TableCell className={classes.col3} align="center">
                Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.col1} align="left">
                  {item.name}
                </TableCell>
                <TableCell className={classes.col2} align="center">
                  {item.ric_code}
                </TableCell>
                <TableCell className={classes.col3} align="center">
                  {item.latest_score}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!companies.length && <Typography className={classes.noRecordsMessage}>No Data Found</Typography>}
      </Paper>
    </>
  );
};

StocksTable.propTypes = {
  companies: PropTypes.arrayOf({
    id: PropTypes.number,
    name: PropTypes.string,
    ric_code: PropTypes.string,
    latest_score: PropTypes.string,
  }),
  heading: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    table: PropTypes.string,
    tableHeader: PropTypes.string,
    subheading: PropTypes.string,
    tableWrapper: PropTypes.string,
    noRecordsMessage: PropTypes.string,
  }).isRequired,
};

export default StocksTable;
