import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import LoginForm from './LoginForm';
import Registration from './Registration';
import Emitter from '../login-event-emitter';

const SIGN = {
  IN: 'Sign In',
  UP: 'Sign Up',
};

const useStyles = makeStyles(() => ({
  scrollBody: {
    maxWidth: 'calc(500px - 4%)',
    width: 'calc(100% - 4%)',
    verticalAlign: 'top',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    margin: '2%',
    transition: 'transform .3s ease-out',
    transform: 'translateY(-50px)',
  },
  transformed: {
    transform: 'translateY(0)',
  },
}));

const LoginModal = (props) => {
  const classes = useStyles();

  const [selectLogin, setLogin] = useState(true);
  const [selectRegistration, setRegistration] = useState(false);
  const [modelHeaderName, setModelHeaderName] = useState(SIGN.IN);
  const [transform, setTransform] = useState(false);

  const modelLoginStatus = () => {
    setRegistration(false);
    setLogin(true);
    setModelHeaderName(SIGN.IN);
  };

  const modelRegistrationStatus = () => {
    setRegistration(true);
    setLogin(false);
    setModelHeaderName(SIGN.UP);
  };

  let footerLinkRedirect;
  if (selectRegistration === false) {
    footerLinkRedirect = (
      <p style={{ margin: '0.5rem' }} className="forgot-password text-end">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <span>Don't have an account </span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={modelRegistrationStatus}>
          {SIGN.UP}
        </a>
      </p>
    );
  }

  if (selectRegistration === true) {
    footerLinkRedirect = (
      <p style={{ margin: '0.5rem' }} className="forgot-password text-end">
        <span>Have an account? </span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={modelLoginStatus}>
          {SIGN.IN}
        </a>
      </p>
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    Emitter.on('login-model', (newValue) => setLogin(newValue));
    if (selectLogin === true) {
      setRegistration(false);
    }
    return () => {
      Emitter.off('login-model', setRegistration(!selectLogin));
      if (selectLogin === true) {
        setModelHeaderName(SIGN.IN);
        setRegistration(false);
      } else {
        setModelHeaderName(SIGN.UP);
      }
    };
  });

  return (
    <Dialog
      classes={{ paperScrollBody: clsx(classes.scrollBody, [{ [classes.transformed]: transform }]) }}
      scroll="body"
      fullWidth
      maxWidth={false}
      open={props.modalOpen}
      onClose={props.handleModalOpen}
      TransitionProps={{ addEndListener: () => (props.modalOpen ? setTransform(true) : setTransform(false)) }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', m: 0, p: 2 }} className="modal-title h4">
        <Box component="span" sx={{ pl: 2.5 }}>
          {modelHeaderName}
        </Box>
        <IconButton sx={{ mt: -1, mb: -1, mr: -1.75, ml: 'auto' }} aria-label="close" onClick={props.handleModalOpen}>
          <CloseIcon sx={{ fontSize: 28 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }} dividers>
        {selectLogin && <LoginForm setModelHeaderName={setModelHeaderName} handleModalOpen={props.handleModalOpen} />}
        {selectRegistration && (
          <Registration
            selectLogin={selectLogin}
            setLogin={setLogin}
            setRegistration={setRegistration}
            handleModalOpen={props.handleModalOpen}
          />
        )}
      </DialogContent>
      <DialogActions>{footerLinkRedirect}</DialogActions>
    </Dialog>
  );
};

export default LoginModal;
