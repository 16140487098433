import { GOOGLE_CLOUD_BASE_URL } from './axios';

export const verifyToken = async (token) => {
  const response = await fetch(`${GOOGLE_CLOUD_BASE_URL}tokenCheck${token ? `?token=${token}` : ''}`, {
    method: 'POST',
  });
  const result = await response.json();

  return result;
};
