import jwtDecode from 'jwt-decode';
import AxiosInstance from '../api/axios';

export const phoneNumberValidation = (phoneNumber) => {
  if (phoneNumber.includes('+91')) return phoneNumber.length === 13;
  return phoneNumber.length === 10;
};

export const validateEmail = (email) => {
  const reg =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const isConfirmPasswordSame = (password, confirmPassword) => {
  return password === confirmPassword;
};

export const passwordLength = (password) => {
  return password.length >= 5;
};

export const checkPassword = (password) => {
  const phoneno = /^[ 0-9-.()]*$/;
  return phoneno.test(password);
};

export const getAccessToken = () => {
  const savedData = JSON.parse(localStorage.getItem('token'));
  return savedData;
};

export const getRole = (tokenIf) => {
  const token = tokenIf || getAccessToken();
  if (token) {
    const decoded = jwtDecode(token);
    const role = decoded && decoded.userData && decoded.userData.role ? decoded.userData.role : null;
    if (role === 1) {
      return 'admin';
    }
    return 'user';
  }
  return 'user';
};

export const setAuthToken = (token) => {
  AxiosInstance.defaults.headers['x-access-token'] = token;
};

export const setCorsHeader = () => {
  AxiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};

export const saveToken = async (token) => {
  await localStorage.setItem('token', JSON.stringify(token));
};

export const setToken = () => {
  const savedData = JSON.parse(localStorage.getItem('token'));
  AxiosInstance.defaults.headers['x-access-token'] = savedData;
  AxiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};

export const decodeUserDetails = () => {
  const token = getAccessToken();
  if (token) {
    const decoded = jwtDecode(token);
    const userDetails = decoded && decoded.userData ? decoded.userData : null;
    return userDetails;
  }
  return null;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('login');
};

export const getWaterMarkOnPdf = (body, pageType, callback) => {
  AxiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const endPoint =
    pageType === 'sector' ? `sector/watermark?fileUrl=${body.fileUrl}` : `company/watermark?fileUrl=${body.fileUrl}`;
  AxiosInstance.get(endPoint)
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, error);
      console.log('==>Errr', error);
    });
};

export const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) {
    return text;
  }

  const truncated = text.substr(0, maxLength);
  const lastValidIndex = truncated.search(/[\s,.!?;][^\s,.!?;]*$/);
  const finalText = truncated.substr(0, lastValidIndex || truncated.length).replace(/[,;]$/, '');

  return `${finalText}...`;
};
