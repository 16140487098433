import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserForm from './UserEditForm';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function UserEditPopUp(props) {
  const classes = useStyles();
  const [mobileMenuAnchorE1, setMobileMenuAnchorE1] = useState(null);
  const closeMobileMenu = (event) => {
    setMobileMenuAnchorE1(null);
  };
  const {
    title,
    childern,
    openUserPopup,
    setopenUserPopup,
    editUserRecord,
    initalSectorValue,
    initalCountryValue,
    loadSector,
    loadUser,
    setOpen,
    open,
  } = props;

  //     useEffect(() => {
  //         return () => {
  //             {editUserRecord.sector.map((item) => (
  //                   setInitialSectorValue(item.name)

  //         ))}
  //         }
  //     }, [])
  return (
    <div className="PopupModel">
      <Dialog open={openUserPopup} classes={{ paper: classes.dialogWrapper }} maxWidth="xs" fullWidth>
        {/* <ActionButton 
                color="secondary"
                onClick={() => {setopenUserPopup(false)}}
                > */}
        {/* </ActionButton> */}
        <DialogTitle style={{ paddingTop: '5%', paddingLeft: '9%' }}>
          <Box
            onClick={() => {
              setopenUserPopup(false);
            }}
          >
            <CloseIcon style={{ float: 'right' }} />
          </Box>
          <Typography variant="h5" component="span">
            Edit User
          </Typography>
        </DialogTitle>
        <DialogContent className="PopupModel" dividers>
          <UserForm
            openUserPopup={openUserPopup}
            open={open}
            setopenUserPopup={setopenUserPopup}
            loadSector={loadSector}
            editUserRecord={editUserRecord}
            setOpen={setOpen}
            sectorsData={props.sectorsData}
            initalSectorValue={initalSectorValue}
            initalCountryValue={initalCountryValue}
            loadUser={loadUser}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
