import { makeStyles } from '@mui/styles';
import Button from 'react-bootstrap/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { Box, TextField } from '@mui/material';
import Loader from '../BackdropLoader';
import AxiosInstance from '../../../api/axios';
import { TOAST_OPTIONS } from '../../../utils/toast';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function ConfirmationModel(props) {
  const classes = useStyles();

  //   const [open, setOpen] = useState(false);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { openDeleteModel, setOpenDeleteModel, userRecord, loadSector, setOpen, open } = props;
  let userEmail = '';
  let userName = '';
  if (userRecord != null) {
    userEmail = userRecord.email;
    userName = userRecord.first_name;
  }
  const handleClickOpen = () => {
    setOpenDeleteModel(true);
  };

  const handleClose = () => {
    setOpenDeleteModel(false);
  };

  const errorNotification = (message, statusValue) => {
    // const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code = message.response.status;
    if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  const deleteSectorReord = (e) => {
    e.preventDefault();
    setOpen(true);

    if (userRecord != null) {
      const userId = userRecord.id;
      AxiosInstance.post(
        'user/forgotPassword',
        { email: userEmail },
        {
          headers: { Accept: 'application/json' },
        },
      )
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setOpenDeleteModel(false);
          setOpen(false);
        })
        .catch(function (error) {
          const message = 'Something Went Wrong';
          toast.error(message, TOAST_OPTIONS);
          setOpenDeleteModel(false);
        });
    } else {
      toast.error('Something Went wrong', TOAST_OPTIONS);
    }
  };

  return (
    <div>
      <Dialog
        open={openDeleteModel}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="ajs-header" style={{ color: '#000000', fontWeight: 1000 }} id="responsive-dialog-title">
          Reset User Password
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText className=".ajs-body" style={{ color: '#000000', paddingTop: '1%', paddingBottom: '1%' }}>
            Send reset password link to user {userName}
          </DialogContentText>
          {/* <TextField
         id="outlined-read-only-input"
         label="User Email Id"
         defaultValue={userEmail}
         InputProps={{
           readOnly: true,
         }}
       /> */}
          <Box style={{ color: '#000000', paddingBottom: '1%' }}>
            {/* <TextField disabled id="standard-disabled" label="User Email Id"  defaultValue={userEmail} /> */}
            <TextField
              className={classes.input}
              id="outlined-read-only-input"
              label="User Email Id"
              defaultValue={userEmail}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteSectorReord}
            style={{ lineHeight: 'normal' }}
            className="btn btn-dark btn-primary_submit submitButton shadow-none"
          >
            Send
          </Button>

          <Button
            className="btn btn-dark btn-primary_submit cancelButton shadow-none"
            style={{ lineHeight: 'normal' }}
            onClick={handleClose}
            color="secondary"
          >
            Cancel
          </Button>
          {/* <div className="ajs-footer"><div className="ajs-auxiliary ajs-buttons"></div><div className="ajs-primary ajs-buttons"><button className="ajs-button ajs-ok">OK</button><button className="ajs-button ajs-cancel">Cancel</button></div></div> */}
        </DialogActions>
        <Loader setOpen={setOpen} open={open} />
      </Dialog>
    </div>
  );
}
