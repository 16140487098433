const ADMIN_DASHBOARD = '/admin-dashboard/';
const USER_DASHBOARD = '/user-dashboard/';

export const routePathNames = {
  HOMEPAGE: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgetPassword',
  RESET_PASSWORD: '/resetPassword/:id',
  ABOUT: '/about',
  TEAM: '/team',
  TermsCondition: '/terms&condition',
  ADMIN_USERS: `${ADMIN_DASHBOARD}users`,
  ADMIN_SECTOR: `${ADMIN_DASHBOARD}sectors`,
  ADMIN_RESEARCH: `${ADMIN_DASHBOARD}research`,
  ADMIN_COMPANIES: `${ADMIN_DASHBOARD}companies`,
  ADMIN_ANALYTICS: `${ADMIN_DASHBOARD}analytics`,
  ADMIN_ALPHA_GENERATION: `${ADMIN_DASHBOARD}alpha-generation`,
  USER_SECTOR: `${USER_DASHBOARD}sectors`,
  USER_SECTOR_ID: `${USER_DASHBOARD}sector`,
  USER_RESEARCH: `${USER_DASHBOARD}research`,
  USER_COMPANIES: `${USER_DASHBOARD}companies`,
  USER_ALPHA_GENERATION: `${USER_DASHBOARD}alpha-generation`,
  OUR_APPROACH: `${USER_DASHBOARD}ourApproach`,
  CONTACT_US: `${USER_DASHBOARD}contactUs`,
};

export const REPORT_NOTIFICATION_TYPES = {
  ALL_USERS: 'all_users',
  SECTORS_AND_COUNTRIES: 'specific_sectors_and_countries',
  DISABLE: 'disable',
};

export const LOCATION_STATES = {
  TOASTS: {
    RESET_PASSWORD: {
      type: 'error',
      message: 'Sorry, the link you followed has expired',
    },
  },
};

export const companyGroup = {
  EMPTY: null,
  TOP_DECILE: 'td',
  TOP_QUARTILE: 'tq',
  BOTTOM_QUARTILE: 'bq',
  BOTTOM_DECILE: 'bd',
};

export const reportStatus = {
  SCHEDULED: 0,
  PUBLISH: 1,
};

export const tableId = {
  TSR_SCORE_DECILES: 1,
  TSR_SCORE_QUARTILES: 2,
};

export const tableRowMode = {
  VIEW: 0,
  EDIT: 1,
};

export const tableRowType = {
  HEAD: 0,
  BODY: 1,
  FOOTER: 2,
};
