import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import clsx from 'clsx';

import TeamCard from './TeamCard';

const useStyles = makeStyles((theme) => ({
  slide: {
    height: 'auto',
  },
  card: {
    height: '100%',
    width: '100%',
    padding: 0,
  },
  swiper: {
    '& .swiper-pagination': {
      [theme.breakpoints.up(576)]: {
        display: 'none',
      },
    },
    '& .swiper-pagination-horizontal': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 40,
    },
    '& .swiper-pagination-bullet': {
      width: 15,
      height: 15,
      backgroundColor: '#d7d7d7', // todo: theme
      borderRadius: '100%',
      margin: [[0, 5]],
      transition: '0.4s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#21309d', // todo: theme
      },
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: '#21309d', // todo: theme
    },
  },
}));

const image1 = require('../assets/img/team-img1.jpg');
const image2 = require('../assets/img/team-img2.jpg');

const TEAM_DATA = [
  {
    id: 1,
    img: image1,
    name: 'Ed Steele',
    profession: 'Founder',
    bio: 'Ed founded Iron Blue in 2021 after working as an equity analyst since 1998 at major investment banks including Citi, Nomura and HSBC. Ed and his team achieved strong institutional investor ratings, confirmed in many external surveys including a No 1 Business Services ranking in 2019’s II European survey. He is a CFA charterholder and has a first class degree in Law with American Law & Politics from Nottingham University and University of Texas, School of Law.',
  },
  {
    id: 2,
    img: image2,
    name: 'Shailja Periwal',
    profession: 'Senior Analyst',
    bio: 'Shailja has worked as an equity research analyst for 10+ years with Citi and Crisil. At Crisil she worked for a European bulge bracket investment bank. Her team at Citi was rated amongst Top 3 in the sector in the Institutional Investor Survey. She is a Computer graduate and holds an MBA degree from a top tier Business School in India. She has also cleared CFA Level 2. Her areas of interest are forensic accounting, company analysis and valuation and working on data platforms.',
  },
];

const Team = ({ title = 'Expert Team', subtitle = 'Four decades of equity research experience', team = TEAM_DATA }) => {
  const classes = useStyles();

  const [flippedCards, setFlippedCards] = useState(
    TEAM_DATA.reduce((prev, curr) => ({ ...prev, [curr.id]: false }), { [TEAM_DATA[0].id]: false }),
  );

  return (
    <section id="team" className="team-area ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        <Swiper
          className={classes.swiper}
          modules={[Pagination]}
          spaceBetween={30}
          loop
          pagination={{ clickable: true }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
            },
          }}
          onSlideChangeTransitionEnd={() => {
            setFlippedCards((prev) =>
              Object.keys(prev).reduce((p, k) => ({ ...p, [k]: false }), { [Object.keys(prev)[0]]: false }),
            );
          }}
        >
          {team.map((item) => (
            <SwiperSlide className={classes.slide} key={item.id}>
              <TeamCard
                className={clsx('single-team', classes.card)}
                isFlipped={flippedCards[item.id]}
                onClick={() => setFlippedCards((prev) => ({ ...prev, [item.id]: true }))}
                {...item}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

Team.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.string,
  team: PropTypes.arrayOf(
    // todo: should be inherited from TeamCard component
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      img: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      profession: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      bio: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ),
};

export default Team;
