/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Link } from '@mui/material';
import PolicyDialog from './Policy';
import TermsConditionDialog from './Terms_condition';

// eslint-disable-next-line react/function-component-definition
export default function Footer() {
  const [open, setOpen] = useState(false);
  const [OpenTC, setOpenTC] = useState(false);
  const [isTC, setTC] = useState(false);
  const [isCIP, setCIP] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setTC(false);
  };

  const handleClickOpenTC = () => {
    setOpen(true);
    setTC(true);
  };

  const handleClickOpenTCNew = () => {
    setOpenTC(true);
    setCIP(true);
    setTC(false);
  };

  const handleClickOpenNew = () => {
    setOpenTC(true);
    setCIP(false);
    setTC(false);
  };

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 FooterBarCopy">
            <p>Copyright @ 2021 Iron Blue Financials. All rights reserved.</p>
          </div>

          {/* <button onclick={ setOpen(true)}>
                                    Terms & Conditions</button>   */}
          <div className="col-lg-8 col-md-8">
            <ul>
              <li>
                <Link style={{ cursor: 'pointer' }} onClick={handleClickOpenTC} color="inherit">
                  Terms & Conditions{' '}
                </Link>
                {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                Terms & Conditions  </Button> */}
              </li>
              <li>
                <Link style={{ cursor: 'pointer' }} onClick={handleClickOpen} color="inherit">
                  Privacy Policy
                </Link>
                {/* <Button variant="outlined" color="primary">
                                Terms & Conditions  </Button> */}
              </li>
              <li>
                <Link style={{ cursor: 'pointer' }} onClick={handleClickOpenTCNew} color="inherit">
                  Conflicts Of Interest Policy{' '}
                </Link>
                {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                Terms & Conditions  </Button> */}
              </li>
              <li>
                <Link style={{ cursor: 'pointer' }} onClick={handleClickOpenNew} color="inherit">
                  {' '}
                  Our Approach
                </Link>
                {/* <Button variant="outlined" color="primary">
                                Terms & Conditions  </Button> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PolicyDialog open={open} setOpen={setOpen} isTC={isTC} />
      <TermsConditionDialog open={OpenTC} setOpen={setOpenTC} isCIP={isCIP} />
    </footer>
  );
}
