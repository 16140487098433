import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import * as utils from './index';

const measurementId = process.env.REACT_APP_GA_MESEASUREMENT_ID;

const initAnalytics = () => {
  if (!measurementId) {
    return;
  }

  ReactGA.initialize(measurementId, {
    gtagOptions: { send_page_view: false },
  });

  ReactGA.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 2000,
  });

  ReactGA.gtag('set', 'ads_data_redaction', true);
  ReactGA.gtag('set', 'url_passthrough', true);
};

const waitForCallback = async (callback, interval = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 3 * 1000);

    const intervalId = setInterval(() => {
      if (callback()) {
        clearInterval(intervalId);
        resolve();
      }
    }, interval);
  });
};

const trackView = async () => {
  if (!measurementId) {
    return;
  }

  if (utils.getRole() !== 'user' || window.location.pathname === '/') {
    return;
  }

  await waitForCallback(
    () => window.dataLayer?.length && window.dataLayer.find((item) => item[0] === 'consent' && item[1] === 'update'),
  );

  const data = { hitType: 'pageview', page: window.location.pathname, title: document.title };
  console.log('trackView', data);
  ReactGA.send(data);
};

const useInitGa = () => {
  useEffect(() => {
    initAnalytics();
  }, []);

  useEffect(() => {
    const observerCallback = (mutationsList) => {
      mutationsList
        .filter((mutation) => mutation.type === 'childList')
        .forEach(() => {
          trackView();
        });
    };

    const observer = new MutationObserver(observerCallback);
    const targetNode = document.querySelector('title');

    observer.observe(targetNode, { childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};

export default useInitGa;
