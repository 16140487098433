import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableContainer,
  TableHead,
  Checkbox,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSectorReport from './DeleteSectorReport';
import DeleteCompanyReport from './DeleteCompReport';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    height: '18rem',
    overflowY: 'auto',
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

// eslint-disable-next-line react/function-component-definition
export default function EditRecord(props) {
  const {
    editRecord,
    reportLength,
    isFromCompany,
    loadSector,
    loadCompany,
    reportArray,
    setReportArray,
    showPinColumn,
  } = props;
  const [openPopup, setOpenPopUp] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [openUploadReportModel, setUploadReportModel] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);
  const [upoadReportTitleName, setUploadReportTitleName] = useState('');
  const [open, setOpen] = useState(false);
  const [urlReq, seturlReq] = useState('');
  const [editRecords, setEditRecords] = useState(false);
  const [editReportDetails, setEditReportDetails] = useState('');
  const classes = useStyles();

  const headCells = [
    { id: 'reports', label: 'Report', disableSorting: true },
    showPinColumn ? { id: 'pin', label: 'Pin to the top', disableSorting: true } : null,
    // { id: 'manage', label: ' ' },
    { id: 'manage', label: 'Delete' },
    // { id: 'manage', label: '' },
  ].filter(Boolean);

  const openInPopUp = (item) => {
    if (isFromCompany === 'true') {
      const reportUrl = `company/report/${item.reportId}`;
      seturlReq(reportUrl);
      const titleName = `Company: ${editRecord.name}`;
      setUploadReportTitleName(titleName);
      setOpenPopUp(true);
      setUploadReportModel(true);
      setEditRecords(true);
      setUploadReport(editRecord);
      setUploadReportModel(true);
    }

    if (isFromCompany === 'false') {
      const reportUrl = `sector/report/${item.reportId}`;
      seturlReq(reportUrl);
      const titleName = `Sector: ${editRecord.name}`;
      setUploadReportTitleName(titleName);
      setOpenPopUp(true);
      setUploadReportModel(true);
      setEditRecords(true);
      setUploadReport(editRecord);
      setUploadReportModel(true);
    }
    // setEditRecord(item);

    setOpenPopUp(true);
    setEditReportDetails(item);
    setUploadReportModel(true);
    setEditRecords(true);
    setUploadReport(editRecord);
    setUploadReportModel(true);
  };

  const openConfirmationModel = (item) => {
    setDeleteRecord(item);
    setOpenDeleteModel(true);
  };

  const uploadReportModel = (item) => {
    setUploadReport(item);
    const titleName = `Sector: ${item.name}`;
    setUploadReportTitleName(titleName);
    setUploadReportModel(true);
  };

  function togglePinToTop(event, item) {
    const newReports = reportArray.map((report) => {
      if (report.reportId === item.reportId) {
        return { ...report, pin_to_top: event.target.checked ? 1 : 0 };
      }
      return report;
    });

    setReportArray(newReports);
  }

  useEffect(() => {
    if (editRecord !== null) {
      setReportArray(editRecord.reports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className="desktop-only">
        <Box textAlign="center">
          {reportLength !== 0 && (
            <Paper className={classes.pageContent}>
              <Typography
                variant="h6"
                style={{
                  textAlign: 'center',
                  fontSize: '17px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                component="h6"
              >
                Reports
              </Typography>
              <div className={classes.tableWrapper}>
                <TableContainer
                  className="EditRecordTable"
                  style={{
                    fontWeight: '600',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    display: 'table',
                  }}
                >
                  <TableHead component="div">
                    <TableRow
                      component="div"
                      loader="bubble-spin"
                      style={{ fontWeight: '600' }}
                      color="#21309D"
                      size={9}
                    >
                      {headCells.map((headCell, index) => (
                        <TableCell component="div" key={index} style={{ fontWeight: '600' }} align="center">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody component="div" className="ReportBox">
                    {reportArray.map((item) => (
                      <TableRow component="div" key={item.reportId}>
                        <TableCell component="div" align="center">
                          {item.date_change}
                        </TableCell>
                        {showPinColumn && (
                          <TableCell component="div" align="center">
                            <Checkbox
                              checked={item.pin_to_top === 1}
                              onChange={(event) => {
                                togglePinToTop(event, item);
                              }}
                            />
                          </TableCell>
                        )}
                        <TableCell component="div" align="center">
                          {/* <Tooltip title="Upload report" style={{ marginRight: "2%" }} arrow><PublishIcon onClick={() => { openInPopUp(item) }} /></Tooltip> */}
                          <Tooltip title="Delete" arrow>
                            <DeleteOutlineIcon
                              onClick={() => {
                                openConfirmationModel(item);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TableContainer>
              </div>
            </Paper>
          )}
          {isFromCompany === 'true' && (
            <DeleteCompanyReport
              deleteRecord={deleteRecord}
              openDeleteModel={openDeleteModel}
              setOpenDeleteModel={setOpenDeleteModel}
              editRecord={editRecord}
              open={open}
              setOpen={setOpen}
              loadCompany={loadCompany}
              setReportArray={setReportArray}
              setOpenPopUp={setOpenPopUp}
            />
          )}

          {isFromCompany === 'false' && (
            <DeleteSectorReport
              deleteRecord={deleteRecord}
              setReportArray={setReportArray}
              editRecord={editRecord}
              openDeleteModel={openDeleteModel}
              setOpenDeleteModel={setOpenDeleteModel}
              open={open}
              setOpen={setOpen}
              loadSector={loadSector}
              setOpenPopUp={setOpenPopUp}
            />
          )}

          {/* {isFromCompany === "true" &&
      <UploadReportDialog uploadReport={uploadReport} openUploadReportModel={openUploadReportModel} setUploadReportModel={setUploadReportModel} open={open} urlReq={urlReq} setOpen={setOpen} editRecords={editRecords} setEditRecords={setEditRecords} upoadReportTitleName={upoadReportTitleName} setUploadReportTitleName={setUploadReportTitleName} loadSector={loadCompany} editReportDetails ={editReportDetails} isFromCompany="true"/>}
{isFromCompany === "false" && <UploadReportDialog uploadReport={uploadReport} openUploadReportModel={openUploadReportModel} setUploadReportModel={setUploadReportModel} open={open} urlReq={urlReq} setOpen={setOpen} editRecords={editRecords} setEditRecords={setEditRecords} upoadReportTitleName={upoadReportTitleName} setUploadReportTitleName={setUploadReportTitleName} editReportDetails ={editReportDetails} loadSector={loadSector} isFromCompany="false"/>} */}
          {/* {error && <h5>{error}</h5>} */}
        </Box>
      </Box>

      <Box className="mobile-only">
        <Box textAlign="center">
          {reportLength !== 0 && (
            <Paper className={classes.pageContent}>
              <Typography
                variant="h6"
                style={{
                  textAlign: 'center',
                  fontSize: '17px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                component="h6"
              >
                Reports
              </Typography>
              <div className={classes.tableWrapper}>
                <TableContainer
                  className="EditRecordTable"
                  style={{
                    fontWeight: '600',
                    height: '18rem',
                    width: '100%',
                    overflowY: 'scroll !important',
                    whiteSpace: 'nowrap',
                    display: 'table',
                  }}
                >
                  <TableHead component="div">
                    <TableRow
                      component="div"
                      loader="bubble-spin"
                      style={{ fontWeight: '600' }}
                      color="#21309D"
                      size={9}
                    >
                      {headCells.map((headCell, index) => (
                        <TableCell
                          component="div"
                          key={index}
                          style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
                          align="center"
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody component="div" className="ReportBox">
                    {reportArray.map((item) => (
                      <TableRow component="div" key={item.reportId}>
                        <TableCell component="div" align="center">
                          {item.date_change}
                        </TableCell>
                        {showPinColumn && (
                          <TableCell component="div" align="center">
                            <Checkbox
                              checked={item.pin_to_top === 1}
                              onChange={(event) => {
                                togglePinToTop(event, item);
                              }}
                            />
                          </TableCell>
                        )}
                        <TableCell component="div" align="center">
                          {/* <Tooltip title="Upload report" style={{ marginRight: "2%" }} arrow><PublishIcon onClick={() => { openInPopUp(item) }} /></Tooltip> */}
                          <Tooltip title="Delete" arrow>
                            <DeleteOutlineIcon
                              onClick={() => {
                                openConfirmationModel(item);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TableContainer>
              </div>
            </Paper>
          )}
          {isFromCompany === 'true' && (
            <DeleteCompanyReport
              deleteRecord={deleteRecord}
              openDeleteModel={openDeleteModel}
              setOpenDeleteModel={setOpenDeleteModel}
              editRecord={editRecord}
              open={open}
              setOpen={setOpen}
              loadCompany={loadCompany}
              setReportArray={setReportArray}
              setOpenPopUp={setOpenPopUp}
            />
          )}

          {isFromCompany === 'false' && (
            <DeleteSectorReport
              deleteRecord={deleteRecord}
              setReportArray={setReportArray}
              editRecord={editRecord}
              openDeleteModel={openDeleteModel}
              setOpenDeleteModel={setOpenDeleteModel}
              open={open}
              setOpen={setOpen}
              loadSector={loadSector}
              setOpenPopUp={setOpenPopUp}
            />
          )}

          {/* {isFromCompany === "true" &&
      <UploadReportDialog uploadReport={uploadReport} openUploadReportModel={openUploadReportModel} setUploadReportModel={setUploadReportModel} open={open} urlReq={urlReq} setOpen={setOpen} editRecords={editRecords} setEditRecords={setEditRecords} upoadReportTitleName={upoadReportTitleName} setUploadReportTitleName={setUploadReportTitleName} loadSector={loadCompany} editReportDetails ={editReportDetails} isFromCompany="true"/>}
{isFromCompany === "false" && <UploadReportDialog uploadReport={uploadReport} openUploadReportModel={openUploadReportModel} setUploadReportModel={setUploadReportModel} open={open} urlReq={urlReq} setOpen={setOpen} editRecords={editRecords} setEditRecords={setEditRecords} upoadReportTitleName={upoadReportTitleName} setUploadReportTitleName={setUploadReportTitleName} editReportDetails ={editReportDetails} loadSector={loadSector} isFromCompany="false"/>} */}
          {/* {error && <h5>{error}</h5>} */}
        </Box>
      </Box>
    </>
  );
}
