import { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';

import Loader from '../BackdropLoader';
import CompanyPositionChanges from '../CompanyPositionChanges';
import AxiosInstance from '../../../api/axios';

const RecalculateQueuedReportsStep = (props) => {
  const {
    reports,
    fullScreen,
    handleClose,
    maxWidth,
    handlePrev,
    handleNext,
    setOpen,
    open,
    companyId,
    companyNewScore,
  } = props;

  const [reportsLocal, setReportsLocal] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await AxiosInstance.post('admin/reports/bulkRecalculate', {
          dry_run: true,
          reports: reports.map(({ id }) => ({ id })),
          updated_company: {
            id: companyId,
            score: companyNewScore,
          },
        });
        setReportsLocal(data.data);
        if (!data.data.length) {
          setMessage('No queued reports, please recalculate the score changes');
        }
      } catch (error) {
        console.error(error);
        setMessage('No queued reports, please recalculate the score changes');
      }
    };
    fetchData();
  }, [reports, companyId, companyNewScore]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={maxWidth}
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            // maxWidth: '640px',
            maxHeight: '750px',
          },
        },
      }}
    >
      <Box>
        <DialogTitle id="responsive-dialog-title">
          <Typography
            variant="h6"
            style={{
              textAlign: 'Left',
              paddingBottom: '10px',
              paddingLeft: '1%',
              paddingRight: '3%',
            }}
            component="span"
          >
            Step 3 of 3: Recalculated Reports
          </Typography>
          <CloseIcon style={{ float: 'right', cursor: 'pointer', marginTop: '4px' }} onClick={handleClose} />
        </DialogTitle>
      </Box>
      <DialogContent
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        dividers
      >
        {message && (
          <Typography
            style={{
              padding: '40px',
              textAlign: 'center',
            }}
          >
            {message}
          </Typography>
        )}
        {reportsLocal.map((report, index) => {
          const reportTime = moment(`${report.date_change.split('T')[0]} ${report.time}`);
          return (
            <>
              <Grid container>
                <Grid
                  sm={12}
                  md={6}
                  xl={6}
                  xs={12}
                  item
                  sx={{
                    borderBottom: {
                      xs: '1px solid #e0e0e0',
                      sm: '1px solid #e0e0e0',
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                >
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                      paddingLeft: '2%',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>
                      A report on company {report.company.name} with a score change from {report.company.latest_score}{' '}
                      to {report.latest_score} is already submitted for publication on{' '}
                      {reportTime.format('YYYY-MM-DD HH:mm')} GMT, which will make the following changes:
                    </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  sm={12}
                  md={6}
                  xl={6}
                  xs={12}
                  sx={{
                    borderBottom: {
                      xs: '1px solid #e0e0e0',
                      sm: '1px solid #e0e0e0',
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                >
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                      paddingLeft: '2%',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>Original version:</span>
                    <CompanyPositionChanges
                      topDecile={report.previous_position_changes?.topDecile || 'No changes'}
                      topQuartile={report.previous_position_changes?.topQuartile || 'No changes'}
                      bottomQuartile={report.previous_position_changes?.bottomQuartile || 'No changes'}
                      bottomDecile={report.previous_position_changes?.bottomDecile || 'No changes'}
                      showDivider={false}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={6}
                  xl={6}
                  xs={12}
                  sx={{
                    borderBottom: {
                      xs: '1px solid #e0e0e0',
                      sm: '1px solid #e0e0e0',
                      md: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                >
                  <Box
                    style={{
                      textAlign: 'Left',
                      paddingRight: '3%',
                      marginTop: '30px',
                      paddingLeft: '2%',
                    }}
                  >
                    <span style={{ fontWeight: '600' }}>Recalculated version:</span>
                    <CompanyPositionChanges
                      topDecile={report.position_changes?.topDecile || 'No changes'}
                      topQuartile={report.position_changes?.topQuartile || 'No changes'}
                      bottomQuartile={report.position_changes?.bottomQuartile || 'No changes'}
                      bottomDecile={report.position_changes?.bottomDecile || 'No changes'}
                      showDivider={false}
                    />
                  </Box>
                </Grid>
              </Grid>
              {index < reports.length - 1 && <Divider style={{ borderColor: '#8c8b8b' }} />}
            </>
          );
        })}
      </DialogContent>
      <DialogActions>
        <>
          <Button
            className="btn btn-dark btn-primary_submit cancelButton shadow-none"
            style={{
              lineHeight: 1.5,
              color: '#21309d',
              fontWeight: '600',
              outlineStyle: 'none !important',
              backgroundColor: '#ffffff !important',
              fontFamily: 'Quicksand',
              borderRadius: '18px',
              borderColor: '#21309d',
              border: '2px solid #21309d',
            }}
            onClick={handlePrev}
            color="secondary"
          >
            <span className="MuiButton-label">Back</span>
          </Button>
          <Button
            onClick={handleNext}
            className=" btn btn-dark btn-primary_submit submitButton shadow-none"
            color="primary"
            autoFocus
          >
            <span className="MuiButton-label">Submit</span>
          </Button>
        </>
      </DialogActions>
      <Loader setOpen={setOpen} open={open} />
    </Dialog>
  );
};

export default RecalculateQueuedReportsStep;
