import { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Box,
  Paper,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
  NativeSelect,
  Card,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet';

import UserHeader from './UserHeader';
import SectorTable from './Dashboard/AdminTable';
import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../api/axios';
import EmptyComponent from './Dashboard/emptyComponent';
import * as utils from '../utils/index';
import Loader from './Dashboard/BackdropLoader';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    overflow: 'hidden !important',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  },

  searchInput: {
    // width: '75%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  reportLink: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
    whiteSpace: 'nowrap',
  },
  row: {
    backgroundColor: 'var(--row-background-color)',
    '& td': {
      backgroundColor: 'var(--row-background-color)',
    },
  },
}));

const headCells = [
  { id: 'name', label: 'Company' },
  { id: 'latest_score', label: 'Latest Score' },
  { id: 'latest_report_date', label: 'Latest Report', type: 'date' },
  { id: 'previous_score', label: 'Previous Score' },
  { id: 'change_yoy', label: 'Change' },
  { id: 'reports', label: 'All Reports', disableSorting: true },
  { id: 'ric_code', label: 'RIC Code' },
  { id: 'sector_id', label: 'Sector' },
  { id: 'country', label: 'Country' },
];

// eslint-disable-next-line react/function-component-definition
export default function UserViewCompanies() {
  const [company, setCompany] = useState([]);
  // const [sectorData, setSectorData] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopUp] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [openUploadReportModel, setUploadReportModel] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);
  const [upoadReportTitleName, setUploadReportTitleName] = useState('');
  const [getSectorList, setGetsectorList] = useState([]);
  const [open, setOpen] = useState(false);

  const loadCompany = async () => {
    setOpen(true);
    AxiosInstance.get('company/user?fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        setCompany(finalRes);
        setOpen(false);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        setOpen(false);
        //    setError(error)
      });
  };
  const getSectorListApi = () => {
    AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        // return finalRes
        setGetsectorList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  useEffect(() => {
    loadCompany();
    getSectorListApi();
  }, []);
  const classes = useStyles();

  const {
    TablContainer,
    TblHead,
    // recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
    orderColumnType,
  } = SectorTable(company, headCells, filterFn);

  const TableCell = withStyles({
    root: {
      borderBottom: 'none',
    },
  })(MuiTableCell);

  const handlePdfUrlLink = (targetObject) => {
    const body = {
      fileUrl: targetObject.url,
      fileDate: targetObject.date_change,
      reportName: targetObject.reportName,
    };
    console.log('handlePdfUrlLink ~ body', body);
    const url = `${GOOGLE_CLOUD_BASE_URL}company/watermark/${body.reportName}?token=${utils.getAccessToken()}&fileUrl=${
      body.fileUrl
    }`;

    const newWindow = window.open(url, '_blank');
    if (newWindow == null || typeof newWindow === 'undefined') {
      //   alert(
      //     'Please disable your pop-up blocker and click the "Open" link again.'
      //   );
      document.location.href = url;
    } else {
      newWindow.focus();
    }
  };

  const analyticApi = (targetObject) => {
    console.log('ANYL call');
    let body = {};
    const userData = utils.decodeUserDetails();

    AxiosInstance.post(
      'analytics/company',
      (body = {
        user_id: userData.userId,
        sc_id: targetObject.company_id,
        sc_report_id: targetObject.reportId,
      }),
    )
      .then(function (response) {
        handlePdfUrlLink(targetObject);
      })
      .catch(function (error) {
        handlePdfUrlLink(targetObject);
        // toast.error("Something went wrong", TOAST_OPTIONS);
      });
  };

  const handleReportLink = (e) => {
    if (e !== 'null') {
      const reportsArray = _.map(company, 'reports');
      const combineArray = _.uniq(_.flatten(reportsArray));
      const targetObject = _.find(combineArray, { url: e });
      if (targetObject !== undefined) {
        analyticApi(targetObject);
      }

      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      // document.location.href = url
      // const newWindow = document.location.href
      // if (newWindow) newWindow.opener = null
    }
  };

  return (
    <>
      <Helmet>
        <title>Companies - Iron Blue Financials</title>
      </Helmet>
      <UserHeader getSectorList={getSectorList} company={company} />

      <Loader setOpen={setOpen} open={open} />

      <Card className="cardClass">
        <Typography variant="h4" className="cardTitle">
          Companies
        </Typography>
      </Card>

      {company && company.length ? (
        <Box textAlign="center">
          <Paper className="TableView" style={{ padding: '0px' }}>
            <TablContainer>
              <TblHead />
              <TableBody>
                {stableSort(company, getComparator(order, orderBy, orderColumnType)).map((item) => (
                  <TableRow
                    key={item.id}
                    className={classes.row}
                    style={{
                      '--row-background-color': item.color,
                    }}
                  >
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.latest_score}</TableCell>
                    <TableCell align="center">
                      <span
                        className={classes.reportLink}
                        onClick={() => {
                          const latestReport = item.reports.find(
                            (report) => report.date_change === item.latest_report_date,
                          );
                          if (latestReport) {
                            handleReportLink(latestReport.url);
                          }
                        }}
                        role="presentation"
                      >
                        {item.latest_report_date}
                      </span>
                    </TableCell>
                    <TableCell align="center">{item.previous_score}</TableCell>
                    <TableCell align="center">{item.change_yoy}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {/* <DropDown item={item}/> */}
                      {/* <NativeSelect
                                                    // onClick={onChangeSectorId(element)}
                                                    // onClick={getSectorList}
                                                    onClick= {(e) =>handleReportLink(e.target.value)}
                                                    // onChange={handleReportLink(this.value)}
                                                    inputProps={{
                                                        name: 'Select Sector',
                                                        id: 'age-native-label-placeholder',
                                                    }}
                                                >
                                                    {item.reports.map(element =>
                                                        (
                                                            <option value={element.url}> {element.date_change}</option>
                                                        ))}
                                                    {item.reports.length === 0 && <option value="null">None</option>}

                                                </NativeSelect> */}
                      {item.reports && item.reports.length ? (
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <option value="null">Select Report</option>
                          {item.reports.map((element, index) => (
                            // eslint-disable-next-line react/no-unknown-property
                            <option value={element.url} date={element.date_change} key={index}>
                              {' '}
                              {element.date_change}
                            </option>
                          ))}
                        </NativeSelect>
                      ) : (
                        // <span>None</span>
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <>
                            <option value="null">Select Report</option>
                            <option value="null"> None</option>
                          </>
                        </NativeSelect>
                      )}
                    </TableCell>
                    <TableCell align="center">{item.ric_code}</TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {item.sectorName}
                    </TableCell>
                    <TableCell align="center">{item.country}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TablContainer>
          </Paper>
        </Box>
      ) : (
        !open && <EmptyComponent />
      )}
    </>
  );
}
