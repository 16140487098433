import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import Loader from './BackdropLoader';
import AxiosInstance from '../../api/axios';
import { TOAST_OPTIONS } from '../../utils/toast';

// eslint-disable-next-line react/function-component-definition
export default function CompanyConfirm(props) {
  //   const [open, setOpen] = useState(false);
  const { openDeleteModel, setOpenDeleteModel, companyDeleteRecord, loadCompany, setOpen, open } = props;

  const handleClickOpen = () => {
    setOpenDeleteModel(true);
  };

  const handleClose = () => {
    setOpenDeleteModel(false);
  };

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code = message.response.status;
    if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  const deleteSectorReord = (e) => {
    e.preventDefault();
    setOpen(true);

    if (companyDeleteRecord != null) {
      const companyId = companyDeleteRecord.id;
      AxiosInstance.delete(`company/${companyId}`, {
        headers: { Accept: 'application/json' },
      })
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setOpenDeleteModel(false);
          loadCompany();
          setOpen(false);
        })
        .catch(function (error) {
          errorNotification(error, 500);
          setOpenDeleteModel(false);
        });
    } else {
      toast.error('Something Went wrong', TOAST_OPTIONS);
    }
  };

  return (
    <div>
      <Dialog
        open={openDeleteModel}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="ajs-header" style={{ color: '#000000', fontWeight: 1000 }} id="responsive-dialog-title">
          Delete Company
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            className=".ajs-body"
            // style={{ color: '#000000', paddingTop: '1%', paddingBottom: '1%' }}
            style={{ color: '#000000', marginBottom: 12 }}
          >
            Do you really want to delete company?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteSectorReord}
            style={{ lineHeight: 'normal', width: '20%', borderColor: '#21309d', border: '2px solid #21309d' }}
            className=" btn btn-dark btn-primary_submit submitButton shadow-none"
          >
            <span className="MuiButton-label">Ok</span>
          </Button>
          <Button
            className="btn btn-dark btn-primary_submit cancelButton shadow-none"
            style={{
              lineHeight: 'normal',
              width: '20%',
              color: '#21309d',
              outlineStyle: 'none !important',
              backgroundColor: '#ffffff !important',
              fontWeight: 'bold',
              fontFamily: 'Quicksand',
              borderRadius: '18px',
              borderColor: '#21309d',
              border: '2px solid #21309d',
            }}
            onClick={handleClose}
            color="secondary"
          >
            <span className="MuiButton-label">Cancel</span>
          </Button>
        </DialogActions>
        <Loader setOpen={setOpen} open={open} />
      </Dialog>
    </div>
  );
}
