import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Paper, Grid, Card } from '@mui/material';
import PropTypes from 'prop-types';

import Loader from '../Dashboard/BackdropLoader';
import TSRTable from './TSRTable';
import StocksTable from './StocksTable';
import AxiosInstance from '../../api/axios';
import { companyGroup, tableId } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  wrapperPaper: {
    margin: '40px',
    boxShadow: 'none',
    '@media (max-width: 769px)': {
      margin: '20px',
    },
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.5em',
    '& .btn': {
      whiteSpace: 'nowrap',
      marginLeft: '10px',
    },
  },
  heading: {
    marginTop: '1.8em',
    marginBottom: '0.5em',
    textAlign: 'left',
    '@media (max-width: 1024px)': {
      fontSize: '20px',
    },
  },
  subheading: {
    marginTop: '0',
    textAlign: 'left',
    float: 'left',
    '@media (max-width: 1024px)': {
      fontSize: '16px',
    },
  },
  table: {
    '& thead th': {
      fontWeight: '600',
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      cursor: 'pointer',
    },
  },
  tableWrapper: {
    overflowX: 'auto',
    marginTop: '24px',
    marginBottom: '0.5em',
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: '5px',
    boxShadow: 'none !important',
    '& table': {
      marginTop: '0 !important',
      tableLayout: 'auto',
    },
    '& th': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '& td': {
      whiteSpace: 'nowrap',
    },
    '& tbody tr:last-of-type td': {
      borderBottom: 'none',
    },
    '& td, & th': {
      padding: '10px !important',
      // fontSize: '16px',
      '@media (max-width: 1024px)': {
        padding: '10px 12px !important',
      },
    },
  },
  noRecordsMessage: {
    padding: '20px',
    textAlign: 'center',
  },
  col1: {
    width: '50%',
  },
  col2: {
    width: '25%',
  },
  col3: {
    width: '25%',
  },
}));

const AlphaGenerationContent = (props) => {
  const { mode } = props;

  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const endpoint =
          mode === 'edit' ? 'company/?fieldName=latest_score&order=DESC' : 'company/user?fieldName=name&order=ASC';
        const response = await AxiosInstance.get(endpoint);
        setCompanies(response.data.data);
      } finally {
        setOpen(false);
      }
    };

    setOpen(true);
    loadData();
  }, [mode]);

  const classes = useStyles();

  return (
    <>
      <Loader setOpen={setOpen} open={open} />

      <Box>
        <Paper className={classes.wrapperPaper}>
          <Card className="cardClass" style={{ margin: '0' }}>
            <Typography variant="h4" className="cardTitle">
              Alpha Generation
            </Typography>
          </Card>

          <Typography className={classes.heading} variant="h5">
            Current Top and Bottom Decile Scores
          </Typography>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 8 }}>
            <Grid item sm={12} md={6} xl={6} xs={12}>
              <StocksTable
                companies={companies.filter((company) => company.group === companyGroup.TOP_DECILE)}
                heading="Top Decile"
                classes={classes}
              />
            </Grid>
            <Grid item sm={12} md={6} xl={6} xs={12}>
              <StocksTable
                companies={companies.filter((company) => company.group === companyGroup.BOTTOM_DECILE)}
                heading="Bottom Decile"
                classes={classes}
              />
            </Grid>
          </Grid>

          <Typography variant="h5" className={classes.heading}>
            Alpha Generated Since Launch
          </Typography>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 8 }}>
            <Grid item sm={12} md={6} xl={6} xs={12}>
              <TSRTable
                tableId={tableId.TSR_SCORE_DECILES}
                mode={mode}
                heading="TSR by Score Deciles"
                classes={classes}
              />
            </Grid>
            <Grid item sm={12} md={6} xl={6} xs={12}>
              <TSRTable
                tableId={tableId.TSR_SCORE_QUARTILES}
                mode={mode}
                heading="TSR by Score Quartiles"
                classes={classes}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

AlphaGenerationContent.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default AlphaGenerationContent;
