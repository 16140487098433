import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const EmptyComponent = () => {
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '70vh' }}>
      <Typography>No Data Found</Typography>
    </Grid>
  );
};

export default EmptyComponent;
