import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    position: 'relative',
    flex: 1,
    transition: '.6s',
    transform: 'perspective(1000px)',
    transformStyle: 'preserve-3d',
  },
  btn: {
    border: 0,
    padding: 0,
    boxShadow: '0 4px 8px 0 rgba(0,0,0,.2)',
    backfaceVisibility: 'hidden',
    transform: 'rotateY(0deg)',
    transformStyle: 'preserve-3d',
    transitionDuration: '0.6s',
  },
  imageWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  metaWrapper: {
    display: 'flex',
    height: '100%',
    color: '#ffffff',
    backgroundColor: '#21309d',
    // aspect ratio 1/1
    '&::before': {
      content: '""',
      paddingBottom: '100%',
      float: 'left',
    },
    '&::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },
  meta: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
  },
  bio: {
    color: '#ffffff',
    textAlign: 'left',
    padding: '1%',
    margin: 0,
  },
  front: {
    transform: 'rotateY(0deg)',
  },
  frontActive: {
    transform: 'rotateY(180deg)',
  },
  back: {
    transform: 'rotateY(-180deg)',
  },
  backActive: {
    transform: 'rotateY(0deg)',
  },
}));
