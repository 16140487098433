import { Component } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class Portfolio extends Component {
  render() {
    return (
      <section
        id="portfolio"
        className="portfolio-area ptb-50 bg-f9f9f9"
        style={{ backgroundColor: '#21309D', textAlignLast: 'center' }}
      >
        <div className="container">
          <div className="section-title" style={{ color: '#eeeeee', marginBottom: '0px', paddingTop: '2%' }}>
            <h1>
              {this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b>
            </h1>
            <p
              style={{
                // color: '#eeeeee',
                paddingTop: '2%',
                color: '#ffffff',
                marginBottom: '0',
                fontSize: '24px',
              }}
            >
              {this.props.sectionContent}
            </p>
          </div>
        </div>
      </section>
    );
  }
}

Portfolio.PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
};

Portfolio.defaultProps = {
  sectionTitle: '',
  sectionTitleSpan: 'Careers',
  sectionContent: 'Please send your resume to research@ironblue.co.uk',
};

export default Portfolio;
