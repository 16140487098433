import axios from 'axios';

export const GOOGLE_CLOUD_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const AxiosInstance = axios.create({
  baseURL: GOOGLE_CLOUD_BASE_URL,
  // timeout: 60 * 1000, // 1 minute server timeout
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export default AxiosInstance;
