import ReactDOM from 'react-dom/client';
import * as history from 'history';
import App from './App';

// Axios interceptors setup
import NetworkService from './network-service';

const browserHistory = history.createBrowserHistory();
NetworkService.setupInterceptors(null, browserHistory);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
