import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Paper, TableBody, TableRow, TableCell, NativeSelect } from '@mui/material';
import _ from 'lodash';
import Loader from './Dashboard/BackdropLoader';
import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../api/axios';
import EmptyComponent from './Dashboard/emptyComponent';
import { routePathNames } from '../utils/constants';
import * as utils from '../utils/index';
import SectorTable from './Dashboard/AdminTable';
import UserHeader from './UserHeader';

const useStyles = makeStyles((theme) => ({
  // pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3),
  //     overflow: 'hidden !important',
  //     boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)'
  // },"@media (max-width: 750px)": {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3),
  //     boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  //     overflow: 'scroll !important',
  //   },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    overflow: 'hidden !important',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  },

  searchInput: {
    // width: '75%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

const headCells = [
  { id: 'name', label: 'Sector' },
  { id: 'reports', label: 'Report(s) Link', disableSorting: true },
  { id: 'latest_score', label: 'Latest Score' },
  { id: 'previous_score', label: 'Previous Score' },
  { id: 'change_yoy', label: 'Change' },
];

// eslint-disable-next-line react/function-component-definition
export default function Sectors() {
  const [company, setCompany] = useState([]);
  // const [sectorData, setSectorData] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopUp] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [openUploadReportModel, setUploadReportModel] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);
  const [upoadReportTitleName, setUploadReportTitleName] = useState('');
  const [getSectorList, setGetsectorList] = useState([]);
  const [open, setOpen] = useState(false);
  const [userSelectedCompanyId, setUserSelectedCompnyId] = useState('');
  const [userSelectedSectorId, SetUserSelectedSectorId] = useState(4);
  const [selectedCompanyResult, setselectedCompanyResult] = useState([]);
  const [tablehead, setTableHead] = useState('');
  const [sectors, setSectors] = useState([]);
  const navigate = useNavigate();

  const loadSector = async () => {
    setOpen(true);
    AxiosInstance.get('sector/user?fieldName=name&order=ASC')
      .then(function (response) {
        const finalRes = response.data.data;
        setSectors(finalRes);
        setOpen(false);
      })
      .catch(function (error) {
        setOpen(false);
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  // it loads sector list initially
  const getSectorListApi = () => {
    AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        // return finalRes
        setGetsectorList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const handlePdfUrlLink = (targetObject) => {
    const body = {
      fileUrl: targetObject.url,
      fileDate: targetObject.date_change,
      reportName: targetObject.reportName,
    };

    const url = `${GOOGLE_CLOUD_BASE_URL}sector/watermark/${body.reportName}?token=${utils.getAccessToken()}&fileUrl=${
      body.fileUrl
    }`;

    const newWindow = window.open(url, '_blank');
    if (newWindow == null || typeof newWindow === 'undefined') {
      //   alert(
      //     'Please disable your pop-up blocker and click the "Open" link again.'
      //   );
      document.location.href = url;
    } else {
      newWindow.focus();
    }
  };

  const analyticApi = (targetObject) => {
    console.log('ANYL call');
    let body = {};
    const userData = utils.decodeUserDetails();
    AxiosInstance.post(
      'analytics/sector',
      (body = {
        user_id: userData.userId,
        sc_id: targetObject.sector_id,
        sc_report_id: targetObject.reportId,
      }),
    )
      .then(function (response) {
        handlePdfUrlLink(targetObject);
      })
      .catch(function (error) {
        handlePdfUrlLink(targetObject);
      });
  };

  useEffect(() => {
    loadSector();
    getSectorListApi();
  }, []);

  const classes = useStyles();

  const {
    TablContainer,
    TblHead,
    // recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
  } = SectorTable(sectors, headCells, filterFn);

  const handleSectorClick = (item) => {
    const url = `${routePathNames.USER_SECTOR}/${item.id}`;
    navigate(url);
  };

  const handleReportLink = (e) => {
    if (e !== 'null') {
      const reportsArray = _.map(sectors, 'reports');
      const combineArray = _.uniq(_.flatten(reportsArray));
      const targetObject = _.find(combineArray, { url: e });
      setTimeout(() => {
        analyticApi(targetObject);
      });

      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      // document.location.href = url;
      // const newWindow = document.location.href;
      // if (newWindow) newWindow.opener = null
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const openAddSectorBtnPoopUp = () => {
    setOpenPopUp(true);
    setEditRecord(null);
  };

  return (
    <>
      <UserHeader
        getSectorList={getSectorList}
        company={company}
        SetUserSelectedSectorId={SetUserSelectedSectorId}
        userSelectedSectorId={userSelectedSectorId}
        setUserSelectedCompnyId={setUserSelectedCompnyId}
        setselectedCompanyResult={setselectedCompanyResult}
        setTableHead={setTableHead}
      />
      <Loader setOpen={setOpen} open={open} />
      {sectors && sectors.length ? (
        <Box textAlign="center">
          <Paper className="TableView">
            <TablContainer>
              <TblHead />
              <TableBody>
                {stableSort(sectors, getComparator(order, orderBy)).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell onClick={() => navigate(`${routePathNames.USER_SECTOR}/${item.id}`)} align="center">
                      {/* <Link href={routePathNames.USER_SECTOR + '/' + item.id} onClick={preventDefault}>{item.name} </Link> */}
                      <span style={{ color: '#007bff' }}> {item.name}</span>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {/* <DropDown item={item}/> */}
                      {/* <NativeSelect
                                                // onClick={onChangeSectorId(element)}
                                                // onClick={getSectorList}
                                                onClick= {(e) =>handleReportLink(e.target.value)}
                                                // onChange={handleReportLink(this.value)}
                                                inputProps={{
                                                    name: 'Select Sector',
                                                    id: 'age-native-label-placeholder',
                                                }}
                                            >
                                                {item.reports.map(element =>
                                                    (
                                                        <option value={element.url}> {element.date_change}</option>
                                                    ))}
                                                {item.reports.length === 0 && <option value="null">None</option>}

                                            </NativeSelect> */}

                      {item.reports && item.reports.length ? (
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <option value="null">Select Report</option>
                          {item.reports.map((element, index) => (
                            // eslint-disable-next-line react/no-unknown-property
                            <option value={element.url} date={element.date_change} key={index}>
                              {' '}
                              {element.date_change}
                            </option>
                          ))}
                        </NativeSelect>
                      ) : (
                        // <span>None</span>
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <>
                            <option value="null">Select Report</option>
                            <option value="null"> None</option>
                          </>
                        </NativeSelect>
                      )}
                    </TableCell>
                    <TableCell align="center">{item.latest_score}</TableCell>
                    <TableCell align="center">{item.previous_score}</TableCell>
                    <TableCell align="center">{item.change_yoy}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TablContainer>
          </Paper>
          {/* {error && <h5>{error}</h5>} */}
        </Box>
      ) : (
        !open && <EmptyComponent />
      )}
    </>
  );
}
