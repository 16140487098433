import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    loader: 'bubble-spin',
    color: '#21309D',
    size: 9,
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function BackdropLoader(props) {
  const { setOpen, open } = props;
  const classes = useStyles();
  //   const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleToggle}>
        Show backdrop
      </Button> */}
      <Backdrop
        className={classes.backdrop}
        open={open}
        loader="bubble-spin"
        color="#21309D"
        size={9}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
