import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Grid,
  TextField,
  Box,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  Checkbox,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import _without from 'lodash/without';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import Validation from './Validation';
import AxiosInstance from '../api/axios';
import { TOAST_OPTIONS } from '../utils/toast';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '3%',
      // width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '95%',
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  selectAllText: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: 'menu',
};

function getStyles(name, sectorName, theme) {
  return {
    fontWeight:
      sectorName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

// eslint-disable-next-line react/function-component-definition
export default function Registration(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [sectorsList, setSectorList] = useState([{}]);
  const [sectorName, setSectorName] = useState('');
  const [countryList, setCountryList] = useState([{}]);
  const [countryName, setCountryName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState([]);
  const [errors, setErrors] = useState({});
  const [getAllCountryValue, setAllCountryValue] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dropDownSectorName, setDropdownSsectorName] = useState([]);
  const [dropdownCountryName, setDropdownCountryName] = useState([]);
  const isAllSelected = dropdownCountryName.length > 0 && selectedCountry.length === dropdownCountryName.length;

  const [selectedAllSector, setSelectedAllSector] = useState([]);

  const isAllSelectedAllSector = dropDownSectorName.length > 0 && selectedSectors.length === dropDownSectorName.length;

  const { setLogin, setRegistration, handleModalOpen } = props;

  const fomrStyle = {
    padding: '30px',
  };

  const [userRegistration, setUserRegistration] = useState({
    userFirstName: '',
    userLastName: '',
    companyName: '',
    userEmail: '',
    userPassword: '',
    userConfPassword: '',
  });

  const loadSectorList = async () => {
    AxiosInstance.get('user/sector')
      .then(function (response) {
        const finalRes = response.data.data;
        const sectorNameDropdown = _.map(finalRes, 'name');
        setDropdownSsectorName(sectorNameDropdown);
        setSectorList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const onClickSelectAll = (event) => {
    const companyName = _.map(countryList, 'name');
    setSelectedCountry(companyName);
    // setAllCountryValue(companyName)
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === countryList.length ? [] : countryList);
      return;
    }
    setSelected(value);
  };

  const loadCountryList = async () => {
    AxiosInstance.get('user/country')
      .then(function (response) {
        const finalRes = response.data.data;
        const countryNameDropdown = _.map(finalRes, 'name');
        setCountryList(finalRes);
        setDropdownCountryName(countryNameDropdown);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelectedSectors(selectedSectors.length === dropDownSectorName.length ? [] : dropDownSectorName);
      return;
    }
    setSelectedSectors(value);
  };

  const handleDelete = (e, value) => {
    setSelectedCountry((current) => _without(current, value));
  };

  const handleSectorDelete = (e, value) => {
    setSelectedSectors((current) => _without(current, value));
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelectedCountry(selectedCountry.length === dropdownCountryName.length ? [] : dropdownCountryName);
      return;
    }
    setSelectedCountry(value);
  };

  const mystyle = {
    backgroundColor: '#21309D',
  };
  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const { code } = message.response.data;
    if (code === 406) {
      toast.warning(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = (message, statusValue) => {
    const status = message.data.code;
    const displayMessage = message.data.message;
    if (status === 200) {
      toast.success(displayMessage, TOAST_OPTIONS);
    } else if (status === 406) {
      toast.warning(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
      setUserRegistration({
        userFirstName: '',
        userLastName: '',
        companyName: '',
        userEmail: '',
        userPassword: '',
        userConfPassword: '',
      });
    }
  };
  const handleInput = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setUserRegistration({ ...userRegistration, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRecord = { ...userRegistration, id: new Date().getTime().toString() };

    const getSectorResult = _(sectorsList).keyBy('name').at(selectedSectors).value();

    const getFileteredSectorId = _.map(getSectorResult, 'id');
    const getCountryResult = _(countryList).keyBy('name').at(selectedCountry).value();

    const getFileteredCountryId = _.map(getCountryResult, 'id');

    setErrors(Validation(newRecord));
    const data = {
      body: {
        first_name: userRegistration.userFirstName,
        last_name: userRegistration.userLastName,
        email: userRegistration.userEmail,
        user_company: userRegistration.companyName,
        password: userRegistration.userPassword,
        sector: getFileteredSectorId,
        country: getFileteredCountryId,
      },
    };

    if (newRecord.userPassword !== newRecord.userConfPassword) {
      const error = 'Password and confirm password should be same.';
      toast.error(error, TOAST_OPTIONS);
    } else {
      AxiosInstance.post('signUp', data.body, {
        headers: { Accept: 'application/json' },
      })
        .then(function (response) {
          const { code } = response.data;
          const message = response;

          setUserRegistration({
            userFirstName: '',
            userLastName: '',
            companyName: '',
            userEmail: '',
            userPassword: '',
            userConfPassword: '',
            setSelectedCountry: [],
            setSelectedSectors: [],
          });
          setSelectedCountry([]);
          setSelectedSectors([]);
          notificationMessage(message, code);
          setTimeout(() => {
            handleModalOpen(false);
          }, 1000);
        })
        .catch(function (error) {
          errorNotification(error, 500);
        });
    }
  };

  useEffect(() => {
    loadSectorList();
    loadCountryList();
  }, []);

  return (
    <form style={fomrStyle}>
      <Grid item xs={12} style={{ paddingBottom: '2%', paddingRight: '2%', paddingLeft: '2%' }}>
        {/* <label>First Name</label> */}
        <TextField
          type="text"
          label="First Name"
          value={userRegistration.userFirstName}
          onChange={handleInput}
          name="userFirstName"
          placeholder="Enter your first name"
          fullWidth
        />
        {errors.userFirstName && <p className="error">{errors.userFirstName}</p>}
      </Grid>

      <Grid item xs={12} className="registrationStyle">
        {/* <label>Last Name</label> */}
        <TextField
          type="text"
          label="Last Name"
          value={userRegistration.userLastName}
          onChange={handleInput}
          name="userLastName"
          placeholder="Enter your last name"
          fullWidth
        />
        {errors.userLastName && <p className="error">{errors.userLastName}</p>}
      </Grid>

      <Grid item xs={12} className="registrationStyle">
        {/* <label>Company Name</label> */}
        <TextField
          type="text"
          label="Company Name"
          value={userRegistration.companyName}
          onChange={handleInput}
          name="companyName"
          placeholder="Enter your company name"
          fullWidth
        />
        {errors.companyName && <p className="error">{errors.companyName}</p>}
      </Grid>

      <Grid item xs={12} className="registrationStyle">
        {/* <label>Email</label> */}
        <TextField
          type="email"
          label="Email"
          value={userRegistration.userEmail}
          onChange={handleInput}
          name="userEmail"
          placeholder="Enter your email"
          fullWidth
        />
        {errors.userEmail && <p className="error">{errors.userEmail}</p>}
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <Box alignItems="center" display="flex">
            <InputLabel id="demo-mutiple-chip-label">Select Sectors</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={selectedSectors}
              onChange={handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(choosed) => (
                <div className={classes.chips}>
                  {choosed.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      clickable
                      deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      onDelete={(e) => handleSectorDelete(e, value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
              fullWidth
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelectedAllSector ? classes.selectedAll : '',
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelectedAllSector}
                    indeterminate={selectedSectors.length > 0 && selectedSectors.length < dropDownSectorName.length}
                  />
                  <ListItemText className={classes.selectAllText} primary="Select All Sectors" />
                </ListItemIcon>
              </MenuItem>

              {sectorsList.map((item, index) => (
                <MenuItem key={`${item.id}-${index}`} value={item.name} style={getStyles(item, sectorName, theme)}>
                  <Checkbox checked={selectedSectors.indexOf(item.name) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
            <Tooltip title="You can select multiple sectors" arrow>
              <HelpIcon style={{ width: '1.2rem' }} />
            </Tooltip>
          </Box>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <Box alignItems="center" display="flex">
            <InputLabel id="demo-mutiple-chip-label">Select Country </InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={selectedCountry}
              onChange={handleCountryChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(choosed) => (
                <div className={classes.chips}>
                  {choosed.map((value, index) => (
                    <Chip
                      key={index}
                      label={value}
                      clickable
                      deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      // onDelete={handleDelete(value)}
                      onDelete={(e) => handleDelete(e, value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
              fullWidth
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : '',
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={selectedCountry.length > 0 && selectedCountry.length < dropdownCountryName.length}
                  />
                  <ListItemText className={classes.selectAllText} primary="Select All Countries" />
                </ListItemIcon>
              </MenuItem>
              {countryList.map((item, index) => (
                <MenuItem key={`${item.id}-${index}`} value={item.name} style={getStyles(item, sectorName, theme)}>
                  <Checkbox checked={selectedCountry.indexOf(item.name) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
            <Tooltip title="You can select multiple countries" arrow>
              <HelpIcon style={{ width: '1.2rem' }} />
            </Tooltip>
          </Box>
        </FormControl>
      </Grid>

      <Grid item xs={12} className="registrationStyle">
        {/* <label>Password</label> */}
        <TextField
          type="password"
          label="Password"
          value={userRegistration.userPassword}
          onChange={handleInput}
          name="userPassword"
          autoComplete="off"
          placeholder="Enter password"
          fullWidth
        />
        {errors.userPassword && <p className="error">{errors.userPassword}</p>}
      </Grid>

      <Grid item xs={12} className="registrationStyle" style={{ paddingBottom: '6%' }}>
        {/* <label>Confirm Password</label> */}
        <TextField
          type="password"
          label="Confirm Password"
          value={userRegistration.userConfPassword}
          onChange={handleInput}
          name="userConfPassword"
          autoComplete="off"
          placeholder="Enter confirm password"
          fullWidth
        />
        {errors.userConfPassword && <p className="error">{errors.userConfPassword}</p>}
      </Grid>
      <Box style={{ paddingTop: '2%' }}>
        <div className="d-grid">
          <button
            type="submit"
            style={mystyle}
            className="btn btn-dark btn-lg btn-block btn-primary_submit shadow-none"
            onClick={handleSubmit}
          >
            Create Account
          </button>
        </div>
      </Box>
    </form>
  );
}
