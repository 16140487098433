import { useEffect, useState } from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { makeStyles } from '@mui/styles';
import { ButtonBase } from '@mui/material';

import { DoubleArrow } from './Icons';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    right: -50,
    bottom: 20,
    width: 40,
    height: 40,
    color: '#ffffff',
    borderRadius: '50%',
    backgroundColor: '#131111',
    transition: 'opacity 0.5s ease-in-out 0s, visibility 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s',
    opacity: 0,
    visibility: 'hidden',
    zIndex: 1000,
  },
  isActive: {
    right: 20,
    opacity: 1,
    visibility: 'visible',
  },
}));

const ScrollUpBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const classes = useStyles();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const scrollEvent = debounce(() => {
      if (window.scrollY > 170) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 50);

    window.addEventListener('scroll', scrollEvent);
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  return (
    <ButtonBase type="button" className={clsx(classes.root, isVisible && classes.isActive)} onClick={handleClick}>
      <DoubleArrow />
    </ButtonBase>
  );
};

export default ScrollUpBtn;
