import { Helmet } from 'react-helmet';
import UserHeader from '../UserHeader';
import AlphaGenerationContent from './AlphaGenerationContent';

const AlphaGenerationAdminView = () => {
  return (
    <>
      <Helmet>
        <title>Alpha Generation - Iron Blue Financials</title>
      </Helmet>
      <UserHeader />
      <AlphaGenerationContent mode="read" />
    </>
  );
};

export default AlphaGenerationAdminView;
