import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { TOAST_OPTIONS } from '../utils/toast';

const Layout = ({ children }) => {
  const location = useLocation();

  // set toast message by state from location
  useEffect(() => {
    if (location.state?.toast) {
      const { type, message } = location.state.toast;
      toast[type](message, TOAST_OPTIONS);
    }
  }, [location]);

  return (
    <>
      <ToastContainer icon={false} />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
