import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

const useStyles = makeStyles(() => ({
  swiper: {
    paddingTop: '5%',
    '& .swiper-pagination-horizontal': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 40,
    },
    '& .swiper-pagination-bullet': {
      width: 15,
      height: 15,
      backgroundColor: '#d7d7d7', // todo: theme
      borderRadius: '100%',
      margin: [[0, 5]],
      transition: '0.4s',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#21309d', // todo: theme
      },
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: '#21309d', // todo: theme
    },
  },
}));

const image = require('../assets/img/about_us_img.jpg');
const sectionImage = require('../assets/img/about 1.png');

const About = (props) => {
  const classes = useStyles();

  return (
    <section id="about" className="about-area ptb-100 bg-f9f9f9">
      <div className="container">
        <div className="row">
          <div className="about-image desktop-only">
            <img src={props.sectionImage} alt="about-img" />
          </div>

          <div className="about-image mobile-only">
            <img src={image} alt="about-img" />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 offset-lg-6">
            <Swiper
              className={classes.swiper}
              modules={[Pagination]}
              slidesPerView={1}
              spaceBetween={30}
              loop
              speed={700}
              pagination={{ clickable: true }}
              allowTouchMove={false}
            >
              <SwiperSlide>
                <div className="about-title">
                  <h3>
                    {props.skillToptitle} {props.skillToptitlespan}
                  </h3>
                  <h5 className="SubheadColor">
                    {props.skillTitle} <span>{props.skillTitlespan}</span>
                  </h5>
                  <p>{props.skillContent}</p>
                </div>

                <div className="our-skill">
                  <div className="single-progress">
                    <h3 className="progress-title">{props.progressTitle1}</h3>
                    <div className="progress">
                      <div className="progress-bar" style={{ width: '78%' }}>
                        <div className="progress-value">{props.progressValue1}</div>
                      </div>
                    </div>
                  </div>

                  <div className="single-progress">
                    <h3 className="progress-title">{props.progressTitle2}</h3>
                    <div className="progress">
                      <div className="progress-bar" style={{ width: '100%' }}>
                        <div className="progress-value">{props.progressValue2}</div>
                      </div>
                    </div>
                  </div>

                  <div className="single-progress">
                    <h3 className="progress-title">{props.progressTitle3}</h3>
                    <div className="progress">
                      <div className="progress-bar" style={{ width: '50%' }}>
                        <div className="progress-value">{props.progressValue3}</div>
                      </div>
                    </div>
                  </div>

                  <div className="single-progress">
                    <h3 className="progress-title">{props.progressTitle4}</h3>
                    <div className="progress">
                      <div className="progress-bar" style={{ width: '99%' }}>
                        <div className="progress-value">{props.progressValue4}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="about-title">
                  <h3>
                    {props.item2topTitle} {props.item2topTitlespan}
                  </h3>
                  <h5 className="SubheadColor">
                    {props.item2Title}
                    <span> {props.item2Titlespan}</span>
                  </h5>
                  <p>{props.item2ContentTop}</p>
                </div>

                <div className="our-mission">
                  <p>{props.item2ContentMiddle}</p>
                  <p>{props.item2ContentBottom}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="about-title">
                  <h3>
                    {props.item3TopTitle} {props.item3TopTitleSpan}
                  </h3>
                  <h5 className="SubheadColor">
                    {props.item3Title} <span>{props.item3TitleSpan}</span>
                  </h5>
                  <p>{props.item3Content}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      {/* <div className="animation-box1">
                <img src={props.animationImg1} alt="styleimage" />
            </div>
            <div className="animation-box2">
                <img src={props.animationImg2} alt="styleimage" />
            </div>
            <div className="animation-box3">
                <img src={props.animationImg3} alt="styleimage" />
            </div>
            <div className="animation-box6">
                <img src={props.animationImg4} alt="styleimage" />
            </div> */}
    </section>
  );
};

About.propTypes = {
  sectionImage: PropTypes.string,

  // animationImg1: PropTypes.string,
  // animationImg2: PropTypes.string,
  // animationImg3: PropTypes.string,
  // animationImg4: PropTypes.string,

  skillToptitle: PropTypes.string,
  skillToptitlespan: PropTypes.string,
  skillTitle: PropTypes.string,
  skillTitlespan: PropTypes.string,
  skillContent: PropTypes.string,
  progressTitle1: PropTypes.string,
  progressValue1: PropTypes.string,
  progressTitle2: PropTypes.string,
  progressValue2: PropTypes.string,
  progressTitle3: PropTypes.string,
  progressValue3: PropTypes.string,
  progressTitle4: PropTypes.string,
  progressValue4: PropTypes.string,

  item2topTitle: PropTypes.string,
  item2topTitlespan: PropTypes.string,
  item2Title: PropTypes.string,
  item2Titlespan: PropTypes.string,
  item2ContentTop: PropTypes.string,
  item2ContentMiddle: PropTypes.string,
  item2ContentBottom: PropTypes.string,

  item3TopTitle: PropTypes.string,
  item3TopTitleSpan: PropTypes.string,
  item3Title: PropTypes.string,
  item3TitleSpan: PropTypes.string,
  item3Content: PropTypes.string,
  // logo1: PropTypes.string,
  // logo2: PropTypes.string,
  // logo3: PropTypes.string,
  // logo4: PropTypes.string,
  // logo5: PropTypes.string,
  // logo6: PropTypes.string,
  // log1Title: PropTypes.string,
  // log2Title: PropTypes.string,
  // log3Title: PropTypes.string,
  // log4Title: PropTypes.string,
  // log5Title: PropTypes.string,
  // log6Title: PropTypes.string,
};

About.defaultProps = {
  sectionImage,

  // animationImg1: require("../../src/assets/img/3.png"),
  // animationImg2: require("../../src/assets/img/4.png"),
  // animationImg3: require("../../src/assets/img/1.png"),
  // animationImg4: require("../../src/assets/img/6.png"),

  skillToptitle: 'About',
  skillToptitlespan: 'Iron Blue Financials',
  skillTitle: 'Detailed analysis of the small print in company annual reports',
  skillTitlespan: '',
  skillContent: 'We help predict corporate profit warnings by scoring companies on their:',
  progressTitle1: 'Discretionary Accounting',
  progressValue1: '100%',
  progressTitle2: 'Governance',
  progressValue2: '100%',
  progressTitle3: 'Disclosures',
  progressValue3: '100%',
  progressTitle4: 'Management & Auditor Risks',
  progressValue4: '100%',

  item2topTitle: 'The Iron Blue ',
  item2topTitlespan: 'Mission',
  item2Title: 'Independent, Detailed and Consistent',
  item2Titlespan: '',
  item2ContentTop:
    'Our analysis delivers impartial scoring of corporates according to the disclosures contained in their annual reports. Our findings can be folded into the internal processes of institutional investors to generate new investment ideas, both on the long and short side.',
  item2ContentMiddle:
    'Our experience is that companies with less conservative accounting, conflicted governance and opaque disclosures are more prone to profit warnings. We focus our attention on annual reports because they are typically the most regulated and detailed store of information about a company.',
  item2ContentBottom:
    "As well as a fuller set of accounts than those published in preliminary results, they also contain management's principle risks assessment as well as reports from the auditor and various board committees.",

  item3TopTitle: 'Why Choose',
  item3TopTitleSpan: 'Us',
  item3Title: 'Experienced and Differentiated',
  item3TitleSpan: '',
  item3Content:
    "Iron Blue's founder Ed Steele has more than two decades experience as a top rated equity analyst, including more than a dozen years of publishing accounting and annual report analysis inside major investment banks.",
};

export default About;
