/* eslint-disable react/no-unescaped-entities */
import { makeStyles } from '@mui/styles';
import { Typography, Card, CardContent } from '@mui/material';
import { Helmet } from 'react-helmet';
import UserView from './UserView';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    //   marginLeft:"10%",
    //   marginTop:"5%",
    //   marginRight:"10%"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

// eslint-disable-next-line react/function-component-definition
export default function OurApproach() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <div>
      <Helmet>
        <title>Our Approach - Iron Blue Financials</title>
      </Helmet>
      <UserView />

      <Card className="cardClass">
        <Typography variant="h4" className="cardTitle">
          Our Approach
        </Typography>
      </Card>

      <Card className="cardClass" style={{ display: 'block' }}>
        <CardContent>
          {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
        Terms & Conditions
        </Typography> */}
          {/* <Typography variant="h5" component="h2">
        Terms & Conditions
        </Typography> */}
          {/* <Typography className={classes.pos} color="textSecondary">
          adjective
        </Typography> */}
          {/* <Typography variant="body2" component="p" style={{paddingBottom:"3%"}}>
        <Typography className={classes.pos} color="textSecondary">
        Iron Blue offers investors independent analysis and balanced scoring of listed companies according to the disclosures contained in their annual reports. We focus our attention on annual reports because these are typically the most detailed and regulated available stores of information. Whilst their front pages can read as if written by the company’s marketing department, they also contain a fuller set of accounts than published in preliminary results perhaps a month or two earlier, reports from the auditor, various board committees and management’s principle risks assessment. All of this information can quality check previously reported profits and balance sheets. This allows investors to assess the conservatism and transparency of a company's accounting, governance and disclosures relative to peers and history. 
        </Typography>

        <Typography className={classes.pos} color="textSecondary">
        <div>
         <Typography variant="h5" style={{paddingTop:"2%"}} >Iron Blue’s core beliefs</Typography>
                </div>
                <Typography className={classes.pos} color="textSecondary">
                1. Our experience is that companies with less conservative accounting, conflicted governance and opaque disclosure are more prone to profit warnings.     </Typography>
                <Typography className={classes.pos} color="textSecondary">
                2. If investors can avoid more profit warnings than their peers, they stand a good chance of outperforming.</Typography>
                <Typography className={classes.pos} color="textSecondary">3. We believe that management teams coping with operational and financial pressure often hope for the best and present their earnings and balance sheet in the best possible light. This is not always helpful for investors.</Typography>
                <Typography className={classes.pos} color="textSecondary"> 4. In this scenario, a company’s true predicament is not usually revealed by a “smoking gun” but rather the meticulous assembly of many small “pieces of the jigsaw”.</Typography>
                <Typography className={classes.pos} color="textSecondary"> 5. We advise investors to be sceptical of companies that we score highly. It is usually impossible to ascertain with precision the extent of the earnings or net debt gap versus a conservative reality. The ultimate reset can be greater than even we anticipate, and it often takes longer than most expect for the company and share price subsequently to recover.</Typography>
                <Typography className={classes.pos} color="textSecondary">   6. Whilst locating fraud is always possible, our principal focus is highlighting run of the mill profit warning risk.</Typography>
     </Typography>

     <Typography className={classes.pos} color="textSecondary">

     <Typography variant="h5" style={{paddingTop:"2%"}}> How to use our research </Typography>
            Long-only investor clients might use our lower scoring companies as a screen for new investments and revisit any holdings that score highly. For those investors who also short stocks, our higher scoring corporates could offer a helpful starting point.
            A high or low absolute (or relative to peer) score is interesting but the direction of travel (yoy change) can also offer insight.
            We advise investors to supplement our analysis and scoring with their own investment decision-making tools. We do not, for example, carry out fundamental research into the companies and industries or perform equity valuations.
            Each Iron Blue company report also includes a list of questions for management that can be used by investors during company meetings.
     </Typography>

     <Typography variant="h5" style={{paddingTop:"2%"}}>   Our scoring system </Typography>
     <Typography className={classes.pos} color="textSecondary">
            Iron Blue attributes each company under coverage an overall rating out of 60, which is the aggregation of four scores: Accounting (40), Governance (10), Disclosures (5) and Risks (5). Accounting and Governance are also sub-divided into component scores.
            A company’s approach to discretionary areas of accounting is our largest focus and we analyse revenue and cost recognition approaches and net debt calculations. Some companies choose to recognise revenue early and/or costs late (or stripped out below the line) and some squeeze net debt at period ends or exclude items from this calculation. We consider a few specific areas where our experience suggests there is notable scope for accounting discretion: defined benefit pensions, JVs & associates and acquisitions. We also evaluate the use of non-GAAP measures.
            Corporate governance standards vary widely. We score companies on their audit process (eg auditor tenure, non-audit fees, subsidiary accounting, FRC letters), their management structure (eg board and committee composition, related party transactions, different classes of shares and change of control restrictions) and remuneration approach.
            We also consider the level of disclosure provided by each company (eg segmental split, acquisition fair value adjustments, receivables factoring, disaggregation of one-off costs, etc) and auditor and management risk commentaries. 
          <br />
          </Typography>
        </Typography> */}

          <Typography gutterBottom>
            <span style={{ color: '#000000' }}>
              {' '}
              Iron Blue offers investors independent analysis and balanced scoring of listed companies according to the
              disclosures contained in their annual reports. We focus our attention on annual reports because these are
              typically the most detailed and regulated available stores of information. Whilst their front pages can
              read as if written by the company’s marketing department, they also contain a fuller set of accounts than
              published in preliminary results perhaps a month or two earlier, reports from the auditor, various board
              committees and management’s principle risks assessment. All of this information can quality check
              previously reported profits and balance sheets. This allows investors to assess the conservatism and
              transparency of a company's accounting, governance and disclosures relative to peers and history.
            </span>{' '}
          </Typography>

          <Typography gutterBottom>
            <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000' }}>
              Iron Blue’s Core Beliefs
            </Typography>
            <Typography gutterBottom>
              {' '}
              <span style={{ color: '#000000' }}>
                1. Our experience is that companies with less conservative accounting, conflicted governance and opaque
                disclosure are more prone to profit warnings.
              </span>
            </Typography>
            <Typography gutterBottom>
              {' '}
              <span style={{ color: '#000000' }}>
                2. If investors can avoid more profit warnings than their peers, they stand a good chance of
                outperforming.
              </span>
            </Typography>
            <Typography gutterBottom>
              {' '}
              <span style={{ color: '#000000' }}>
                3. We believe that management teams coping with operational and financial pressure often hope for the
                best and present their earnings and balance sheet in the best possible light. This is not always helpful
                for investors.
              </span>
            </Typography>
            <Typography gutterBottom>
              {' '}
              <span style={{ color: '#000000' }}>
                4. In this scenario, a company’s true predicament is not usually revealed by a “smoking gun” but rather
                the meticulous assembly of many small “pieces of the jigsaw”.
              </span>
            </Typography>
            <Typography gutterBottom>
              {' '}
              <span style={{ color: '#000000' }}>
                5. We advise investors to be sceptical of companies that we score highly. It is usually impossible to
                ascertain with precision the extent of the earnings or net debt gap versus a conservative reality. The
                ultimate reset can be greater than even we anticipate, and it often takes longer than most expect for
                the company and share price subsequently to recover.
              </span>
            </Typography>
            <Typography gutterBottom>
              {' '}
              <span style={{ color: '#000000' }}>
                6. Whilst locating fraud is always possible, our principal focus is highlighting run of the mill profit
                warning risk.
              </span>
            </Typography>
          </Typography>

          <Typography gutterBottom>
            <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000' }}>
              How To Use Our Research
            </Typography>
            <span style={{ color: '#000000' }}>
              {' '}
              Long-only investor clients might use our lower scoring companies as a screen for new investments and
              revisit any holdings that score highly. For those investors who also short stocks, our higher scoring
              corporates could offer a helpful starting point. A high or low absolute (or relative to peer) score is
              interesting but the direction of travel (yoy change) can also offer insight. We advise investors to
              supplement our analysis and scoring with their own investment decision-making tools. We do not, for
              example, carry out fundamental research into the companies and industries or perform equity valuations.
            </span>{' '}
          </Typography>

          <Typography gutterBottom>
            <span style={{ color: '#000000' }}>
              Each Iron Blue company report also includes a list of questions for management that can be used by
              investors during company meetings.
            </span>
          </Typography>

          <Typography gutterBottom>
            <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000' }}>
              Our Scoring System
            </Typography>
            <span style={{ color: '#000000' }}>
              {' '}
              Iron Blue attributes each company under coverage an overall rating out of 60, which is the aggregation of
              four scores: Accounting (40), Governance (10), Disclosures (5) and Risks (5). Accounting and Governance
              are also sub-divided into component scores. A company’s approach to discretionary areas of accounting is
              our largest focus and we analyse revenue and cost recognition approaches and net debt calculations. Some
              companies choose to recognise revenue early and/or costs late (or stripped out below the line) and some
              squeeze net debt at period ends or exclude items from this calculation. We consider a few specific areas
              where our experience suggests there is notable scope for accounting discretion: defined benefit pensions,
              JVs & associates and acquisitions. We also evaluate the use of non-GAAP measures. Corporate governance
              standards vary widely. We score companies on their audit process (eg auditor tenure, non-audit fees,
              subsidiary accounting, FRC letters), their management structure (eg board and committee composition,
              related party transactions, different classes of shares and change of control restrictions) and
              remuneration approach. We also consider the level of disclosure provided by each company (eg segmental
              split, acquisition fair value adjustments, receivables factoring, disaggregation of one-off costs, etc)
              and auditor and management risk commentaries.
            </span>{' '}
          </Typography>

          <Typography gutterBottom>
            <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000' }}>
              Iron Blue employee holdings disclosure{' '}
            </Typography>
            <span style={{ color: '#000000' }}>
              {' '}
              Iron Blue employees currently own securities in the following covered companies: none.
            </span>{' '}
          </Typography>
        </CardContent>
        {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </Card>
    </div>
  );
}
