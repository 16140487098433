import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './TeamCard.styles';

const TeamCard = ({
  className,
  img,
  name,
  profession,
  bio,
  isFlipped: initialIsFlipped = false,
  onClick = (e) => e,
  ...props
}) => {
  const classes = useStyles();

  const [isFlipped, setIsFlipped] = useState(initialIsFlipped);

  const handleButtonClick = (e) => {
    setIsFlipped((prev) => !prev);
    onClick(e);
  };

  useEffect(() => {
    if (initialIsFlipped !== isFlipped) {
      setIsFlipped(initialIsFlipped);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIsFlipped]);

  return (
    <div className={clsx(classes.root, className)} {...props}>
      <div className={classes.media}>
        <button
          type="button"
          className={clsx(classes.btn, classes.imageWrapper, classes.front, [{ [classes.frontActive]: isFlipped }])}
          onClick={handleButtonClick}
        >
          <img className={classes.image} src={img} alt="team-img" />
        </button>
        <button
          type="button"
          className={clsx(classes.btn, classes.metaWrapper, classes.back, [{ [classes.backActive]: isFlipped }])}
          onClick={handleButtonClick}
        >
          <div className={clsx(classes.meta)}>
            <h3>{name}</h3>
            <p className={classes.bio}>{bio}</p>
          </div>
        </button>
      </div>
      <h3>{name}</h3>
      <span>{profession}</span>
    </div>
  );
};

TeamCard.propTypes = {
  className: PropTypes.string,
  img: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  profession: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  bio: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isFlipped: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TeamCard;
