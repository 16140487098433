import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { Typography, Box, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import EmailFields from './emailData';
import AlertModel from './AlertBox';
import AxiosInstance from '../../api/axios';
import Loader from './BackdropLoader';
import { TOAST_OPTIONS } from '../../utils/toast';
import { REPORT_NOTIFICATION_TYPES } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200,
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function UploadReportModel(props) {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    openUploadReportModel,
    setUploadReportModel,
    uploadReport,
    loadSector,
    urlReq,
    upoadReportTitleName,
    setUploadReportTitleName,
    open,
    setOpen,
    subject,
    setSubject,
    mailBody,
    setMailBody,
    selectedSectors,
    setSelectedSectors,
    file,
    setFile,
    fileName,
    setFileName,
    selectedDate,
    setSelectedDate,
    selectedCountry,
    setSelectedCountry,
    setgetTimeZoneValue,
    getTimeZoneValue,
  } = props;
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [time, SetTime] = useState(null);

  const [sectorsList, setSectorList] = useState([{}]);
  const [sectorName, setSectorName] = useState('');
  const [countryList, setCountryList] = useState([{}]);
  const [notificationType, setNotificationType] = useState(REPORT_NOTIFICATION_TYPES.ALL_USERS);

  let dateFormat = '';
  if (selectedDate !== null) {
    dateFormat = moment(selectedDate).format('YYYY-MM-DD');
  }

  const handleClose = () => {
    SetTime(null);
    setUploadReportModel(false);
  };

  const getSectorList = async () => {
    AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC')
      .then((response) => {
        const finalRes = response.data.data;
        setSectorList(finalRes);
      })
      .catch((error) => {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const loadCountryList = async () => {
    AxiosInstance.get('user/country')
      .then((response) => {
        const finalRes = response.data.data;
        setCountryList(finalRes);
      })
      .catch((error) => {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  useEffect(() => {
    getSectorList();
    loadCountryList();
    setUploadReportTitleName('');
    setSelectedDate('');
    setFileName('');
    setFile('');
    setMailBody('');
    setSubject('');
    setSelectedCountry([]);
    setSelectedSectors([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectTime(e) {
    const str = String(e);
    SetTime(str);
  }

  const errorNotification = (message) => {
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code =
      message && message.response && message.response.code && message.response.data.code
        ? message.response.data.code
        : 500;
    if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  const uploadFile = (e) => {
    const fileValue = e.target.files;
    const render = new FileReader();
    render.readAsDataURL(fileValue[0]);
    const newFileName = fileValue[0].name;
    setFileName(newFileName);
    render.onload = (event) => {
      const fileData = event.target.result;
      const finalFile = fileData.split(/[,]/);
      setFile(finalFile[1]);
    };
  };

  function onSelectDateCheckDate(e) {
    setSelectedDate(e);
    const body = {
      id: uploadReport.id,
      date_change: e,
      reportId: null,
    };
    AxiosInstance.post('sector/checkReportDate', body)
      .then((response) => {
        const checkDateRes = response.data.date;
        if (checkDateRes === false) {
          setOpenAlertBox(true);
        } else {
          console.log('==> no issue');
        }
      })
      .catch((error) => {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  }

  const UploadSectorReord = (e) => {
    setOpen(true);
    e.preventDefault();

    const getSectorResult = _(sectorsList).keyBy('name').at(selectedSectors).value();

    const getFileteredSectorId = _.map(getSectorResult, 'id');

    const getCountryResult = _(countryList).keyBy('name').at(selectedCountry).value();

    const getFileteredCountryId = _.map(getCountryResult, 'id');

    const getOptionalFields = () => {
      switch (notificationType) {
        case REPORT_NOTIFICATION_TYPES.ALL_USERS:
          return {
            email_subject: subject,
            email_body: mailBody,
          };
        case REPORT_NOTIFICATION_TYPES.SECTORS_AND_COUNTRIES:
          return {
            email_subject: subject,
            email_body: mailBody,
            sectorIds: getFileteredSectorId,
            countryIds: getFileteredCountryId,
          };
        case REPORT_NOTIFICATION_TYPES.DISABLE:
        default:
          return {
            email_subject: '',
            email_body: '',
          };
      }
    };

    if (uploadReport != null) {
      const sectorId = uploadReport.id;
      const timeValue = time !== null ? time : getTimeZoneValue;
      const optionalFields = getOptionalFields();
      const body = {
        sectorId,
        notify_by_email: notificationType,
        date_change: dateFormat,
        fileType: 'application/pdf',
        fileName,
        file,
        name: uploadReport.name,
        time: `${timeValue}:00`,
        ...optionalFields,
      };
      console.log('sector modal pdf', body);
      AxiosInstance.post(urlReq, body)
        .then((response) => {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setUploadReportModel(false);
          setUploadReportTitleName('');
          setSelectedDate('');
          loadSector();
          setFileName('');
          setFile('');
          setOpen(false);
          setMailBody('');
          setSubject('');
          SetTime(null);
          setSelectedCountry([]);
          setSelectedSectors([]);
          setNotificationType(REPORT_NOTIFICATION_TYPES.ALL_USERS);
        })
        .catch((error) => {
          errorNotification(error, 500);
          // setUploadReportModel(false);
          setOpen(false);
        });
    } else {
      SetTime(null);
      toast.error('Something Went wrong', TOAST_OPTIONS);
    }
  };

  return (
    <div>
      <Loader setOpen={setOpen} open={open} />
      <AlertModel
        openAlertBox={openAlertBox}
        setOpenAlertBox={setOpenAlertBox}
        setSelectedDate={setSelectedDate}
        setUploadReportModel={setUploadReportModel}
      />
      <Dialog
        fullScreen={fullScreen}
        open={openUploadReportModel}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
      >
        <Box style={{ paddingLeft: '3%' }}>
          <DialogTitle id="responsive-dialog-title">Upload Reports</DialogTitle>
        </Box>
        <DialogContent dividers>
          <Typography
            variant="h6"
            style={{
              textAlign: 'Left',
              paddingBottom: '2%',
              paddingLeft: '3%',
            }}
            component="h5"
          >
            {upoadReportTitleName}
          </Typography>
          <Box style={{ paddingLeft: '3%', paddingRight: '3%' }}>
            <TextField
              id="date"
              label="Report Date"
              type="date"
              defaultValue={selectedDate}
              fullWidth
              // className={classes.textField}
              // onChange={(e) => setSelectedDate(e.target.value)}
              onChange={(e) => onSelectDateCheckDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          {/* <TimeModel/> */}
          <Box
            style={{
              paddingTop: '1%',
              paddingBottom: '1%',
              paddingLeft: '3%',
              paddingRight: '3%',
            }}
          >
            {/* <TimeInput
              mode='24h'
              onChange={(time) => selectTime(time)}
              fullWidth
              ampm={false}
              format="hh:mm a"
            /> */}
            <form className={classes.container} noValidate>
              <TextField
                id="time"
                label="Report Time"
                type="time"
                defaultValue={getTimeZoneValue}
                key={getTimeZoneValue}
                // value={getTimeZoneValue}
                // className={classes.textField}
                onChange={(e) => selectTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </form>
          </Box>
          <Box
            style={{
              textAlign: 'Left',
              paddingBottom: '2%',
              paddingTop: '5%',
              paddingLeft: '3%',
              paddingRight: '3%',
            }}
          >
            <input type="file" label="Browse" accept="application/pdf" onChange={(e) => uploadFile(e)} />
          </Box>
          <Box
            style={{
              textAlign: 'Left',
              paddingBottom: '2%',
              paddingTop: '2%',
              paddingLeft: '3%',
              paddingRight: '3%',
            }}
          >
            <FormControl>
              <FormLabel id="sector-notification-label">Notify by email</FormLabel>
              <RadioGroup
                value={notificationType}
                name="sector-notification"
                aria-labelledby="sector-notification-label"
                onChange={(event) => setNotificationType(event.target.value)}
              >
                <FormControlLabel
                  value={REPORT_NOTIFICATION_TYPES.ALL_USERS}
                  control={<Radio />}
                  label="Send to all users"
                />
                <FormControlLabel
                  value={REPORT_NOTIFICATION_TYPES.SECTORS_AND_COUNTRIES}
                  control={<Radio />}
                  label="Send to specific sectors and countries"
                />
                <FormControlLabel
                  value={REPORT_NOTIFICATION_TYPES.DISABLE}
                  control={<Radio />}
                  label="Disable email notification"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <EmailFields
            setSelectedSectors={setSelectedSectors}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            subject={subject}
            setSubject={setSubject}
            mailBody={mailBody}
            setMailBody={setMailBody}
            selectedSectors={selectedSectors}
            sectorsList={sectorsList}
            countryList={countryList}
            sectorName={sectorName}
            notificationType={notificationType}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={UploadSectorReord}
            className=" btn btn-dark btn-primary_submit submitButton shadow-none"
            color="primary"
            autoFocus
          >
            <span className="MuiButton-label">Submit</span>
          </Button>
          <Button
            className="btn btn-dark btn-primary_submit cancelButton shadow-none"
            style={{
              lineHeight: 'normal',
              color: '#21309d',
              fontWeight: '600',
              outlineStyle: 'none !important',
              backgroundColor: '#ffffff !important',
              fontFamily: 'Quicksand',
              borderRadius: '18px',
              borderColor: '#21309d',
              border: '2px solid #21309d',
            }}
            onClick={handleClose}
            color="secondary"
          >
            <span className="MuiButton-label">Cancel</span>
          </Button>
        </DialogActions>
        <Loader setOpen={setOpen} open={open} />
      </Dialog>
    </div>
  );
}
