/* eslint-disable react/no-unescaped-entities */
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/function-component-definition
export default function CustomizedDialogs({ open, setOpen, isCIP }) {
  // const [Open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        {isCIP === true && (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000', fontFamily: 'Calibri' }}>
                {' '}
                Conflicts Of Interest Policy
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  By becoming an Iron Blue Financials Ltd (“Iron Blue” or the “Company”) client you acknowledge that we
                  and our officers and employees may provide services to other parties. It is therefore possible that
                  any of them may, in the course of business, have potential conflicts of interest with you.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Equal Access To Research And Analysts
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Our research will be published on our website (www.ironblue.co.uk) and be equally accessible for all
                  Iron Blue clients initially and on an ongoing basis. If we decide to remove the research, it will be
                  removed for all clients.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  An email will be sent simultaneously to all clients who have subscribed for that country/sector (in
                  their original sign-up preferences) flagging that relevant new research has been published on our
                  website. Other than ensuring simultaneous sending of this email, we carry no liability for the speed
                  of its ultimate delivery to the client’s email inbox or that it is delivered at all.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Iron Blue analysts will not discuss any company or industry research with clients or anyone outside of
                  Iron Blue prior to publication of that research. An exception to this could be if Iron Blue’s
                  regulators demand access to our research in advance of publication.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Iron Blue analysts will generally be available to discuss newly published research with subscribing
                  clients and will be mindful of treating clients equally and in a timely fashion. In any individual
                  situation though there may be diary constraints or a surfeit of demand for their time such that it may
                  not be possible for clients to receive immediate access to the analyst(s).
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Independence
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Although subscribing clients are welcome to suggest companies or industries for possible new coverage,
                  the decision to cover and score new stocks is entirely at the discretion of Iron Blue and its
                  management.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Iron Blue encourages client feedback on its research and scoring but, notwithstanding this, is
                  committed to retaining full unbiased independence.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Personal Account Trading
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Iron Blue promises to avoid investments in securities related to covered companies. However, its
                  officers and employees might invest in these securities. All positions in covered securities held by
                  Iron Blue’s officers and employees will be disclosed on our website. All officers and employees will
                  need to obtain senior management approval prior to dealing in securities connected with covered
                  companies.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Company Relationships
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Iron Blue will not sell or offer to sell any goods or services to companies under coverage. Iron Blue
                  is purely an independent research provider and offers neither securities trading nor investment
                  banking services.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Iron Blue officers and employees will not accept any gifts or entertainment from companies under
                  coverage.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontSize: '1.3rem',
                    fontFamily: 'Calibri',
                    fontWeight: 500,
                  }}
                >
                  Outside Interests
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Any officer or employee of Iron Blue must gain approval from senior management prior to engaging in
                  outside business activities. Iron Blue will be mindful of its potential conflicts of interest when
                  considering whether to approve these requests.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  This conflicts of interest policy was created on 16 July 2021 and last updated on 16 July 2021.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontSize: '1.3rem',
                    fontFamily: 'Calibri',
                    fontWeight: 500,
                  }}
                >
                  Retention Period
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Personal and website activity data will be held no longer than necessary.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Change To Our Policy
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Iron Blue reserves the right to update this policy at any time and the updated version will be
                  available on our website.
                </span>{' '}
              </Typography>
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleClose} color="primary">
             Save changes
           </Button> */}
            </DialogActions>
          </>
        )}
        {isCIP === false && (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} open={open}>
              <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000', fontFamily: 'Calibri' }}>
                Our Approach
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Iron Blue offers investors independent analysis and balanced scoring of listed companies according to
                  the disclosures contained in their annual reports. We focus our attention on annual reports because
                  these are typically the most detailed and regulated available stores of information. Whilst their
                  front pages can read as if written by the company’s marketing department, they also contain a fuller
                  set of accounts than published in preliminary results perhaps a month or two earlier, reports from the
                  auditor, various board committees and management’s principle risks assessment. All of this information
                  can quality check previously reported profits and balance sheets. This allows investors to assess the
                  conservatism and transparency of a company's accounting, governance and disclosures relative to peers
                  and history.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Iron Blue’s Core Beliefs
                </Typography>
                <Typography gutterBottom>
                  {' '}
                  <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                    1. Our experience is that companies with less conservative accounting, conflicted governance and
                    opaque disclosure are more prone to profit warnings.
                  </span>
                </Typography>
                <Typography gutterBottom>
                  {' '}
                  <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                    2. If investors can avoid more profit warnings than their peers, they stand a good chance of
                    outperforming.
                  </span>
                </Typography>
                <Typography gutterBottom>
                  {' '}
                  <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                    3. We believe that management teams coping with operational and financial pressure often hope for
                    the best and present their earnings and balance sheet in the best possible light. This is not always
                    helpful for investors.
                  </span>
                </Typography>
                <Typography gutterBottom>
                  {' '}
                  <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                    4. In this scenario, a company’s true predicament is not usually revealed by a “smoking gun” but
                    rather the meticulous assembly of many small “pieces of the jigsaw”.
                  </span>
                </Typography>
                <Typography gutterBottom>
                  {' '}
                  <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                    5. We advise investors to be sceptical of companies that we score highly. It is usually impossible
                    to ascertain with precision the extent of the earnings or net debt gap versus a conservative
                    reality. The ultimate reset can be greater than even we anticipate, and it often takes longer than
                    most expect for the company and share price subsequently to recover.
                  </span>
                </Typography>
                <Typography gutterBottom>
                  {' '}
                  <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                    6. Whilst locating fraud is always possible, our principal focus is highlighting run of the mill
                    profit warning risk.
                  </span>
                </Typography>
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  How To Use Our Research
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Long-only investor clients might use our lower scoring companies as a screen for new investments and
                  revisit any holdings that score highly. For those investors who also short stocks, our higher scoring
                  corporates could offer a helpful starting point. A high or low absolute (or relative to peer) score is
                  interesting but the direction of travel (yoy change) can also offer insight. We advise investors to
                  supplement our analysis and scoring with their own investment decision-making tools. We do not, for
                  example, carry out fundamental research into the companies and industries or perform equity
                  valuations.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  Each Iron Blue company report also includes a list of questions for management that can be used by
                  investors during company meetings.
                </span>
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Our Scoring System
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Iron Blue attributes each company under coverage an overall rating out of 60, which is the aggregation
                  of four scores: Accounting (40), Governance (10), Disclosures (5) and Risks (5). Accounting and
                  Governance are also sub-divided into component scores. A company’s approach to discretionary areas of
                  accounting is our largest focus and we analyse revenue and cost recognition approaches and net debt
                  calculations. Some companies choose to recognise revenue early and/or costs late (or stripped out
                  below the line) and some squeeze net debt at period ends or exclude items from this calculation. We
                  consider a few specific areas where our experience suggests there is notable scope for accounting
                  discretion: defined benefit pensions, JVs & associates and acquisitions. We also evaluate the use of
                  non-GAAP measures. Corporate governance standards vary widely. We score companies on their audit
                  process (eg auditor tenure, non-audit fees, subsidiary accounting, FRC letters), their management
                  structure (eg board and committee composition, related party transactions, different classes of shares
                  and change of control restrictions) and remuneration approach. We also consider the level of
                  disclosure provided by each company (eg segmental split, acquisition fair value adjustments,
                  receivables factoring, disaggregation of one-off costs, etc) and auditor and management risk
                  commentaries.
                </span>{' '}
              </Typography>
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button> */}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
