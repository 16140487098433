import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import AxiosInstance from '../api/axios';
import * as utils from '../utils/index';
import { TOAST_OPTIONS } from '../utils/toast';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    //   marginLeft:"10%",
    //   marginTop:"5%",
    //   marginRight:"10%"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  fblink: PropTypes.string,
  twitterlink: PropTypes.string,
  instagramlink: PropTypes.string,
  linkedinlink: PropTypes.string,
  contactussData: PropTypes.array,
};

const defaultProps = {
  sectionTitle: 'Contact',
  sectionTitleSpan: 'Us',
  sectionContent:
    'Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
  title: 'Get in Touch',
  content:
    'If you would like to contact us, please complete this form. Alternatively you can email us directly at research@ironblue.co.uk.',
  // fblink: "#",
  twitterlink: '#',
  // instagramlink: "#",
  linkedinlink: '#',
  contactussData: [
    {
      boxicon: 'icofont-mail',
      boxtitle: 'Email',
      boxitem1link: 'mailto:research@ironblue.co.uk',
      boxitem1: 'research@ironblue.co.uk',
      // boxitem2link: "#",
      // boxitem2: "support@exolot.com",
      fontSize: '16px',
      paddingBottom: '1%',
    },
    {
      boxicon: 'icofont-linkedin',
      boxtitle: 'LinkedIn',
      boxitem1link: 'https://www.linkedin.com/company/iron-blue-financials-ltd',
      boxitem1: 'Iron Blue Financials',
      boxitem2link: 'https://www.linkedin.com/company/iron-blue-financials-ltd',
      // boxitem2: "Stockholm, Sweden",
      fontSize: '12px',
      paddingBottom: '0%',
    },
    // {
    //     boxicon: "icofont-phone",
    //     boxtitle: "Phone",
    //     boxitem1link: "#",
    //     boxitem1: "+44 4587 458",
    //     boxitem2link: "#",
    //     boxitem2: "+44 4587 458",

    // }
  ],
};
const notificationMessage = async (message, statusValue) => {
  if (statusValue === 200) {
    await toast.success(message, TOAST_OPTIONS);
  } else {
    toast.error(message, TOAST_OPTIONS);
  }
};

const errorNotification = (message, statusValue) => {
  const status = 500;
  const displayMessage =
    message && message.response && message.response.data && message.response.data.message
      ? message.response.data.message
      : 'Something Went Wrong';
  const code =
    message && message.response && message.response.status && message.response.status ? message.response.status : 500;

  if (displayMessage === 'undefined') {
    toast.error('Something went wrong', TOAST_OPTIONS);
  } else if (code === 406) {
    toast.info(displayMessage, TOAST_OPTIONS);
  } else {
    toast.error(displayMessage, TOAST_OPTIONS);
  }
};

// eslint-disable-next-line react/function-component-definition
export default function UserContactus() {
  const classes = useStyles();
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Company, setcompany] = useState('');
  const [Message, setMessage] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    const bodySubmitForm = {
      name: Name,
      email: Email,
      company: Company,
      message: Message,
    };

    AxiosInstance.post('/getIntouch', bodySubmitForm)
      .then(function (response) {
        const code = response.status;
        const { message } = response.data;
        setName('');
        setcompany('');
        setEmail('');
        setMessage('');
        notificationMessage(message, code);
      })
      .catch(function (error) {
        errorNotification(error, 500);
      });
  };
  // Start ContactUs Loop

  const contactusdata = defaultProps.contactussData.map((contactus, index) => (
    // <div className="col-lg-5 mb-4 boxContact"  key={index}>
    //     <div className="contact-box" style={{boxShadow:"none"}}>
    //         <div className="icon">
    //             <i className={contactus.boxicon} />
    //         </div>
    //         <h4>{contactus.boxtitle}</h4>
    //         <p style={{paddingRight:"13%"}}><a href={contactus.boxitem1link}>{contactus.boxitem1}</a></p>
    //         <p><a href={contactus.boxitem2link}>{contactus.boxitem2}</a></p>
    //     </div>
    // </div>
    <div className="col-lg-5 mb-4 boxContact" key={index}>
      <a
        href={contactus.boxitem1link}
        target="_blank"
        className="contact-box"
        style={{ backgroundColor: '#eeeeee' }}
        rel="noreferrer"
      >
        <div className="icon">
          <i className={contactus.boxicon} />
        </div>
        <h4 style={{ color: '#000000' }}>{contactus.boxtitle}</h4>
        <p style={{ marginBottom: '15px' }}>{contactus.boxitem1}</p>
      </a>
    </div>
  ));
  // End ContactUs Loop

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const userData = utils.decodeUserDetails();
    if (userData !== null) {
      setName(userData.name);
      setEmail(userData.email);
      setcompany(userData.user_company);
    }
  });

  return (
    <>
      {/* <div className="work-with-us ptb-100" style ={{backgroundColor:"#21309D"}}> */}
      {/* <section id="contact" className="contact-area ptb-100"> */}
      <section id="contact" className="contact-area">
        {/* <div class ="work-with-us ptb-100"> */}
        <div className="container">
          <div className="section-title work-with-us">
            <div style={{ paddingBottom: '3%', paddingTop: '2%', color: '#ffffff' }}>
              <h1>
                <b>
                  {defaultProps.sectionTitle} {defaultProps.sectionTitleSpan}
                </b>
              </h1>
              {/* <p>{this.props.sectionContent}</p> */}
            </div>
            <div className="row justify-content-center" style={{ paddingBottom: '3%' }}>
              {contactusdata}
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-12 col-sm-12" style={{ backgroundColor: '#ffffff' }}>
              <div className="get-in-touch ">
                <h3>{defaultProps.title}</h3>
                <p>{defaultProps.content}</p>
                {/* <ul>
                                    <li>
                                        <a href={this.props.fblink} className="icofont-facebook"></a>
                                    </li>
                                    <li>
                                        <a href={this.props.twitterlink} className="icofont-twitter"></a>
                                    </li>
                                    <li>
                                        <a href={this.props.instagramlink} className="icofont-instagram"></a>
                                    </li>
                                    <li>
                                        <a href={this.props.linkedinlink} className="icofont-linkedin"></a>
                                    </li>
                                </ul> */}
              </div>
            </div>

            <div className="col-lg-8 col-md-12 col-sm-12">
              <ValidationForm id="contactForm" onSubmit={() => {}}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="mb-3">
                      <TextInput
                        name="name"
                        id="name"
                        value={Name}
                        required
                        successMessage=""
                        errorMessage="Please enter your name"
                        className="form-control"
                        placeholder="Name"
                        autoComplete="off"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="mb-3">
                      <TextInput
                        name="Company"
                        id="Company"
                        value={Company}
                        type="text"
                        required
                        successMessage=""
                        errorMessage="Please enter your company name"
                        className="form-control"
                        placeholder="Company"
                        autoComplete="off"
                        onChange={(e) => setcompany(e.target.value)}
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="mb-3">
                      <TextInput
                        name="email"
                        id="email"
                        type="email"
                        value={Email}
                        required
                        successMessage=""
                        errorMessage="Please enter your email address"
                        className="form-control"
                        placeholder="Email"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <TextInput
                        name="message"
                        id="description"
                        value={Message}
                        multiline
                        placeholder="Your message"
                        className="form-control"
                        required
                        successMessage=""
                        errorMessage="Please write your message"
                        rows="5"
                        autoComplete="off"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12" style={{ paddingBottom: '2%' }}>
                    {/* <button type="submit" className="btn btn-primary">Send Message</button> */}
                    <button type="submit" onClick={submitForm} className="btn btn-primary_submit shadow-none">
                      Send Message
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden" />
                    <div className="clearfix" />
                  </div>
                </div>
              </ValidationForm>
              {/*                             
                            {this.state.successMsg !== "" ? (
                                <h3 className="contactMsg">
                                    {this.state.successMsg}
                                </h3>
                            ) : null} */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
      {/* </div> */}
    </>
  );
}
