import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IoIosFunnel } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function SimpleSelect(props) {
  const classes = useStyles();

  const { data, style } = props;

  const handleChange = (event) => {
    // setAge(event.target.value);
    props.onChangeFilter(event.target.value);
  };

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div align="right" style={style}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          <IoIosFunnel />
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={data}
          onChange={handleChange}
          label="Age"
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value={30}>30 Days</MenuItem>
          <MenuItem value={90}>90 Days</MenuItem>
          <MenuItem value={365}>1 Year</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
