import { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableHead, Box } from '@mui/material';
import PropTypes from 'prop-types';

import AxiosInstance from '../../api/axios';
import { tableRowMode, tableRowType } from '../../utils/constants';
import TSRTableHeadRow from './TSRTableHeadRow';
import EditableTableRow from './EditableTableRow';

const TSRTable = (props) => {
  const { tableId, heading, mode, classes } = props;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await AxiosInstance.get(`tables/${tableId}/rows`);
        setRows(response.data.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error:', error);
      }
    };

    loadData();
  }, [tableId]);

  const createNewRow = async () => {
    try {
      const response = await AxiosInstance.post(`admin/tables/${tableId}/rows`, {
        title: '',
        lqs: '',
        hqs: '',
        alpha: '',
      });

      const newRow = response.data.data;
      newRow.mode = tableRowMode.EDIT;
      setRows([...rows, newRow]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error:', error);
    }
  };

  const handleRowDelete = (rowId) => {
    setRows(rows.filter((row) => row.id !== rowId));
  };

  return (
    <>
      <Box className={classes.tableHeader}>
        <Typography variant="h6" className={classes.subheading}>
          {heading}
        </Typography>
        {mode === 'edit' && (
          <button
            type="button"
            onClick={createNewRow}
            style={{ backgroundColor: '#21309D', float: 'right' }}
            className="btn btn-dark btn-primary_submit shadow-none"
          >
            <span className="MuiButton-label">Add Record</span>
          </button>
        )}
      </Box>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            {rows
              .filter((row) => row.row_type === tableRowType.HEAD)
              .map((row) => (
                <TSRTableHeadRow key={row.id} row={row} mode={mode} />
              ))}
          </TableHead>
          <TableBody>
            {rows
              .filter((row) => row.row_type === tableRowType.BODY && !row.isDeleted)
              .map((row) => (
                <EditableTableRow key={row.id} row={row} onDelete={handleRowDelete} isEditable={mode === 'edit'} />
              ))}
            {rows
              .filter((row) => row.row_type === tableRowType.FOOTER)
              .map((row) => (
                <EditableTableRow key={row.id} row={row} onDelete={handleRowDelete} isEditable={mode === 'edit'} />
              ))}
          </TableBody>
        </Table>

        {!rows.filter((row) => !row.isDeleted).length && (
          <Typography className={classes.noRecordsMessage}>No Data Found</Typography>
        )}
      </div>
    </>
  );
};

TSRTable.propTypes = {
  tableId: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    table: PropTypes.string,
    tableHeader: PropTypes.string,
    subheading: PropTypes.string,
    tableWrapper: PropTypes.string,
    noRecordsMessage: PropTypes.string,
  }).isRequired,
};

export default TSRTable;
