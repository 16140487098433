import { useState, useEffect } from 'react';
import { Grid, TextField, Typography, Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import PublishIcon from '@mui/icons-material/Publish';
import { toast } from 'react-toastify';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import EmailFields from './emailData';
import AxiosInstance from '../../api/axios';
import EditRecrodTable from './EditRecord';
import Loader from './BackdropLoader';
import useForm from './UseForm';
import { TOAST_OPTIONS } from '../../utils/toast';

const momentTime = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '3%',
      // width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '95%',
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, sectorName, theme) {
  return {
    fontWeight:
      sectorName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

// eslint-disable-next-line react/function-component-definition
export default function AddSectorForm(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { addOrEdit, editRecord, loadSector, openPopup, setOpenPopUp, setOpen, open } = props;
  const [sectorName, setSectorName] = useState('');
  const [latestScore, setLatestScore] = useState(null);
  const [previousScore, setPreviousScore] = useState(null);
  const [changeYoy, setChangeYoy] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailToAll, setEmailToAll] = useState(0);
  const [reportLength, setReportLength] = useState(0);
  const [subject, setSubject] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [sectorsList, setSectorList] = useState([{}]);
  const [EditNewReportselectedDate, setEditNewReportSelectedDate] = useState(null);
  const [reportArray, setReportArray] = useState();
  const [submitButtonName, setSubmitButtonName] = useState('Submit');

  // const [value, setValue] = useState(null);
  const btn = { width: '50%', backgroundColor: '#21309D' };
  const btnSubmit = { width: '80%', float: 'left', backgroundColor: '#21309D' };
  let dateFormat = '';
  if (selectedDate !== null) {
    dateFormat = moment(selectedDate).format('YYYY-MM-DD');
  }
  const initialFValues = {
    name: '',
    latest_score: '',
    previous_score: '',
    change_yoy: '',
  };

  const handleChange = (event) => {
    setSelectedSectors(event.target.value);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true);

  useEffect(() => {
    if (editRecord != null) {
      setValues({
        ...editRecord,
      });
      setSectorName(editRecord.name);
      setLatestScore(editRecord.latest_score);
      setPreviousScore(editRecord.previous_score);
      setChangeYoy(editRecord.change_yoy);
      setSelectedDate(editRecord.date_change);
      const recordLength = editRecord.reports.length;
      setReportLength(recordLength);
      setReportArray(editRecord.reports);
      setSubmitButtonName('Update');
    }
    setSectorList(props.sectorsData);
    const timezones = momentTime.tz.names();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRecord]);

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code =
      message && message.response && message.response.status && message.response.status ? message.response.status : 500;

    if (displayMessage === 'undefined') {
      toast.error('Something went wrong', TOAST_OPTIONS);
    } else if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  const uploadFile = (e) => {
    const fileValue = e.target.files;
    const render = new FileReader();
    render.readAsDataURL(fileValue[0]);
    const newFileName = fileValue[0].name;
    setFileName(newFileName);
    render.onload = (event) => {
      const fileData = event.target.result;
      const finalFile = fileData.split(/[,]/);
      setFile(finalFile[1]);
    };
  };
  const handleChangeEmailToAll = (event) => {
    setCheckEmail(!checkEmail);
    if (checkEmail === true) {
      setEmailToAll(1);
    }
  };

  const submitSectorReord = (e) => {
    e.preventDefault();
    setOpen(true);

    if (editRecord != null) {
      const sectorId = editRecord.id;
      AxiosInstance.put(
        `sector/${sectorId}`,
        {
          name: sectorName,
          latest_score: latestScore,
          previous_score: previousScore,
          change_yoy: changeYoy,
          email_to_all: emailToAll,
          date_change: dateFormat,
          fileType: 'application/pdf',
          fileName,
          file,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setSectorName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setSelectedDate(null);
          loadSector();
          setOpen(false);
          setOpenPopUp(false);
        })
        .catch(function (error) {
          errorNotification(error, 500);
        });
    } else {
      AxiosInstance.post(
        'sector',
        {
          name: sectorName,
          latest_score: latestScore,
          previous_score: previousScore,
          change_yoy: changeYoy,
          email_to_all: emailToAll,
          date_change: dateFormat,
          fileType: 'application/pdf',
          fileName: String(fileName),
          file,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setSectorName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setSelectedDate(null);
          loadSector();
          setOpen(false);
          setOpenPopUp(false);
        })
        .catch(function (error) {
          errorNotification(error, 500);
          setOpen(false);
        });
    }
  };

  const submitSectorWithNewRecord = (e) => {
    e.preventDefault();
    setOpen(true);

    if (editRecord != null) {
      const sectorId = editRecord.id;
      AxiosInstance.put(
        `sector/${sectorId}`,
        {
          name: sectorName,
          latest_score: latestScore,
          previous_score: previousScore,
          change_yoy: changeYoy,
          // email_to_all: emailToAll,
          // date_change: dateFormat,
          // fileType: "application/pdf",
          // fileName: fileName,
          // file: file
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          const newSectorId = editRecord.id;
          // if (EditNewReportselectedDate !== null) {
          if (EditNewReportselectedDate !== null) {
            dateFormat = moment(EditNewReportselectedDate).format('YYYY-MM-DD');
            AxiosInstance.post(
              'sector/report',
              {
                sectorId: newSectorId,
                email_to_all: emailToAll,
                date_change: dateFormat,
                fileType: 'application/pdf',
                fileName,
                file,
                name: sectorName,
              },
              {
                headers: { Accept: 'application/json' },
              },
            )
              .then(function () {
                setOpen(false);
              })
              .catch(function (error) {
                errorNotification(error, 500);
              });
          }
          notificationMessage(message, code);
          // setOpenPopUp(false);
          setSectorName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setSelectedDate(null);
          loadSector();
          setOpen(false);
          setOpenPopUp(false);
          // loadCompany();
          setEditNewReportSelectedDate(null);
          setOpen(false);
        })
        .catch(function (error) {
          errorNotification(error, 500);
        });
    } else {
      AxiosInstance.post(
        'sector',
        {
          name: sectorName,
          latest_score: latestScore,
          previous_score: previousScore,
          change_yoy: changeYoy,
          email_to_all: emailToAll,
          date_change: dateFormat,
          fileType: 'application/pdf',
          fileName: String(fileName),
          file,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setSectorName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setSelectedDate(null);
          loadSector();
          setOpen(false);
          setOpenPopUp(false);
        })
        .catch(function (error) {
          errorNotification(error, 500);
        });
    }
  };

  const renderButtons = () => {
    return (
      <>
        {/* {editRecord === null &&
                    <Button type="submit" onClick={submitSectorWithNewRecord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>{submitButtonName}</Button>
                    }
                    {editRecord !== null &&
                    <Button type="submit" onClick={submitSectorWithNewRecord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>Update</Button>
            } */}
        {editRecord === null && (
          <button
            type="submit"
            onClick={submitSectorReord}
            style={btnSubmit}
            className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
          >
            Submit
          </button>
        )}

        {editRecord !== null && reportLength !== 0 && (
          <button
            type="submit"
            onClick={submitSectorReord}
            style={btnSubmit}
            className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
          >
            Update
          </button>
        )}

        {editRecord !== null && reportLength === 0 && (
          //   <Button type="submit" onClick={submitSectorWithNewRecord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>submit...</Button>
          <button
            type="submit"
            onClick={submitSectorWithNewRecord}
            style={btnSubmit}
            className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
          >
            Update
          </button>
        )}
      </>
    );
  };

  return (
    <>
      {editRecord === null && (
        <form className={classes.root} noValidate autoComplete="off">
          <Loader setOpen={setOpen} open={open} />
          <Grid container>
            {/* <Grid item xs={8}> */}
            <TextField
              id="name"
              label="Enter Sector Name"
              value={sectorName}
              onChange={(e) => setSectorName(e.target.value)}
              fullWidth
              required
            />

            <TextField
              id="latest_score"
              label="Enter Latest Score"
              // variant="filled"
              type="number"
              value={latestScore}
              onChange={(e) => setLatestScore(e.target.value)}
              fullWidth
            />

            <TextField
              id="previous_score"
              label="Enter Previous Score"
              // variant="filled"
              value={previousScore}
              type="number"
              onChange={(e) => setPreviousScore(e.target.value)}
              fullWidth
            />

            <TextField
              id="change_yoy"
              label="Enter Change"
              // variant="filled"
              value={changeYoy}
              type="number"
              onChange={(e) => setChangeYoy(e.target.value)}
              fullWidth
              // onChange={handleInputChange}
            />
            <div />
          </Grid>
          <Grid item xs={12} className="mailDivStyle">
            {renderButtons()}
          </Grid>
          {/* </Grid> */}
        </form>
      )}

      {reportLength === 0 && editRecord != null && (
        <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: '400px' }}>
          <Loader setOpen={setOpen} open={open} />
          <Grid container>
            {/* <Grid item xs={8}> */}
            <TextField
              id="name"
              label="Enter Sector Name"
              value={sectorName}
              onChange={(e) => setSectorName(e.target.value)}
              fullWidth
              required
            />

            <TextField
              id="latest_score"
              label="Enter Latest Score"
              // variant="filled"
              type="number"
              value={latestScore}
              onChange={(e) => setLatestScore(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="previous_score"
              label="Enter Previous Score"
              // variant="filled"
              value={previousScore}
              type="number"
              onChange={(e) => setPreviousScore(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              id="change_yoy"
              label="Enter Change"
              // variant="filled"
              value={changeYoy}
              type="number"
              onChange={(e) => setChangeYoy(e.target.value)}
              fullWidth
              // onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
            />
            <div />
          </Grid>
          <Grid item xs={12} className="mailDivStyle">
            {renderButtons()}
          </Grid>
          {/* </Grid> */}
        </form>
      )}

      <Box className="mobile-only">
        {editRecord != null && reportLength !== 0 && (
          <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: '400px' }}>
            <Loader setOpen={setOpen} open={open} />
            <Grid container>
              {/* <Grid item xs={8}> */}
              <TextField
                id="name"
                label="Enter Sector Name"
                value={sectorName}
                onChange={(e) => setSectorName(e.target.value)}
                fullWidth
                required
              />

              <TextField
                id="latest_score"
                label="Enter Latest Score"
                // variant="filled"
                type="number"
                value={latestScore}
                onChange={(e) => setLatestScore(e.target.value)}
                fullWidth
              />

              <TextField
                id="previous_score"
                label="Enter Previous Score"
                // variant="filled"
                value={previousScore}
                type="number"
                onChange={(e) => setPreviousScore(e.target.value)}
                fullWidth
              />

              <TextField
                id="change_yoy"
                label="Enter Change"
                // variant="filled"
                value={changeYoy}
                type="number"
                onChange={(e) => setChangeYoy(e.target.value)}
                fullWidth
                // onChange={handleInputChange}
              />
              <div />
            </Grid>
            <Grid item xs={12} className="mailDivStyle">
              {renderButtons()}
            </Grid>
            {/* </Grid> */}
            {editRecord !== null && (
              <EditRecrodTable
                editRecord={editRecord}
                reportArray={reportArray}
                setReportArray={setReportArray}
                reportLength={reportLength}
                isFromCompany="false"
                loadSector={loadSector}
              />
            )}
          </form>
        )}
      </Box>

      <Box className="desktop-only">
        {editRecord != null && reportLength !== 0 && (
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            style={{ paddingLeft: '3%', paddingRight: '3%' }}
          >
            <Loader setOpen={setOpen} open={open} />
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="name"
                  label="Enter Sector Name"
                  value={sectorName}
                  onChange={(e) => setSectorName(e.target.value)}
                  required
                />

                <TextField
                  id="latest_score"
                  label="Enter Latest Score"
                  defaultValue="0"
                  // variant="filled"
                  type="number"
                  value={latestScore}
                  onChange={(e) => setLatestScore(e.target.value)}
                />

                <TextField
                  id="previous_score"
                  label="Enter Previous Score"
                  defaultValue="0"
                  // variant="filled"
                  value={previousScore}
                  type="number"
                  onChange={(e) => setPreviousScore(e.target.value)}
                />

                <TextField
                  id="change_yoy"
                  label="Enter Change"
                  defaultValue="0"
                  // variant="filled"
                  value={changeYoy}
                  type="number"
                  onChange={(e) => setChangeYoy(e.target.value)}
                  // onChange={handleInputChange}
                />
                {/* {editRecord === null &&
                    <TextField
                            id="date"
                            label="Report Date"
                            type="date"
                            defaultValue={selectedDate}
                            className={classes.textField}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                      } */}

                {/* {editRecord === null &&
                    <Button type="submit" onClick={submitSectorReord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>{submitButtonName}</Button>
                    }
                    {editRecord !== null &&
                    <Button type="submit" onClick={submitSectorWithNewRecord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>Update</Button>
                    } */}

                {/* <Box style={{ paddingTop: "4%" }}>
                        {editRecord === null &&
                            <Button type="submit" onClick={submitSectorReord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>Submit</Button>
                        }

                        {editRecord !== null && reportLength !== 0 &&
                            <Button type="submit" onClick={submitSectorReord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>Update</Button>
                        }

                        {editRecord !== null && reportLength === 0 &&
                            //   <Button type="submit" onClick={submitSectorWithNewRecord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>submit...</Button>
                            <Button type="submit" onClick={submitSectorWithNewRecord} style={btnSubmit} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>Update</Button>
                        }
                    </Box> */}

                {!checkEmail ? renderButtons() : null}
              </Grid>
              <Grid item xs={6}>
                {/* <Card className="container boxContact" > */}
                {editRecord === null && (
                  <div className="container boxContact">
                    <div
                      style={{
                        width: '2em',
                        height: '2em',
                        paddingLeft: '40%',
                        paddingBottom: '25%',
                        paddingTop: '20%',
                      }}
                    >
                      <PublishIcon className="desktop-only" style={{ width: '2em', height: '2em' }} />
                      <PublishIcon className="mobile-only" style={{ width: '1em', height: '1em' }} />
                    </div>
                    <Typography variant="h5" style={{ textAlign: 'center', paddingBottom: '6%' }} component="h2">
                      Upload Reports
                    </Typography>
                    <Box style={{ padding: '8%', textAlign: 'center' }}>
                      <TextField
                        id="date"
                        label="Report Date"
                        type="date"
                        defaultValue={selectedDate}
                        className={classes.textField}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>

                    <Box style={{ padding: '8%', textAlign: 'center', width: '98%' }}>
                      <input
                        type="file"
                        label="Browse"
                        // eslint-disable-next-line react/no-unknown-property
                        align="center"
                        style={{ width: '98%' }}
                        onChange={(e) => uploadFile(e)}
                      />
                      {/* <div className="custom-file ">
                        <input type="file" className="custom-file-input"  onChange= {(e)=>uploadFile(e)}  id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01"/>
                        <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                      </div> */}
                      {/* <Button type="submit" onClick={submitSectorReord} style={btn} className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4" fullWidth>Browse</Button> */}
                    </Box>

                    <FormControlLabel
                      style={{ paddingLeft: '8%' }}
                      control={
                        <Checkbox
                          checked={checkEmail}
                          onChange={handleChangeEmailToAll}
                          name="checkedB"
                          align="center"
                          color="primary"
                        />
                      }
                      label="Send email"
                    />
                  </div>
                )}

                {editRecord !== null && (
                  <EditRecrodTable
                    editRecord={editRecord}
                    reportLength={reportLength}
                    reportArray={reportArray}
                    setReportArray={setReportArray}
                    isFromCompany="false"
                    loadSector={loadSector}
                  />
                )}

                {/* </Card> */}
                <div />
              </Grid>
            </Grid>
            {checkEmail ? <EmailFields /> : null}
          </form>
        )}
      </Box>
    </>
  );
}
