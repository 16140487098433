import { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Box,
  Typography,
  Button,
  Card,
  Paper,
  TableBody,
  TableRow,
  TableCell as MuiTableCell,
  Tooltip,
  NativeSelect,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublishIcon from '@mui/icons-material/Publish';
import _ from 'lodash';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../../api/axios';
import EmptyComponent from './emptyComponent';
import * as utils from '../../utils/index';
import Emitter from '../../login-event-emitter';
import CompUploadReportDialog from './CompanyUploadReport';
import CompanyConfirmation from './CompanyConfirm';
import CompPopup from './PopUpCompany';
import SectorTable from './AdminTable';
import Header from './header';
import Loader from './BackdropLoader';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    overflow: 'hidden !important',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  },

  searchInput: {
    // width: '75%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  alignInRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  reportLink: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationStyle: 'solid',
    whiteSpace: 'nowrap',
  },
  row: {
    backgroundColor: 'var(--row-background-color)',
    '& td': {
      backgroundColor: 'var(--row-background-color)',
    },
  },
}));

const headCells = [
  { id: 'name', label: 'Company' },
  { id: 'latest_score', label: 'Latest Score' },
  { id: 'latest_report_date', label: 'Latest Report', type: 'date' },
  { id: 'previous_score', label: 'Previous Score' },
  { id: 'change_yoy', label: 'Change' },
  { id: 'reports', label: 'All Reports', disableSorting: true },
  { id: 'ric_code', label: 'RIC Code' },
  { id: 'sector_id', label: 'Sector' },
  { id: 'country', label: 'Country' },
  { id: 'uploadReports', label: 'Upload Report', disableSorting: true },
  { id: 'manage', label: 'Manage', disableSorting: true },
];

// eslint-disable-next-line react/function-component-definition
export default function Sectors() {
  const [company, setCompany] = useState([]);
  // const [sectorData, setSectorData] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopUp] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [companyDeleteRecord, setCompanyDeleteRecord] = useState(null);
  const [openUploadReportModel, setUploadReportModel] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);
  const [upoadReportTitleName, setUploadReportTitleName] = useState('');
  const [uploadReportCompanyID, setUploadReportCompanyID] = useState(0);
  const [getSectorList, setGetsectorList] = useState([]);
  const [open, setOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [latestScore, setLatestScore] = useState(null);

  const [subject, setSubject] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [selectedSectors, setSelectedSectors] = useState([]);

  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [getTimeZoneValue, setgetTimeZoneValue] = useState('');

  const loadCompany = async () => {
    setOpen(true);
    AxiosInstance.get('company/?fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        setOpen(false);
        setCompany(finalRes);
      })
      .catch(function (error) {
        setOpen(false);
        //    setError(error)
      });
  };

  const loadCountry = async () => {
    AxiosInstance.get('user/country')
      .then(function (response) {
        const finalRes = response.data.data;
        setCountryList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const getSectorListApi = () => {
    AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        // return finalRes
        setGetsectorList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  useEffect(() => {
    loadCompany();
    getSectorListApi();
    loadCountry();
  }, []);
  const classes = useStyles();

  const {
    TablContainer,
    TblHead,
    // recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
    orderColumnType,
  } = SectorTable(company, headCells, filterFn);

  const TableCell = withStyles({
    root: {
      borderBottom: 'none',
    },
  })(MuiTableCell);

  const handleSearch = (e) => {
    const { target } = e;
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items;
        return items.filter((x) => x.name.toLowerCase().includes(target.value));
      },
    });
  };

  const openInPopUp = (item) => {
    setEditRecord(item);
    setOpenPopUp(true);
  };

  const openConfirmationModel = (item) => {
    setCompanyDeleteRecord(item);
    setOpenDeleteModel(true);
  };

  const uploadReportModel = (item) => {
    setUploadReport(item);
    const titleName = `Company: ${item.name}`;
    setUploadReportTitleName(titleName);
    setUploadReportCompanyID(item.id);
    setUploadReportModel(true);
    setLatestScore(item.latest_score);

    Emitter.on('upload-report-comp', (newValue) => setSubject(''));
    setSubject('');
    setMailBody('');
    setFile('');
    setCheckEmail(false);
    setFileName('');
    setSelectedSectors([]);
    setSelectedCountry([]);
    setSelectedDate(null);
    setSelectedDate(String(moment.tz('Etc/Universal').format('YYYY-MM-DD')));
    setgetTimeZoneValue(String(moment.tz('Etc/Universal').format('HH:mm')));
    return () => {
      Emitter.off(
        'upload-report',
        setMailBody(''),
        setSelectedSectors([]),
        setSubject(''),
        setSubject(''),
        setMailBody(''),
        setFile(''),
        setCheckEmail(false),
        setFileName(''),
        setSelectedSectors([]),
        setSelectedCountry([]),
        setSelectedDate(null),
      );
      setSelectedDate(String(moment.tz('Etc/Universal').format('YYYY-MM-DD')));
      setgetTimeZoneValue(String(moment.tz('Etc/Universal').format('HH:mm')));
    };
  };

  const handleChange = (event) => {
    // setreport(event.target.value);
    // myStyle={
    //   display:"none",
    // }
  };

  const handleReportLink = (e) => {
    if (e !== 'null') {
      const reportsArray = _.map(company, 'reports');
      const combineArray = _.uniq(_.flatten(reportsArray));
      const targetObject = _.find(combineArray, { url: e });
      if (targetObject.url === undefined) {
        targetObject.url = '';
      }
      const body = {
        fileUrl: targetObject.url,
        fileDate: targetObject.date_change,
        reportName: targetObject.reportName,
      };
      const url = `${GOOGLE_CLOUD_BASE_URL}company/watermark/${
        body.reportName
      }?token=${utils.getAccessToken()}&fileUrl=${body.fileUrl}`;

      const newWindow = window.open(url, '_blank');
      if (newWindow == null || typeof newWindow === 'undefined') {
        document.location.href = url;
        // alert(
        //   'Please disable your pop-up blocker and click the "Open" link again.'
        // );
      } else {
        newWindow.focus();
      }

      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      // document.location.href = url;
      // const newWindow = document.location.href;
      // if (newWindow) newWindow.opener = null;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const openAddSectorBtnPoopUp = () => {
    setOpenPopUp(true);
    setEditRecord(null);
  };

  return (
    <>
      <Helmet>
        <title>Companies - Iron Blue Financials</title>
      </Helmet>
      <Header />
      <Loader setOpen={setOpen} open={open} />
      {/* <Card className="cardClass">
                <div className="comp_btn">
                          <Typography variant="h4" > Companies
                       <Box className="comp_btn" style={{ float: "right" }}>
                            <Button type="button" style={{ float: "right", backgroundColor: "#21309D",width:"100%" }}
                                onClick={() => { openAddSectorBtnPoopUp() }}
                                className="btn btn-primary_submit shadow-none">Add Company</Button>
                        </Box>
                    </Typography>
                </div>
            </Card> */}

      <Card className="cardClass whiteSpaceNoWrap">
        <Typography variant="h4" className="cardTitle">
          Companies
        </Typography>
        <button
          type="button"
          style={{ float: 'right', backgroundColor: '#21309D' }}
          className="btn btn-dark btn-primary_submit shadow-none"
          onClick={openAddSectorBtnPoopUp}
        >
          <span className="MuiButton-label">Add Company</span>
        </button>
      </Card>

      {company && company.length ? (
        <Box textAlign="center">
          <Paper className="TableView" style={{ padding: '0px' }}>
            <TablContainer>
              <TblHead />
              <TableBody>
                {stableSort(company, getComparator(order, orderBy, orderColumnType)).map((item) => (
                  <TableRow
                    key={item.id}
                    className={classes.row}
                    style={{
                      '--row-background-color': item.color,
                    }}
                  >
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.latest_score}</TableCell>
                    <TableCell align="center">
                      <span
                        className={classes.reportLink}
                        onClick={() => {
                          const latestReport = item.reports.find(
                            (report) => report.date_change === item.latest_report_date,
                          );
                          if (latestReport) {
                            handleReportLink(latestReport.url);
                          }
                        }}
                        role="presentation"
                      >
                        {item.latest_report_date}
                      </span>
                    </TableCell>
                    <TableCell align="center">{item.previous_score}</TableCell>
                    <TableCell align="center">{item.change_yoy}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {/* <DropDown item={item}/> */}
                      {/* <NativeSelect
                                                // onClick={onChangeSectorId(element)}
                                                // onClick={getSectorList}
                                                onClick= {(e) =>handleReportLink(e.target.value)}
                                                // onChange={handleReportLink(this.value)}
                                                inputProps={{
                                                    name: 'Select Sector',
                                                    id: 'age-native-label-placeholder',
                                                }}
                                            >
                                                {item.reports.map(element =>
                                                    (
                                                        <option value={element.url}> {element.date_change}</option>
                                                    ))}
                                                {item.reports.length === 0 && <option value="null">None</option>}

                                            </NativeSelect> */}

                      {item.reports && item.reports.length ? (
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <option value="null">Select Report</option>
                          {item.reports.map((element, index) => (
                            // eslint-disable-next-line react/no-unknown-property
                            <option value={element.url} date={element.date_change} key={index}>
                              {' '}
                              {element.date_change}
                            </option>
                          ))}
                        </NativeSelect>
                      ) : (
                        // <span>None</span>
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <>
                            <option value="null">Select Report</option>
                            <option value="null"> None</option>
                          </>
                        </NativeSelect>
                      )}
                    </TableCell>
                    <TableCell align="center">{item.ric_code}</TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      {item.sectorName}
                    </TableCell>
                    <TableCell align="center">{item.country}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Upload Report" style={{ margin: '2%' }} arrow>
                        <PublishIcon
                          onClick={() => {
                            uploadReportModel(item);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <span className={classes.alignInRow}>
                        <Tooltip title="Edit" style={{ marginRight: '3%' }} arrow>
                          <EditIcon
                            onClick={() => {
                              openInPopUp(item);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Delete" style={{ marginLeft: '3%' }} arrow>
                          <DeleteOutlineIcon
                            onClick={() => {
                              openConfirmationModel(item);
                            }}
                          />
                        </Tooltip>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TablContainer>
          </Paper>
          {/* {error && <h5>{error}</h5>} */}
        </Box>
      ) : (
        !open && <EmptyComponent />
      )}

      <CompPopup
        openPopup={openPopup}
        setOpenPopUp={setOpenPopUp}
        loadCompany={loadCompany}
        editRecord={editRecord}
        getSectorList={getSectorList}
        setOpen={setOpen}
        open={open}
        countryList={countryList}
      />
      <CompanyConfirmation
        companyDeleteRecord={companyDeleteRecord}
        openDeleteModel={openDeleteModel}
        loadCompany={loadCompany}
        setOpenDeleteModel={setOpenDeleteModel}
        setOpen={setOpen}
        open={open}
      />
      {openUploadReportModel ? (
        <CompUploadReportDialog
          key={`uploadDialog_${uploadReportCompanyID}`}
          uploadReport={uploadReport}
          openUploadReportModel={openUploadReportModel}
          setUploadReportModel={setUploadReportModel}
          urlReq="company/report"
          upoadReportTitleName={upoadReportTitleName}
          uploadReportCompanyID={uploadReportCompanyID}
          loadCompany={loadCompany}
          setOpen={setOpen}
          open={open}
          setUploadReportTitleName={setUploadReportTitleName}
          setUploadReportCompanyID={setUploadReportCompanyID}
          subject={subject}
          setSubject={setSubject}
          latestScore={latestScore}
          setLatestScore={setLatestScore}
          mailBody={mailBody}
          setMailBody={setMailBody}
          selectedSectors={selectedSectors}
          setSelectedSectors={setSelectedSectors}
          file={file}
          setFile={setFile}
          fileName={fileName}
          setFileName={setFileName}
          checkEmail={checkEmail}
          setCheckEmail={setCheckEmail}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          setgetTimeZoneValue={setgetTimeZoneValue}
          getTimeZoneValue={getTimeZoneValue}
        />
      ) : null}
    </>
  );
}
