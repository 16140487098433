import { useState, useEffect } from 'react';
import { Grid, TextField, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PublishIcon from '@mui/icons-material/Publish';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import useForm from './UseForm';
import Loader from './BackdropLoader';
import EditRecrodTable from './EditRecord';
import AxiosInstance from '../../api/axios';
import { TOAST_OPTIONS } from '../../utils/toast';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '3%',
      width: '100%',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiBox-root MuiBox-root-21': {
      width: '100%',
    },
    '& .MuiGrid-container': {
      width: '100%',
      display: 'inline-grid',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
    },
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function AddCompnayForm(props) {
  const classes = useStyles();

  const { addOrEdit, editRecord, loadCompany, getSectorList, openPopup, setOpenPopUp, setOpen, open, countryList } =
    props;
  // const [companyName, setCompanyName] = useState("");
  const [companyName, setCompanyName] = useState('');
  const [latestScore, setLatestScore] = useState(null);
  const [previousScore, setPreviousScore] = useState(null);
  const [changeYoy, setChangeYoy] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailToAll, setEmailToAll] = useState(0);
  const [reportLength, setReportLength] = useState(0);
  const [ricCode, setRicCode] = useState('');
  const [sectorsId, setSectorId] = useState(getSectorList[0].id);
  const [countryId, setCountryId] = useState(countryList[0].id);
  const [countryName, setCountryName] = useState();
  const [SectorName, setSectorName] = useState('');
  const [EditNewReportselectedDate, setEditNewReportSelectedDate] = useState(null);
  const [reportArray, setReportArray] = useState();
  const btn = { width: '50%', backgroundColor: '#21309D' };
  const btnSubmit = { backgroundColor: '#21309D' };
  let dateFormat = '';
  if (selectedDate !== null) {
    dateFormat = moment(selectedDate).format('YYYY-MM-DD');
  }
  const initialFValues = {
    name: '',
    latest_score: '',
    previous_score: '',
    change_yoy: '',
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true);

  useEffect(() => {
    if (editRecord != null) {
      setValues({
        ...editRecord,
      });
      setCompanyName(editRecord.name);
      setLatestScore(editRecord.latest_score);
      setPreviousScore(editRecord.previous_score);
      setChangeYoy(editRecord.change_yoy);
      setRicCode(editRecord.ric_code);
      setSectorId(editRecord.sector_id);
      setSelectedDate(editRecord.date_change);
      setCountryName(editRecord.country);
      setSectorName(editRecord.sectorName);
      setReportArray(editRecord.reports);
      const recordLength = editRecord.reports.length;
      setReportLength(recordLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRecord]);

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code =
      message && message.response && message.response.status && message.response.status ? message.response.status : 500;

    if (displayMessage === 'undefined') {
      toast.error('Something went wrong', TOAST_OPTIONS);
    } else if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  const uploadFile = (e) => {
    const fileValue = e.target.files;
    const render = new FileReader();
    render.readAsDataURL(fileValue[0]);
    const newFileName = fileValue[0].name;
    setFileName(newFileName);
    render.onload = (event) => {
      const fileData = event.target.result;
      const finalFile = fileData.split(/[,]/);
      setFile(finalFile[1]);
    };
  };
  const handleChangeEmailToAll = (event) => {
    setCheckEmail(!checkEmail);
    if (checkEmail === true) {
      setEmailToAll(1);
    }
  };

  const submitSectorWithNewRecord = (e) => {
    e.preventDefault();
    setOpen(true);

    if (editRecord != null) {
      const sectorId = editRecord.id;
      AxiosInstance.put(
        `company/${sectorId}`,
        {
          name: companyName,
          latest_score: parseInt(latestScore, 10),
          previous_score: parseInt(previousScore, 10),
          change_yoy: parseInt(changeYoy, 10),
          email_to_all: emailToAll,
          ric_code: String(ricCode),
          sector_id: parseInt(sectorsId, 10),
          country: countryName,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          const newSectorId = editRecord.id;
          // if (EditNewReportselectedDate !== null) {
          if (EditNewReportselectedDate !== null) {
            dateFormat = moment(EditNewReportselectedDate).format('YYYY-MM-DD');
            AxiosInstance.post(
              'company/report',
              {
                companyId: newSectorId,
                latest_score: latestScore,
                previous_score: previousScore,
                change_yoy: changeYoy,
                email_to_all: emailToAll,
                date_change: dateFormat,
                fileType: 'application/pdf',
                fileName: String(fileName),
                file,
                ric_code: ricCode,
                country: countryName,
              },
              {
                headers: { Accept: 'application/json' },
              },
            )
              .then(function (res) {
                // var code = res.status;
                // var message = res.data.message;
                // notificationMessage(message, code);
                //   loadCompany();
                setOpen(false);
              })
              .catch(function (error) {
                errorNotification(error, 500);
              });
          }
          notificationMessage(message, code);
          // setOpenPopUp(false);
          setCompanyName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setRicCode('');
          setSelectedDate(null);
          loadCompany();
          setOpen(false);
          setOpenPopUp(false);
          // loadCompany();
          setEditNewReportSelectedDate(null);
          setOpen(false);
        })
        .catch(function (error) {
          errorNotification(error, 500);
        });
    } else {
      if (sectorsId === '' || sectorsId === null) {
        setSectorId(editRecord.sector_id);
      }
      if (countryName === '' || countryName === null) {
        setCountryName(editRecord.country);
      }
      const body = {
        name: companyName,
        latest_score: parseInt(latestScore, 10),
        previous_score: parseInt(previousScore, 10),
        change_yoy: parseInt(changeYoy, 10),
        email_to_all: emailToAll,
        ric_code: ricCode,
        sector_id: parseInt(sectorsId, 10),
        date_change: dateFormat,
        fileType: 'application/pdf',
        fileName: String(fileName),
        file,
        country: countryName,
      };
      AxiosInstance.post('company', body, {
        headers: { Accept: 'application/json' },
      })
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setCompanyName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setRicCode('');
          setSelectedDate(null);
          loadCompany();
          setOpen(false);
          setOpenPopUp(false);
        })
        .catch(function (error) {
          setOpen(false);
          setOpenPopUp(false);
          errorNotification(error, 500);
        });
    }
  };

  const submitSectorReord = (e) => {
    e.preventDefault();
    setOpen(true);
    if (editRecord != null) {
      const companyId = editRecord.id;
      AxiosInstance.put(
        `company/${companyId}`,
        {
          name: companyName,
          latest_score: parseInt(latestScore, 10),
          previous_score: parseInt(previousScore, 10),
          change_yoy: parseInt(changeYoy, 10),
          ric_code: String(ricCode),
          sector_id: parseInt(sectorsId, 10),
          country: countryName,
          reports: reportArray,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setOpenPopUp(false);
          setCompanyName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setRicCode('');
          setSelectedDate(null);
          loadCompany();
          setOpen(false);
        })
        .catch(function (error) {
          setOpen(false);
          errorNotification(error, 500);
        });
    } else {
      const body = {
        name: companyName,
        latest_score: parseInt(latestScore, 10),
        previous_score: parseInt(previousScore, 10),
        change_yoy: parseInt(changeYoy, 10),
        email_to_all: emailToAll,
        ric_code: ricCode,
        sector_id: parseInt(sectorsId, 10),
        date_change: dateFormat,
        fileType: 'application/pdf',
        fileName: String(fileName),
        file,
        country: countryName,
      };

      AxiosInstance.post('company', body, {
        headers: { Accept: 'application/json' },
      })
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setCompanyName('');
          setLatestScore(null);
          setPreviousScore(null);
          setChangeYoy(null);
          setRicCode('');
          setSelectedDate(null);
          loadCompany();
          setOpen(false);
          setOpenPopUp(false);
        })
        .catch(function (error) {
          setOpen(false);
          errorNotification(error, 500);
        });
    }
  };

  return (
    <>
      {editRecord === null && (
        <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: '400px' }}>
          <Loader setOpen={setOpen} open={open} />
          <Grid container style={{ paddingLeft: '3%', paddingRight: '3%' }}>
            <Box alignItems="center" display="flex">
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Sector *
                </InputLabel>
                <NativeSelect onChange={(e) => setSectorId(e.target.value)} defaultValue={SectorName}>
                  <option value="null">Select Sector</option>
                  {getSectorList.map((element, index) => (
                    <option value={element.id} key={index}>
                      {element.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} style={{ marginLeft: '3%', marginRight: '3%', paddingTop: '6%', maxWidth: '100%' }}>
            <FormControl className={classes.formControl} style={{ paddingLeft: '1%' }}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Country *
              </InputLabel>
              <NativeSelect onChange={(e) => setCountryName(e.target.value)} defaultValue={countryName}>
                <option value="null">Select Country</option>
                {countryList.map((element, index) => (
                  <option value={element.name} key={index}>
                    {element.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>

          <TextField
            id="name"
            label="Enter Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            fullWidth
            style={{ paddingRight: '3%' }}
          />
          <TextField
            id="latest_score"
            label="Enter Latest Score"
            type="number"
            value={latestScore}
            onChange={(e) => setLatestScore(e.target.value)}
            fullWidth
            style={{ paddingRight: '3%' }}
          />

          <TextField
            id="previous_score"
            label="Enter Previous Score"
            value={previousScore}
            type="number"
            onChange={(e) => setPreviousScore(e.target.value)}
            fullWidth
            style={{ paddingRight: '3%' }}
          />

          <TextField
            id="change_yoy"
            label="Enter Change"
            value={changeYoy}
            type="number"
            onChange={(e) => setChangeYoy(e.target.value)}
            fullWidth
            style={{ paddingRight: '3%' }}
          />

          <TextField
            id="ric_code"
            label="Enter RIC Code"
            type="text"
            value={ricCode}
            onChange={(e) => setRicCode(e.target.value)}
            required
            fullWidth
            style={{ paddingRight: '3%' }}
          />

          <Box style={{ paddingTop: '4%' }}>
            {editRecord === null && (
              <button
                type="submit"
                onClick={submitSectorReord}
                style={btnSubmit}
                className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                // eslint-disable-next-line react/no-unknown-property
                fullWidth
              >
                Submit
              </button>
            )}

            {editRecord !== null && reportLength !== 0 && (
              <button
                type="submit"
                onClick={submitSectorReord}
                style={btnSubmit}
                className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
              >
                Update
              </button>
            )}

            {editRecord !== null && reportLength === 0 && (
              <button
                type="submit"
                onClick={submitSectorWithNewRecord}
                style={btnSubmit}
                className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
              >
                Update
              </button>
            )}
          </Box>
        </form>
      )}

      {reportLength === 0 && editRecord != null && (
        <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: '400px' }}>
          <Loader setOpen={setOpen} open={open} />
          <Grid container style={{ paddingLeft: '3%', paddingRight: '3%' }}>
            <Box alignItems="center" display="flex">
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Sector *
                </InputLabel>
                <NativeSelect onChange={(e) => setSectorId(e.target.value)} defaultValue={editRecord.sector_id}>
                  {getSectorList.map((element, index) => (
                    <option value={element.id} key={index}>
                      {element.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6} style={{ marginLeft: '3%', marginRight: '3%', paddingTop: '6%', maxWidth: '100%' }}>
            <FormControl className={classes.formControl} style={{ paddingLeft: '1%' }}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Country *
              </InputLabel>
              <NativeSelect onChange={(e) => setCountryName(e.target.value)} defaultValue={editRecord.country}>
                {countryList.map((element, index) => (
                  <option value={element.name} key={index}>
                    {element.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Grid>

          <TextField
            id="name"
            label="Enter Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
            fullWidth
            style={{ paddingRight: '3%' }}
          />
          <TextField
            id="latest_score"
            label="Enter Latest Score"
            type="number"
            value={latestScore}
            onChange={(e) => setLatestScore(e.target.value)}
            fullWidth
            style={{ paddingRight: '3%' }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="previous_score"
            label="Enter Previous Score"
            value={previousScore}
            type="number"
            onChange={(e) => setPreviousScore(e.target.value)}
            fullWidth
            style={{ paddingRight: '3%' }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="change_yoy"
            label="Enter Change"
            // variant="filled"
            value={changeYoy}
            type="number"
            onChange={(e) => setChangeYoy(e.target.value)}
            fullWidth
            style={{ paddingRight: '3%' }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            id="ric_code"
            label="Enter RIC Code"
            type="text"
            value={ricCode}
            onChange={(e) => setRicCode(e.target.value)}
            required
            fullWidth
            style={{ paddingRight: '3%' }}
          />

          <Box style={{ paddingTop: '4%' }}>
            {editRecord === null && (
              <Button
                type="submit"
                onClick={submitSectorReord}
                style={btnSubmit}
                className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                fullWidth
              >
                Submit
              </Button>
            )}

            {editRecord !== null && reportLength !== 0 && (
              <Button
                type="submit"
                onClick={submitSectorReord}
                style={btnSubmit}
                className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
              >
                Update
              </Button>
            )}

            {editRecord !== null && reportLength === 0 && (
              <Button
                type="submit"
                onClick={submitSectorWithNewRecord}
                style={btnSubmit}
                className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
              >
                Update
              </Button>
            )}
          </Box>
        </form>
      )}

      <Box className="mobile-only">
        {editRecord != null && reportLength !== 0 && (
          <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: '400px' }}>
            <Loader setOpen={setOpen} open={open} />
            <Grid container style={{ paddingLeft: '3%', paddingRight: '3%' }}>
              <Box alignItems="center" display="flex">
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="age-native-label-placeholder">
                    Sector *
                  </InputLabel>
                  <NativeSelect onChange={(e) => setSectorId(e.target.value)} defaultValue={editRecord.sector_id}>
                    {getSectorList.map((element, index) => (
                      <option value={element.id} key={index}>
                        {element.name}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={6} style={{ marginLeft: '3%', marginRight: '3%', paddingTop: '6%', maxWidth: '100%' }}>
              <FormControl className={classes.formControl} style={{ paddingLeft: '1%' }}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Country *
                </InputLabel>
                <NativeSelect onChange={(e) => setCountryName(e.target.value)} defaultValue={editRecord.country}>
                  {countryList.map((element, index) => (
                    <option value={element.name} key={index}>
                      {element.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>

            <TextField
              id="name"
              label="Enter Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              fullWidth
              style={{ paddingRight: '3%' }}
            />
            <TextField
              id="latest_score"
              label="Enter Latest Score"
              type="number"
              value={latestScore}
              onChange={(e) => setLatestScore(e.target.value)}
              fullWidth
              style={{ paddingRight: '3%' }}
            />

            <TextField
              id="previous_score"
              label="Enter Previous Score"
              value={previousScore}
              type="number"
              onChange={(e) => setPreviousScore(e.target.value)}
              fullWidth
              style={{ paddingRight: '3%' }}
            />

            <TextField
              id="change_yoy"
              label="Enter Change"
              value={changeYoy}
              type="number"
              onChange={(e) => setChangeYoy(e.target.value)}
              fullWidth
              style={{ paddingRight: '3%' }}
            />

            <TextField
              id="ric_code"
              label="Enter RIC Code"
              type="text"
              value={ricCode}
              onChange={(e) => setRicCode(e.target.value)}
              required
              fullWidth
              style={{ paddingRight: '3%' }}
            />

            {editRecord !== null && (
              <div style={{ marginTop: '32px' }}>
                <EditRecrodTable
                  editRecord={editRecord}
                  reportLength={reportLength}
                  isFromCompany="true"
                  loadCompany={loadCompany}
                  reportArray={reportArray}
                  setReportArray={setReportArray}
                  showPinColumn="true"
                />
              </div>
            )}

            <Box style={{ paddingTop: '4%' }}>
              {editRecord === null && (
                <Button
                  type="submit"
                  onClick={submitSectorReord}
                  style={btnSubmit}
                  className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                >
                  Submit
                </Button>
              )}

              {editRecord !== null && reportLength !== 0 && (
                <Button
                  type="submit"
                  onClick={submitSectorReord}
                  style={btnSubmit}
                  className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                >
                  Update
                </Button>
              )}

              {editRecord !== null && reportLength === 0 && (
                <Button
                  type="submit"
                  onClick={submitSectorWithNewRecord}
                  style={btnSubmit}
                  className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                >
                  Update
                </Button>
              )}
            </Box>
          </form>
        )}
      </Box>

      <Box className="desktop-only">
        {editRecord != null && reportLength !== 0 && (
          <Box className="CompPopup">
            <Grid container style={{ paddingLeft: '3%' }}>
              <Grid item xs={6} style={{ paddingRight: '4%' }}>
                <FormControl className={classes.formControl} style={{ paddingBottom: '2%', width: '100%' }}>
                  <InputLabel shrink htmlFor="age-native-label-placeholder">
                    Sectors
                  </InputLabel>
                  <NativeSelect onChange={(e) => setSectorId(e.target.value)} defaultValue={editRecord.sector_id}>
                    {getSectorList.map((element, index) => (
                      <option value={element.id} key={index}>
                        {element.name}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
                <Box style={{ paddingTop: '1%' }}>
                  <FormControl
                    className={classes.formControl}
                    style={{ paddingLeft: '1%', paddingBottom: '2%', width: '100%' }}
                  >
                    <InputLabel shrink htmlFor="age-native-label-placeholder">
                      Country *
                    </InputLabel>
                    <NativeSelect onChange={(e) => setCountryName(e.target.value)} defaultValue={editRecord.country}>
                      {countryList.map((element, index) => (
                        <option value={element.name} key={index}>
                          {element.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Box>
                <Box style={{ paddingTop: '1%' }}>
                  <TextField
                    id="name"
                    label="Enter Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    fullWidth
                    required
                  />
                </Box>
                <Box style={{ paddingTop: '1%' }}>
                  <TextField
                    id="latest_score"
                    label="Enter Latest Score"
                    type="number"
                    defaultValue="0"
                    value={latestScore}
                    onChange={(e) => setLatestScore(e.target.value)}
                    fullWidth
                  />
                </Box>

                <Box style={{ paddingTop: '1%' }}>
                  <TextField
                    id="previous_score"
                    label="Enter Previous Score"
                    value={previousScore}
                    defaultValue="0"
                    type="number"
                    onChange={(e) => setPreviousScore(e.target.value)}
                    fullWidth
                  />
                </Box>

                <Box style={{ paddingTop: '1%' }}>
                  <TextField
                    id="change_yoy"
                    label="Enter Change"
                    value={changeYoy}
                    defaultValue="0"
                    type="number"
                    onChange={(e) => setChangeYoy(e.target.value)}
                    fullWidth
                  />
                </Box>

                <Box style={{ paddingTop: '1%' }}>
                  <TextField
                    id="ric_code"
                    label="Enter RIC Code"
                    type="text"
                    defaultValue="0"
                    value={ricCode}
                    onChange={(e) => setRicCode(e.target.value)}
                    required
                    fullWidth
                  />
                </Box>

                <Box style={{ paddingTop: '4%' }}>
                  {editRecord === null && (
                    <Button
                      type="submit"
                      onClick={submitSectorReord}
                      style={btnSubmit}
                      className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                    >
                      Submit
                    </Button>
                  )}

                  {editRecord !== null && reportLength !== 0 && (
                    <Button
                      type="submit"
                      onClick={submitSectorReord}
                      style={{ backgroundColor: '#21309D', float: 'left' }}
                      className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                    >
                      Update
                    </Button>
                  )}

                  {editRecord !== null && reportLength === 0 && (
                    <Button
                      type="submit"
                      onClick={submitSectorWithNewRecord}
                      style={btnSubmit}
                      className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
                    >
                      Update
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '4%', paddingRight: '3%' }}>
                {editRecord === null && (
                  <div className="container boxContact">
                    <div
                      style={{
                        width: '2em',
                        height: '2em',
                        paddingLeft: '40%',
                        paddingBottom: '25%',
                        paddingTop: '20%',
                      }}
                    >
                      <PublishIcon className="desktop-only" style={{ width: '2em', height: '2em' }} />
                      <PublishIcon className="mobile-only" style={{ width: '1em', height: '1em' }} />
                    </div>
                    <Typography variant="h5" style={{ textAlign: 'center', paddingBottom: '6%' }} component="h2">
                      Upload Reports
                    </Typography>
                    <Box style={{ padding: '8%', textAlign: 'center' }}>
                      <TextField
                        id="date"
                        label="Report Date"
                        type="date"
                        defaultValue={selectedDate}
                        className={classes.textField}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box style={{ padding: '8%', textAlign: 'center' }}>
                      <input
                        type="file"
                        label="Browse"
                        // eslint-disable-next-line react/no-unknown-property
                        align="center"
                        style={{ width: '98%' }}
                        onChange={(e) => uploadFile(e)}
                      />
                    </Box>

                    <FormControlLabel
                      style={{ paddingLeft: '8%' }}
                      control={
                        <Checkbox
                          checked={checkEmail}
                          onChange={handleChangeEmailToAll}
                          name="checkedB"
                          align="center"
                          color="primary"
                        />
                      }
                      label="Send email to all users"
                    />
                  </div>
                )}

                {editRecord !== null && (
                  <EditRecrodTable
                    editRecord={editRecord}
                    reportLength={reportLength}
                    isFromCompany="true"
                    loadCompany={loadCompany}
                    reportArray={reportArray}
                    setReportArray={setReportArray}
                    showPinColumn="true"
                  />
                )}
                {editRecord !== null && reportLength === 0 && (
                  <div className="container boxContact">
                    <div
                      style={{
                        width: '2em',
                        height: '2em',
                        paddingLeft: '40%',
                        paddingBottom: '25%',
                        paddingTop: '20%',
                      }}
                    >
                      <PublishIcon className="desktop-only" style={{ width: '2em', height: '2em' }} />
                      <PublishIcon className="mobile-only" style={{ width: '1em', height: '1em' }} />
                    </div>
                    <Typography variant="h5" style={{ textAlign: 'center', paddingBottom: '6%' }} component="h2">
                      Upload Reports
                    </Typography>
                    <Box style={{ padding: '8%', textAlign: 'center' }}>
                      <TextField
                        id="date"
                        label="Report Date"
                        type="date"
                        defaultValue={setEditNewReportSelectedDate}
                        className={classes.textField}
                        onChange={(e) => setEditNewReportSelectedDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box style={{ padding: '8%', textAlign: 'center' }}>
                      {/* eslint-disable-next-line react/no-unknown-property */}
                      <input type="file" label="Browse" align="center" onChange={(e) => uploadFile(e)} />
                    </Box>

                    <FormControlLabel
                      style={{ paddingLeft: '8%' }}
                      control={
                        <Checkbox
                          checked={checkEmail}
                          onChange={handleChangeEmailToAll}
                          name="checkedB"
                          align="center"
                          color="primary"
                        />
                      }
                      label="Send email to all users"
                    />
                  </div>
                )}
                <div />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
}
