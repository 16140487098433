import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import NavBar from '../components/NavBar';
import BannerTwo from '../components/banner/BannerTwo';
import About from '../components/About';
import Team from '../components/Team';
import Portfolio from '../components/Portfolio';
import News from '../components/News';
import Footer from '../components/Footer';
import ScrollUpBtn from '../components/ScrollUpBtn';
import NewContactUS from '../components/ContactUsNew';

import * as utils from '../utils/index';
import { routePathNames } from '../utils/constants';

const HomeTwo = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (utils.getAccessToken()) {
      if (utils.getRole() === 'admin') {
        navigate(routePathNames.ADMIN_USERS);
      } else {
        navigate(routePathNames.USER_COMPANIES);
      }
    }
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Iron Blue Financials</title>
      </Helmet>

      {/* NavBar: src/components/NavBar */}
      <NavBar />

      {/* BannerTwo: src/components/banner/BannerTwo */}
      <BannerTwo />

      {/* Welcome: src/components/Welcome */}
      {/* <Welcome /> */}

      {/* About: src/components/About */}
      <About />

      {/* Services: src/components/Services */}
      {/* <Services /> */}

      {/* Team: src/components/Team */}
      <Team />

      {/* Portfolio: src/components/Portfolio */}
      <Portfolio />

      {/* Features: src/components/Features */}
      {/* <Features /> */}

      {/* Feadback: src/components/Feadback */}
      {/* <Feadback /> */}

      {/* Subscribe: src/components/Subscribe */}
      {/* <Subscribe /> */}

      {/* Pricing: src/components/Pricing */}
      {/* <Pricing /> */}

      {/* News: src/components/News */}
      <News />

      {/* Work: src/components/Work */}
      <NewContactUS />

      {/* ContactUs: src/components/ContactUs */}
      {/* <ContactUs /> */}

      {/* Partner: src/components/Partner */}
      {/* <Partner /> */}

      {/* Footer: src/components/Footer */}
      <Footer />

      {/* ScrollUpBtn: src/components/ScrollUpBtn */}
      <ScrollUpBtn />
    </>
  );
};

export default HomeTwo;
