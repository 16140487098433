import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Grid, Paper, TextField, Box, Typography } from '@mui/material';
import logoImg from '../assets/img/longWidthLogo.jpg';
import AxiosInstance from '../api/axios';
import { verifyToken } from '../api/verifyToken';
import { LOCATION_STATES, routePathNames } from '../utils/constants';
import { TOAST_OPTIONS } from '../utils/toast';

// eslint-disable-next-line react/function-component-definition
export default function ResetPassword() {
  const [password, setPassworrd] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const navigate = useNavigate();
  const notificationMessage = (message, statusValue) => {
    if (statusValue === 200) {
      toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };
  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code = message.response.status;
    if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };
  const { id } = useParams();
  const tokenValue = id;

  async function restPassword(e) {
    e.preventDefault();
    if (password !== confPassword) {
      const error = 'Password and confirm password should be same.';
      toast.error(error, TOAST_OPTIONS);
    } else {
      await AxiosInstance.put(
        'resetPassword',
        { token: tokenValue, password },
        { headers: { Accept: 'application/json' } },
      )
        .then((response) => {
          const { code, message, token } = response.data;
          localStorage.setItem('token', JSON.stringify(token));
          if (code === 200) {
            notificationMessage(message, code);
            localStorage.clear();
            setTimeout(() => {
              navigate(routePathNames.HOMEPAGE);
            }, 6000);
          } else {
            notificationMessage(message, code);
          }
        })
        .catch((error) => {
          errorNotification(error, 500);
        });
    }
  }

  // verify token from the url query
  useEffect(() => {
    verifyToken(tokenValue)
      .then((response) => {
        if (!response.auth)
          navigate(routePathNames.HOMEPAGE, { state: { toast: LOCATION_STATES.TOASTS.RESET_PASSWORD } });
      })
      .catch(() => {
        navigate(routePathNames.HOMEPAGE, { state: { toast: LOCATION_STATES.TOASTS.RESET_PASSWORD } });
      });
  }, [tokenValue, navigate]);

  const btn = { width: '100%', backgroundColor: '#21309D' };
  const cardStyle = { textAlign: 'center', paddingLeft: '20%', color: '#808080' };
  const paperStyle = { padding: 20, height: 'auto', margin: '20px auto', maxWidth: '400px' };
  return (
    <Grid style={{ padding: '3%' }}>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Box style={{ height: '30%', paddingBottom: '2%' }} fullWidth>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <img src={logoImg} style={{ width: '100%', paddingBottom: '2%' }} alt="IronBlue" fullWidth />
          </Box>
          <h2>Reset Password</h2>
        </Grid>
        <TextField
          className="mt-4"
          id="standard-password-input"
          type="password"
          onChange={(e) => setPassworrd(e.target.value)}
          label="Enter password"
          variant="outlined"
          fullWidth
        />
        <TextField
          className="mt-4"
          id="standard-password-input"
          type="password"
          onChange={(e) => setConfPassword(e.target.value)}
          label="Enter confirm password"
          variant="outlined"
          fullWidth
        />

        <button
          type="submit"
          onClick={restPassword}
          style={btn}
          className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4"
        >
          Submit
        </button>
        <Typography variant="h5">
          <Box className="mt-4" style={{ paddingLeft: '35%' }}>
            <h5>
              <a style={{ cardStyle }} href={routePathNames.HOMEPAGE}>
                Back to home
              </a>
            </h5>
          </Box>
        </Typography>
      </Paper>
    </Grid>
  );
}
