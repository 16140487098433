import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function AlertBox({ openAlertBox, setOpenAlertBox, setSelectedDate, setUploadReportModel }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpenAlertBox(true);
  };

  const handleClose = () => {
    setSelectedDate(null);
    setOpenAlertBox(false);
    setUploadReportModel(false);
  };

  const onClickOk = () => {
    setOpenAlertBox(false);
  };

  return (
    <Dialog open={openAlertBox} onClose={handleClose} aria-labelledby="responsive-dialog-title" maxWidth="xs" fullWidth>
      <DialogTitle id="responsive-dialog-title">Alert</DialogTitle>
      <DialogContent dividers>
        <DialogContentText style={{ color: '#000000' }}>
          Report for this date already exist. Do you want overwrite?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClickOk}
          style={{ lineHeight: 'normal', width: '25%', borderColor: '#21309d', border: '2px solid #21309d' }}
          className=" btn btn-dark btn-primary_submit submitButton shadow-none"
          color="primary"
          autoFocus
        >
          Overwrite
        </Button>
        <Button
          className="btn btn-dark btn-primary_submit cancelButton shadow-none"
          style={{
            lineHeight: 'normal',
            width: '20%',
            color: '#21309d',
            outlineStyle: 'none !important',
            backgroundColor: '#ffffff !important',
            fontWeight: 'bold',
            fontFamily: 'Quicksand',
            borderRadius: '18px',
            borderColor: '#21309d',
            border: '2px solid #21309d',
          }}
          onClick={handleClose}
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
      {/* <Loader setOpenAlertBox={setOpenAlertBox} open={openAlertBox}/> */}
    </Dialog>
  );
}
