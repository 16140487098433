import { Typography, Box, Divider } from '@mui/material';

// eslint-disable-next-line react/function-component-definition
export default function CompanyPositionChanges(props) {
  const { topDecile, topQuartile, bottomQuartile, bottomDecile, showDivider } = props;

  return (
    <>
      <Box style={{ paddingBottom: '16px', marginTop: '20px' }}>
        <Typography
          variant="h6"
          component="h6"
          style={{
            color: '#8c8b8b',
          }}
        >
          Top Decile
        </Typography>
        <Typography
          style={{
            color: '#000000',
          }}
          variant="body"
          component="p"
        >
          <span dangerouslySetInnerHTML={{ __html: topDecile?.replace(/\n/g, '<br />') }} />
        </Typography>
      </Box>
      <Box style={{ paddingBottom: '16px' }}>
        <Typography
          variant="h6"
          component="h6"
          style={{
            color: '#8c8b8b',
          }}
        >
          Bottom Decile
        </Typography>
        <Typography
          style={{
            color: '#000000',
          }}
          variant="body"
          component="p"
        >
          <span dangerouslySetInnerHTML={{ __html: bottomDecile?.replace(/\n/g, '<br />') }} />
        </Typography>
      </Box>
      {showDivider && <Divider style={{ borderColor: '#8c8b8b' }} />}
      <Box
        style={{
          paddingBottom: '16px',
          marginTop: showDivider ? '20px' : '0',
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          style={{
            color: '#8c8b8b',
          }}
        >
          Top Quartile
        </Typography>
        <Typography
          style={{
            color: '#000000',
          }}
          variant="body"
          component="p"
        >
          <span dangerouslySetInnerHTML={{ __html: topQuartile?.replace(/\n/g, '<br />') }} />
        </Typography>
      </Box>
      <Box style={{ paddingBottom: '16px' }}>
        <Typography
          variant="h6"
          component="h6"
          style={{
            color: '#8c8b8b',
          }}
        >
          Bottom Quartile
        </Typography>
        <Typography
          style={{
            color: '#000000',
          }}
          variant="body"
          component="p"
        >
          <span dangerouslySetInnerHTML={{ __html: bottomQuartile?.replace(/\n/g, '<br />') }} />
        </Typography>
      </Box>
    </>
  );
}
