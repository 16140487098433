import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Paper, TableBody, TableRow, TableCell, NativeSelect } from '@mui/material';
import _ from 'lodash';
import Loader from './Dashboard/BackdropLoader';
import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../api/axios';
import EmptyComponent from './Dashboard/emptyComponent';
import { routePathNames } from '../utils/constants';
import * as utils from '../utils/index';
import SectorTable from './Dashboard/AdminTable';
import UserHeader from './UserHeader';

const useStyles = makeStyles((theme) => ({
  // pageContent: {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3),
  //     overflow: 'hidden !important',
  //     boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)'
  // },"@media (max-width: 750px)": {
  //     margin: theme.spacing(5),
  //     padding: theme.spacing(3),
  //     boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  //     overflow: 'scroll !important',
  //   },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    overflow: 'hidden !important',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  },

  searchInput: {
    // width: '75%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

const headCells = [
  { id: 'name', label: 'Company' },
  { id: 'reports', label: 'Report Link', disableSorting: true },
  { id: 'latest_score', label: 'Latest Score' },
  { id: 'previous_score', label: 'Previous Score' },
  { id: 'ric_code', label: 'RIC code' },
  { id: 'change_yoy', label: 'Change' },
  { id: 'sector_id', label: 'Sector' },
  { id: 'country', label: 'Country' },
];

// eslint-disable-next-line react/function-component-definition
export default function Sectors() {
  const [title, setTitle] = useState([]);
  const [company, setCompany] = useState([]);
  // const [sectorData, setSectorData] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopUp] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [openUploadReportModel, setUploadReportModel] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);
  const [upoadReportTitleName, setUploadReportTitleName] = useState('');
  const [getSectorList, setGetsectorList] = useState([]);
  const [open, setOpen] = useState(false);
  const [userSelectedCompanyId, setUserSelectedCompnyId] = useState('');
  const [userSelectedSectorId, SetUserSelectedSectorId] = useState(4);
  const [selectedCompanyResult, setselectedCompanyResult] = useState([]);
  const [tablehead, setTableHead] = useState('');
  const [getReportUrl, setReportUrl] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const { id } = useParams();

  const loadCompany = async () => {
    setOpen(true);
    AxiosInstance.get('company/user?fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        setCompany(finalRes);
        setOpen(false);
      })
      .catch(function (error) {
        setOpen(false);
        //    setError(error)
      });
  };

  const getSectorListApi = () => {
    AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        setGetsectorList(finalRes);
        loadCompany();
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const getCompanyBySectorId = () => {
    setOpen(true);
    const userSelectedId = parseInt(userSelectedSectorId, 10);
    const url = `/sector/getCompanyBySectorId/${id}?fieldName=name&order=ASC`;
    AxiosInstance.get(url, {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        // setOpen(false);
        setIsLoaded(true);
        setselectedCompanyResult(finalRes);
      })
      .catch(function (error) {
        setOpen(false);
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getCompanyBySectorId();
    }, 200);
    getSectorListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const sectorIndex = getSectorList.findIndex((obj) => obj.id === Number(id));
    const sectorObject = getSectorList[sectorIndex];
    const name = sectorObject && sectorObject.name ? sectorObject.name : '';
    setTitle(name);
  }, [getSectorList, selectedCompanyResult, id]);

  const classes = useStyles();

  const {
    TablContainer,
    TblHead,
    // recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
  } = SectorTable(selectedCompanyResult, headCells, filterFn);

  const handleSearch = (e) => {
    const { target } = e;
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items;
        return items.filter((x) => x.name.toLowerCase().includes(target.value));
      },
    });
  };

  const openInPopUp = (item) => {
    setEditRecord(item);
    setOpenPopUp(true);
  };

  const openConfirmationModel = (item) => {
    setDeleteRecord(item);
    setOpenDeleteModel(true);
  };

  const uploadReportModel = (item) => {
    setUploadReport(item);
    const titleName = `Sector: ${item.name}`;
    setUploadReportTitleName(titleName);
    setUploadReportModel(true);
  };

  const handlePdfUrlLink = (targetObject) => {
    const body = {
      fileUrl: targetObject.url,
      fileDate: targetObject.date_change,
      reportName: targetObject.reportName,
    };
    const url = `${GOOGLE_CLOUD_BASE_URL}company/watermark/${body.reportName}?token=${utils.getAccessToken()}&fileUrl=${
      body.fileUrl
    }`;

    const newWindow = window.open(url, '_blank');
    if (newWindow == null || typeof newWindow === 'undefined') {
      document.location.href = url;
      // alert(
      //   'Please disable your pop-up blocker and click the "Open" link again.'
      // );
    } else {
      newWindow.focus();
    }
  };

  const analyticApi = (targetObject) => {
    console.log('ANYL call');
    let body = {};
    const userData = utils.decodeUserDetails();
    AxiosInstance.post(
      'analytics/company',
      (body = {
        user_id: userData.userId,
        sc_id: targetObject.company_id,
        sc_report_id: targetObject.reportId,
      }),
    )
      .then(function (response) {
        console.log('res .. -->', response);
        handlePdfUrlLink(targetObject);
      })
      .catch(function (error) {
        handlePdfUrlLink(targetObject);
        console.log('==>Err post ', error);
      });
  };

  const handleReportLink = (e) => {
    if (e !== 'null') {
      const reportsArray = _.map(company, 'reports');
      const combineArray = _.uniq(_.flatten(reportsArray));
      const targetObject = _.find(combineArray, { url: e });
      if (targetObject !== undefined) {
        analyticApi(targetObject);
      }
      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      // document.location.href = url
      // const newWindow = document.location.href
      // if (newWindow) newWindow.opener = null
    }
  };

  return (
    <>
      <UserHeader
        getSectorList={getSectorList}
        SetUserSelectedSectorId={SetUserSelectedSectorId}
        userSelectedSectorId={userSelectedSectorId}
        setUserSelectedCompnyId={setUserSelectedCompnyId}
        getCompanyBySectorId={getCompanyBySectorId}
        setselectedCompanyResult={setselectedCompanyResult}
        setTableHead={setTableHead}
      />
      <Loader setOpen={setOpen} open={open} />
      <Box style={{ paddingTop: '1%', paddingLeft: '3%' }}>
        <Typography variant="h6" style={{ textAlign: 'left' }}>
          {isLoaded ? (
            <>
              <a href={routePathNames.USER_SECTOR}> {`Sectors >`}</a>
              {` ${title}`}
            </>
          ) : null}
        </Typography>
      </Box>
      {selectedCompanyResult && selectedCompanyResult.length ? (
        <Box textAlign="center">
          <Paper className="TableView">
            <TablContainer>
              <TblHead />
              <TableBody>
                {stableSort(selectedCompanyResult, getComparator(order, orderBy)).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {/* <DropDown item={item}/> */}

                      {/* <NativeSelect
                                                // onClick={onChangeSectorId(element)}
                                                // onClick={getSectorList}
                                                onClick= {(e) =>handleReportLink(e.target.value)}
                                                // onChange={handleReportLink(this.value)}
                                                inputProps={{
                                                    name: 'Select Sector',
                                                    id: 'age-native-label-placeholder',
                                                }}
                                            >
                                                {item.reports.map(element =>
                                                    (
                                                        <option value={element.url}> {element.date_change}</option>
                                                    ))}
                                                {item.reports.length === 0 && <option value="null">None</option>}

                                            </NativeSelect> */}
                      {item.reports && item.reports.length ? (
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <option value="null">Select Report</option>
                          {item.reports.map((element, index) => (
                            // eslint-disable-next-line react/no-unknown-property
                            <option value={element.url} date={element.date_change} key={index}>
                              {' '}
                              {element.date_change}
                            </option>
                          ))}
                        </NativeSelect>
                      ) : (
                        // <span>None</span>
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <>
                            <option value="null">Select Report</option>
                            <option value="null"> None</option>
                          </>
                        </NativeSelect>
                      )}
                    </TableCell>
                    <TableCell align="center">{item.latest_score}</TableCell>
                    <TableCell align="center">{item.previous_score}</TableCell>
                    <TableCell align="center">{item.ric_code}</TableCell>
                    <TableCell align="center">{item.change_yoy}</TableCell>
                    <TableCell align="center">{item.sectorName}</TableCell>
                    <TableCell align="center">{item.country}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TablContainer>
          </Paper>
        </Box>
      ) : (
        !open && isLoaded && <EmptyComponent />
      )}
    </>
  );
}
