import { useState } from 'react';
import { Grid, Paper, TextField, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import logoImg from '../assets/img/longWidthLogo.jpg';
import AxiosInstance from '../api/axios';
import { routePathNames } from '../utils/constants';
import { TOAST_OPTIONS } from '../utils/toast';

// eslint-disable-next-line react/function-component-definition
export default function ForgetPassword() {
  const paperStyle = { padding: 20, height: 'auto', margin: '20px auto', maxWidth: '400px' };
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
      setTimeout(function () {
        navigate(routePathNames.HOMEPAGE);
      }, 6000);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };
  const mystyle = {
    backgroundColor: '#21309D',
    margin: 'none',
  };

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code = message.response.status;
    if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  function submitForgetPassword(e) {
    e.preventDefault();
    AxiosInstance.post(
      'forgotPassword',
      {
        email,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
      .then(function (response) {
        const code = response.status;
        const { message } = response.data;
        notificationMessage(message, code);
      })
      .catch(function (error) {
        errorNotification(error, 500);
      });
  }
  const fomrStyle = { padding: '40px' };
  const btnFP = { width: '100%', backgroundColor: '#21309D' };
  const cardStyle = { textAlign: 'center', paddingLeft: '20%', color: '#808080' };
  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Box style={{ height: '30%', paddingBottom: '2%' }}>
            <img src={logoImg} style={{ width: '100%', paddingBottom: '2%' }} alt="IronBlue" />
          </Box>
          <h2>Forgot Password</h2>
        </Grid>
        <TextField
          className="mt-4"
          id="outlined-basic"
          onChange={(e) => setEmail(e.target.value)}
          label="Email Address"
          variant="outlined"
          fullWidth
        />
        <button
          type="submit"
          onClick={submitForgetPassword}
          style={btnFP}
          className="btn btn btn-primary_submit btn-lg btn-block container padding-bottom-3x mb-2 mt-4 shadow-none"
        >
          Submit
        </button>
        <Typography variant="h5">
          <Box className="mt-4" style={{ paddingLeft: '35%' }}>
            <h5>
              <a style={{ cardStyle }} href={routePathNames.HOMEPAGE}>
                Back to home
              </a>
            </h5>
          </Box>
        </Typography>
      </Paper>
    </Grid>
  );
}
