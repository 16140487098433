import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AdminSectorForm from './AddSectorForm';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: '75%',
  },
  newButton: {
    position: 'absolute',
    right: '10px',
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function Popup(props) {
  const classes = useStyles();
  const { title, childern, openPopup, setOpenPopUp, editRecord, loadSector, setOpen, open } = props;
  const [mobileMenuAnchorE1, setMobileMenuAnchorE1] = useState(null);
  const [checkReportLength, setcheckReportLength] = useState(0);

  const closeMobileMenu = (event) => {
    setMobileMenuAnchorE1(null);
  };

  // console.log('==>!!! check report length ->',checkReportLength);
  return (
    <>
      {editRecord === null && (
        <div className="PopupModel">
          <Dialog open={openPopup} classes={{ paper: classes.dialogWrapper }} maxWidth="xs" fullWidth>
            {/* <ActionButton 
                color="secondary"
                onClick={() => {setOpenPopUp(false)}}
                > */}
            {/* </ActionButton> */}
            <DialogTitle style={{ paddingTop: '2%', paddingLeft: '5%' }}>
              <Box
                onClick={() => {
                  setOpenPopUp(false);
                }}
              >
                <CloseIcon style={{ float: 'right' }} />
              </Box>
              <Typography variant="h5" component="h2" style={{ paddingLeft: '3%' }}>
                Sectors
              </Typography>
            </DialogTitle>
            <DialogContent className="PopupModel" dividers>
              <AdminSectorForm
                openPopup={openPopup}
                open={open}
                setOpenPopUp={setOpenPopUp}
                loadSector={loadSector}
                editRecord={editRecord}
                setOpen={setOpen}
                sectorsData={props.sectorsData}
              />
            </DialogContent>
          </Dialog>
        </div>
      )}

      {editRecord != null && (
        <div className="PopupModel">
          <Dialog open={openPopup} classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle style={{ paddingTop: '2%', paddingLeft: '5%' }}>
              <Box
                onClick={() => {
                  setOpenPopUp(false);
                }}
              >
                <CloseIcon style={{ float: 'right' }} />
              </Box>
              <Typography variant="h5" component="h2" style={{ paddingLeft: '3%' }}>
                Sectors
              </Typography>
            </DialogTitle>
            <DialogContent className="PopupModel" dividers>
              <AdminSectorForm
                openPopup={openPopup}
                open={open}
                setOpenPopUp={setOpenPopUp}
                loadSector={loadSector}
                editRecord={editRecord}
                setOpen={setOpen}
                sectorsData={props.sectorsData}
              />
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
}
