import AxiosInstance from './api/axios';
import { routePathNames } from './utils/constants';

export default {
  setupInterceptors: () => {
    AxiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          // eslint-disable-next-line no-return-assign
          setTimeout(() => (window.location.href = routePathNames.HOMEPAGE));
        }
        return Promise.reject(error);
      },
    );

    // Add a request interceptor
    AxiosInstance.interceptors.request.use(
      (config) => {
        // you can add some information before send it.
        const token = String(JSON.parse(localStorage.getItem('token')));
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers['x-access-token'] = token;
        }
        return config;
      },
      (error) => {
        // you can add some information before send it.
        return Promise.reject(error);
      },
    );
  },
};
