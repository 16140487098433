import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import Layout from './components/Layout';
import ForgetPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';
import Users from './components/Dashboard/Users/UserTable';
import AlphaGenerationAdminView from './components/AlphaGeneration/AlphaGenerationAdminView';
import AlphaGenerationUserView from './components/AlphaGeneration/AlphaGenerationUserView';
import Adminsectors from './components/Dashboard/Sectors';
import ResearchAdminView from './components/Research/ResearchAdminView';
import Admincompanies from './components/Dashboard/Companies';
import Adminanalytics from './components/Dashboard/Analytics';
import HomeTwo from './pages/HomeTwo';
import UserView from './components/UserViewTable';
import OurApproach from './components/OurApproach';
import ResearchUserView from './components/Research/ResearchUserView';
import Companies from './components/UserViewCompanies';
import UserContactUs from './components/UserContactus';
import UserViewComp from './components/UserViewComp';
import * as utils from './utils/index';
import { routePathNames } from './utils/constants';
import Emitter from './login-event-emitter';
import useInitCookieYes from './utils/use-init-cookieyes';
import useInitGa from './utils/use-init-ga';

// Package CSS & JS
import 'bootstrap/dist/js/bootstrap.min';
import 'animate.css/animate.min.css';
import 'swiper/swiper.min.css';
import './assets/css/bootstrap.scss';

// Template SCSS & CSS Style
import './assets/css/style.scss';
import './assets/css/responsive.scss';
import './App.css';

import { createTheme } from './themes/theme';

const emotionCache = createCache({
  key: 'mui-css',
  prepend: true,
});

const App = (props) => {
  const theme = createTheme();

  const [role, setRole] = useState(utils.getRole());

  const setRoleCall = (data) => {
    console.log('setRoleCall ~ data', data);
    setRole(data);
  };

  useEffect(() => {
    Emitter.on('login-role', (newValue) => setRoleCall(newValue));
    return () => {
      Emitter.off('login-role');
    };
  });

  useEffect(() => {
    setRole(utils.getRole());
  }, []);

  useInitCookieYes();
  useInitGa();

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route exact path={routePathNames.HOMEPAGE} element={<HomeTwo />} />
              <Route exact path={routePathNames.FORGOT_PASSWORD} element={<ForgetPassword />} />
              <Route exact path={routePathNames.RESET_PASSWORD} element={<ResetPassword />} />

              {/* admin routes */}
              {role === 'admin' ? (
                <>
                  <Route exact path={routePathNames.ADMIN_USERS} element={<Users />} />
                  <Route exact path={routePathNames.ADMIN_SECTOR} element={<Adminsectors />} />
                  <Route exact path={routePathNames.ADMIN_RESEARCH} element={<ResearchAdminView />} />
                  <Route exact path={routePathNames.ADMIN_ALPHA_GENERATION} element={<AlphaGenerationAdminView />} />
                  <Route exact path={routePathNames.ADMIN_COMPANIES} element={<Admincompanies />} />
                  <Route exact path={routePathNames.ADMIN_ANALYTICS} element={<Adminanalytics />} />
                </>
              ) : (
                <>
                  {/* uncomment these lines in the future when the sector menu will be enable */}
                  {/* <Route exact path={routePathNames.USER_SECTOR} element={<UserView />} /> */}
                  {/* <Route path={`${routePathNames.USER_SECTOR}/:id`} element={<UserViewComp />} /> */}

                  {/* Remove these lines in the future when the sector menu will be enable */}
                  <Route
                    exact
                    path={routePathNames.USER_SECTOR}
                    element={<Navigate to={routePathNames.USER_COMPANIES} />}
                  />
                  <Route
                    path={`${routePathNames.USER_SECTOR}/:id`}
                    element={<Navigate to={routePathNames.USER_COMPANIES} />}
                  />
                  <Route exact path={routePathNames.USER_RESEARCH} element={<ResearchUserView />} />
                  <Route exact path={routePathNames.USER_ALPHA_GENERATION} element={<AlphaGenerationUserView />} />
                  <Route exact path={routePathNames.USER_COMPANIES} element={<Companies />} />
                  <Route exact path={routePathNames.OUR_APPROACH} element={<OurApproach />} />
                  <Route exact path={routePathNames.CONTACT_US} element={<UserContactUs />} />
                </>
              )}
            </Routes>
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
