const Validation = (values) => {
  const errors = {};
  if (!values.userFirstName) {
    errors.userFirstName = 'First name is required.';
  }

  if (!values.userLastName) {
    errors.userLastName = 'Last name is required.';
  }

  if (!values.companyName) {
    errors.companyName = 'Company name is required.';
  }

  if (!values.userEmail) {
    errors.userEmail = 'Email is required.';
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      values.userEmail,
    )
  ) {
    errors.userEmail = 'Email is invalid.';
  }

  if (!values.userPassword) {
    errors.userPassword = 'Password is required.';
  } else if (values.userPassword.length < 5) {
    errors.userPassword = 'Password must be more than 5 characters.';
  }

  if (!values.userConfPassword) {
    errors.userConfPassword = 'Confirm Password is required';
  } else if (values.userPassword !== values.userConfPassword) {
    errors.userConfPassword = 'Password and confirm password should be same';
  }

  return errors;
};

export default Validation;
