import { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import _ from 'lodash';

const image1 = require('../assets/img/blog-img1.jpg');
const image2 = require('../assets/img/blog-img2.jpg');
const image3 = require('../assets/img/blog-img3.jpg');

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  setIndex(index) {
    this.setState(
      {
        photoIndex: index,
      },
      () => {
        this.setState({
          isOpen: true,
        });
      },
    );
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const imgArr = _.map(this.props.newssData, 'image');
    // Start News Loop
    const newsdata = this.props.newssData.map((news, index) => (
      <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
        <div className="single-blog-post">
          <div className="post-thumbnail">
            <a href={news.imglink}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
              <img src={news.image} alt="blog-img" onClick={() => this.setIndex(index)} />
            </a>
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={imgArr[photoIndex]}
              // nextSrc={imgArr[(photoIndex + 1) % imgArr.length]}
              // prevSrc={imgArr[(photoIndex + imgArr.length - 1) % imgArr.length]}
              // onMovePrevRequest={() =>
              //     this.setState({
              //       photoIndex: (photoIndex + imgArr.length - 1) % imgArr.length,
              //     })
              //   }
              //   onMoveNextRequest={() =>
              //     this.setState({
              //       photoIndex: (photoIndex + 1) % imgArr.length,
              //     })
              //   }
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          )}

          {/* <div className="post-content">
                    <center><h3 style={{fontWeight: "500",fontSize:"18px"}}>{news.title}</h3></center>
                        <a href={news.btnlink} className="read-more">{news.btnname}</a>
                    </div> */}
        </div>
      </div>
    ));
    // End News Loop
    return (
      <section id="blog" className="blog-area ptb-100">
        <div className="container">
          <div className="section-title">
            <center>
              {' '}
              <h2>
                {this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b>
              </h2>
            </center>
            <p>{this.props.sectionContent}</p>
          </div>

          <div className="row justify-content-center">{newsdata}</div>
        </div>
      </section>
    );
  }
}
News.PropsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  newssData: PropTypes.arrayOf(),
};
News.defaultProps = {
  sectionTitle: 'Sample Research',
  sectionTitleSpan: '',
  sectionContent:
    'We publish scores and detailed single company and cluster reports across a range of industry sectors',
  newssData: [
    {
      // imglink: "#",
      image: image1,
      // titlelink: "#",
      title: 'Research 1',
      // btnlink: "#",
      // btnname: "Read more",
    },
    {
      // imglink: "#",
      image: image2,
      // titlelink: "#",
      title: 'Research 2',
      // btnlink: "#",
      // btnname: "Read more",
    },
    {
      // imglink: "#",
      image: image3,
      // titlelink: "#",
      title: 'Research 3',
      // btnlink: "#",
      // btnname: "Read more",
    },
  ],
};

export default News;
