import { useState, useEffect } from 'react';
import { TableRow, TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import AxiosInstance from '../../api/axios';
import EditableTableCell from './EditableTableCell';
import { tableRowMode, tableRowType } from '../../utils/constants';

const sanitizeRow = (row) => {
  return {
    title: row.title,
    lqs: row.lqs,
    hqs: row.hqs,
    alpha: row.alpha,
  };
};

const rowHasInput = (row) => {
  return Object.values(sanitizeRow(row)).filter(Boolean).length > 0;
};

const useStyles = makeStyles(() => ({
  col1: {
    width: '30%',
  },
  col2: {
    width: '23.3%',
  },
  col3: {
    width: '23.3%',
  },
  col4: {
    width: '23.3%',
  },
}));

const EditableTableRow = (props) => {
  const { row: initialRow, onDelete, isEditable } = props;

  const [row, setRow] = useState({ ...initialRow });
  const [rowClone, setRowClone] = useState({ ...initialRow });
  const [mode, setMode] = useState(initialRow.mode || tableRowMode.VIEW);

  const classes = useStyles();

  const startEdit = () => {
    setMode(tableRowMode.EDIT);
  };

  const cancelEdit = () => {
    setRowClone(row);
    setMode(tableRowMode.VIEW);
  };

  const editRow = async (editedRow) => {
    try {
      await AxiosInstance.put(`admin/tables/${editedRow.table_id}/rows/${editedRow.id}`, sanitizeRow(editedRow));

      const results = await AxiosInstance.get(`tables/${editedRow.table_id}/rows/${editedRow.id}`);
      const updatedRow = results.data.data;

      setRow(updatedRow);
      setMode(tableRowMode.VIEW);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error:', error);
    }
  };

  const deleteRow = async (deletedRow) => {
    try {
      await AxiosInstance.delete(`admin/tables/${deletedRow.table_id}/rows/${deletedRow.id}`);
      onDelete(deletedRow.id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error:', error);
    }
  };

  return (
    <TableRow>
      {row.row_type === tableRowType.FOOTER ? (
        <TableCell align="left" className={[classes.col1, 'font-bold']}>
          <span>{row.title}</span>
        </TableCell>
      ) : (
        <EditableTableCell
          value={rowClone.title}
          mode={mode}
          onChange={(value) => {
            rowClone.title = value;
          }}
          align="left"
          className={classes.col1}
        />
      )}

      <EditableTableCell
        value={rowClone.lqs}
        mode={mode}
        onChange={(value) => {
          rowClone.lqs = value;
        }}
        className={[
          classes.col2,
          row.row_type === tableRowType.FOOTER && mode === tableRowMode.VIEW ? 'font-bold' : '',
        ]}
      />
      <EditableTableCell
        value={rowClone.hqs}
        mode={mode}
        onChange={(value) => {
          rowClone.hqs = value;
        }}
        className={[
          classes.col3,
          row.row_type === tableRowType.FOOTER && mode === tableRowMode.VIEW ? 'font-bold' : '',
        ]}
      />
      <EditableTableCell
        value={rowClone.alpha}
        mode={mode}
        onChange={(value) => {
          rowClone.alpha = value;
        }}
        className={[
          classes.col4,
          row.row_type === tableRowType.FOOTER && mode === tableRowMode.VIEW ? 'font-bold' : '',
        ]}
      />
      {isEditable &&
        (mode === tableRowMode.EDIT ? (
          <TableCell align="left">
            <SaveIcon
              onClick={() => {
                editRow(rowClone);
              }}
            />
            <CloseIcon
              style={{ marginLeft: '8px' }}
              onClick={() => {
                if (rowHasInput(row) || rowHasInput(rowClone)) {
                  cancelEdit();
                } else {
                  deleteRow(row);
                }
              }}
            />
          </TableCell>
        ) : (
          <TableCell align="left">
            <EditIcon
              onClick={() => {
                startEdit();
              }}
            />
            {row.row_type !== tableRowType.FOOTER && (
              <DeleteOutlineIcon
                style={{ marginLeft: '8px' }}
                onClick={() => {
                  deleteRow(row);
                }}
              />
            )}
          </TableCell>
        ))}
    </TableRow>
  );
};

EditableTableRow.propTypes = {
  row: PropTypes.arrayOf({
    id: PropTypes.number,
    row_type: PropTypes.number,
    title: PropTypes.string,
    lqs: PropTypes.string,
    hqs: PropTypes.string,
    alpha: PropTypes.string,
    mode: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default EditableTableRow;
