import { Helmet } from 'react-helmet';
import UserHeader from '../UserHeader';
import ResearchContent from './ResearchContent';

const ResearchUserView = () => {
  return (
    <>
      <Helmet>
        <title>Latest Research - Iron Blue Financials</title>
      </Helmet>
      <UserHeader />
      <ResearchContent />
    </>
  );
};

export default ResearchUserView;
