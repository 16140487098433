import { useState, useEffect } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash/debounce';

const useStyles = makeStyles(() => ({
  col1: {
    width: '30%',
  },
  col2: {
    width: '23.3%',
  },
  col3: {
    width: '23.3%',
  },
  col4: {
    width: '23.3%',
  },
}));

const TSRTableHeadRow = (props) => {
  const { row, mode } = props;

  const [rowLocal, setRowLocal] = useState(row);
  const classes = useStyles();

  const insertLineBreaks = (text, screenWidth) => {
    if (!text) {
      return '';
    }

    if (screenWidth < 700) {
      return text.replace(/\s/g, ' <br/>');
    }

    if (screenWidth < 960) {
      return text.replace(/(\s)(?=[^\s]*$)/, ' <br/>');
    }

    if (screenWidth < 1445) {
      return text.replace(/\s/g, ' <br/>');
    }

    if (screenWidth < 1900) {
      return text.replace(/(\s)(?=[^\s]*$)/, ' <br/>');
    }

    return text;
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      const newRow = { ...row };
      newRow.lqs = insertLineBreaks(row.lqs, screenWidth);
      newRow.hqs = insertLineBreaks(row.hqs, screenWidth);
      setRowLocal(newRow);
    };

    const handleResizeDebounced = debounce(handleResize, 100);

    handleResize();

    window.addEventListener('resize', handleResizeDebounced);
    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
    };
  }, [row]);

  return (
    <TableRow loader="bubble-spin" color="#21309D" size={9}>
      <TableCell className={classes.col1} align="left">
        {row.title}
      </TableCell>
      <TableCell className={classes.col2} align="center">
        <div dangerouslySetInnerHTML={{ __html: rowLocal.lqs }} />
      </TableCell>
      <TableCell className={classes.col3} align="center">
        <div dangerouslySetInnerHTML={{ __html: rowLocal.hqs }} />
      </TableCell>
      <TableCell className={classes.col4} align="center">
        {row.alpha}
      </TableCell>
      {mode === 'edit' && (
        <TableCell align="center" style={{ width: '84px' }}>
          Manage
        </TableCell>
      )}
    </TableRow>
  );
};

export default TSRTableHeadRow;
