import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
  Button,
  Card,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  NativeSelect,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublishIcon from '@mui/icons-material/Publish';
import _ from 'lodash';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import Loader from './BackdropLoader';
import AxiosInstance, { GOOGLE_CLOUD_BASE_URL } from '../../api/axios';
import EmptyComponent from './emptyComponent';
import * as utils from '../../utils/index';
import Emitter from '../../login-event-emitter';
import UploadReportDialog from './UploadReportModel';
import ConfirmationDialog from './ConfirmationModel';
import Popup from './Popup';
import SectorTable from './AdminTable';
import Header from './header';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    overflow: 'hidden !important',
    boxShadow: '0px 8px 15px rgb(0 0 0 / 10%)',
  },

  searchInput: {
    // width: '75%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'latest_score', label: 'Latest Score' },
  { id: 'previous_score', label: 'Previous Score' },
  { id: 'change_yoy', label: 'Change' },
  { id: 'reports', label: 'Report(s) Link', disableSorting: true },
  { id: 'uploadReports', label: 'Upload Report', disableSorting: true },
  { id: 'manage', label: 'Manage', disableSorting: true },
];

// eslint-disable-next-line react/function-component-definition
export default function Sectors() {
  const [sectors, setSectors] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopUp] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [openUploadReportModel, setUploadReportModel] = useState(false);
  const [uploadReport, setUploadReport] = useState(null);
  const [upoadReportTitleName, setUploadReportTitleName] = useState('');
  const [open, setOpen] = useState(false);

  const [subject, setSubject] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [selectedSectors, setSelectedSectors] = useState([]);

  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [getTimeZoneValue, setgetTimeZoneValue] = useState('');

  const loadSector = async () => {
    AxiosInstance.get('sector?offset=1&fieldName=name&order=ASC')
      .then(function (response) {
        const finalRes = response.data.data;
        setSectors(finalRes);
        setOpen(false);
      })
      .catch(function (error) {
        setOpen(false);
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  useEffect(() => {
    setOpen(true);
    loadSector();
  }, []);
  const classes = useStyles();

  const {
    TablContainer,
    TblHead,
    // recordsAfterPagingAndSorting,
    getComparator,
    stableSort,
    order,
    orderBy,
  } = SectorTable(sectors, headCells, filterFn);

  const handleSearch = (e) => {
    const { target } = e;
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items;
        return items.filter((x) => x.name.toLowerCase().includes(target.value));
      },
    });
  };

  const openInPopUp = (item) => {
    setEditRecord(item);
    setOpenPopUp(true);
  };

  const openConfirmationModel = (item) => {
    setDeleteRecord(item);
    setOpenDeleteModel(true);
  };

  const uploadReportModel = (item) => {
    setUploadReport(item);
    const titleName = `Sector: ${item.name}`;
    setUploadReportTitleName(titleName);
    setUploadReportModel(true);

    Emitter.on('upload-report', (newValue) => setSubject(''));
    setSubject('');
    setMailBody('');
    setFile('');
    setCheckEmail(false);
    setFileName('');
    setSelectedSectors([]);
    setSelectedCountry([]);
    setSelectedDate(null);
    setSelectedDate(String(moment.tz('Etc/Universal').format('YYYY-MM-DD')));
    setgetTimeZoneValue(String(moment.tz('Etc/Universal').format('HH:mm')));
    return () => {
      Emitter.off(
        'upload-report',
        setMailBody(''),
        setSelectedSectors([]),
        setSubject(''),
        setSubject(''),
        setMailBody(''),
        setFile(''),
        setCheckEmail(false),
        setFileName(''),
        setSelectedSectors([]),
        setSelectedCountry([]),
        setSelectedDate(null),
      );
      setSelectedDate(String(moment.tz('Etc/Universal').format('YYYY-MM-DD')));
      setgetTimeZoneValue(String(moment.tz('Etc/Universal').format('HH:mm')));
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  function waterMarkPdf(body, pageType) {
    const endPoint =
      pageType === 'sector' ? `sector/watermark?fileUrl=${body.fileUrl}` : `company/watermark?fileUrl=${body.fileUrl}`;
    AxiosInstance.get(endPoint)
      .then((response) => {
        // callback(true, response);
      })
      .catch((error) => {
        // callback(false, error);
        console.log('==>Errr', error);
      });
  }

  const handleReportLink = (e) => {
    if (e !== 'null') {
      const reportsArray = _.map(sectors, 'reports');
      const combineArray = _.uniq(_.flatten(reportsArray));
      const targetObject = _.find(combineArray, { url: e });
      const body = {
        fileUrl: targetObject.url,
        fileDate: targetObject.date_change,
        reportName: targetObject.reportName,
      };
      const url = `${GOOGLE_CLOUD_BASE_URL}sector/watermark/${
        body.reportName
      }?token=${utils.getAccessToken()}&fileUrl=${body.fileUrl}`;

      const newWindow = window.open(url, '_blank');
      if (newWindow == null || typeof newWindow === 'undefined') {
        document.location.href = url;
        // alert(
        //   'Please disable your pop-up blocker and click the "Open" link again.'
        // );
      } else {
        newWindow.focus();
      }

      // const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      // document.location.href = url;
      // const newWindow = document.location.href;
      // if (newWindow) newWindow.opener = null;
    }
  };

  const openAddSectorBtnPoopUp = () => {
    setOpenPopUp(true);
    setEditRecord(null);
  };

  return (
    <>
      <Helmet>
        <title>Sectors - Iron Blue Financials</title>
      </Helmet>
      <Header />
      <Loader setOpen={setOpen} open={open} />
      <Card className="cardClass">
        <Typography variant="h4" className="cardTitle">
          Sector
        </Typography>
        <button
          type="button"
          style={{ float: 'right', backgroundColor: '#21309D' }}
          className="btn btn-dark btn-primary_submit shadow-none"
          onClick={openAddSectorBtnPoopUp}
        >
          <span className="MuiButton-label">Add Sector</span>
        </button>
      </Card>
      {sectors && sectors.length ? (
        <Box textAlign="center">
          <Paper className="TableView">
            <TablContainer>
              <TblHead />
              <TableBody>
                {stableSort(sectors, getComparator(order, orderBy)).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.latest_score}</TableCell>
                    <TableCell align="center">{item.previous_score}</TableCell>
                    <TableCell align="center">{item.change_yoy}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {/* <DropDown item={item}/> */}
                      {item.reports && item.reports.length ? (
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <option value="null">Select Report</option>
                          {item.reports.map((element, index) => (
                            // eslint-disable-next-line react/no-unknown-property
                            <option value={element.url} date={element.date_change} key={index}>
                              {' '}
                              {element.date_change}
                            </option>
                          ))}
                        </NativeSelect>
                      ) : (
                        // <span>None</span>
                        <NativeSelect
                          // onClick={onChangeSectorId(element)}
                          // onClick={getSectorList}
                          onChange={(e) => handleReportLink(e.target.value)}
                          // onChange={handleReportLink(this.value)}
                          inputProps={{
                            name: 'Select Sector',
                            id: 'age-native-label-placeholder',
                          }}
                        >
                          <>
                            <option value="null">Select Report</option>
                            <option value="null"> None</option>
                          </>
                        </NativeSelect>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Upload Report" style={{ margin: '2%' }} arrow>
                        <PublishIcon
                          onClick={() => {
                            uploadReportModel(item);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Edit" arrow>
                        <EditIcon
                          onClick={() => {
                            openInPopUp(item);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <DeleteOutlineIcon
                          onClick={() => {
                            openConfirmationModel(item);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TablContainer>
          </Paper>
          {/* {error && <h5>{error}</h5>} */}
        </Box>
      ) : (
        !open && <EmptyComponent />
      )}
      <Popup
        sectorsData={sectors}
        openPopup={openPopup}
        setOpenPopUp={setOpenPopUp}
        editRecord={editRecord}
        loadSector={loadSector}
        setOpen={setOpen}
        open={open}
      />
      <ConfirmationDialog
        deleteRecord={deleteRecord}
        openDeleteModel={openDeleteModel}
        setOpenDeleteModel={setOpenDeleteModel}
        loadSector={loadSector}
        open={open}
        setOpen={setOpen}
        apiUrl="sector/"
      />
      <UploadReportDialog
        uploadReport={uploadReport}
        openUploadReportModel={openUploadReportModel}
        setUploadReportModel={setUploadReportModel}
        open={open}
        urlReq="sector/report"
        setOpen={setOpen}
        loadSector={loadSector}
        upoadReportTitleName={upoadReportTitleName}
        setUploadReportTitleName={setUploadReportTitleName}
        subject={subject}
        setSubject={setSubject}
        mailBody={mailBody}
        setMailBody={setMailBody}
        selectedSectors={selectedSectors}
        setSelectedSectors={setSelectedSectors}
        file={file}
        setFile={setFile}
        fileName={fileName}
        setFileName={setFileName}
        checkEmail={checkEmail}
        setCheckEmail={setCheckEmail}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        setgetTimeZoneValue={setgetTimeZoneValue}
        getTimeZoneValue={getTimeZoneValue}
      />
    </>
  );
}
