import { Component } from 'react';
import { Nav, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { routePathNames } from '../utils/constants';

class SideNav extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    return (
      <>
        <Nav.Link className="nav-link_btn" onClick={this.handleShow}>
          <span />
        </Nav.Link>

        <Modal show={this.state.show} onHide={this.handleClose} className="right">
          <Modal.Header closeButton />

          <Modal.Body>
            <div className="logo">
              <a
                style={{ fontSize: '30px', fontWeight: 400, color: '#000000', textTransform: 'capitalize' }}
                href={routePathNames.HOMEPAGE}
              >
                {this.props.logotext}
                <span>{this.props.logotextspan}</span>
              </a>
            </div>

            <p>{this.props.content}</p>

            <ul className="modal-contact-info">
              {/* <li>
                                <Icofont icon="icofont-google-map" /> 
                                <b>{this.props.locationbolt}</b>{this.props.locationnormal}
                            </li> */}

              <li>
                <Icofont icon="icofont-envelope" />
                <span className="TextStyleSideNav"> {this.props.mailbolot}</span>
                {/* {this.props.mailnormal} */}
              </li>
              <li>
                <Icofont icon="icofont-linkedin" />
                <a
                  className="TextStyleSideNav LinkButton"
                  target="_blank"
                  href="https://www.linkedin.com/company/iron-blue-financials-ltd"
                  rel="noreferrer"
                >
                  {' '}
                  {this.props.phonebolt}
                </a>
                {/* {this.props.phonenormal} */}
              </li>
            </ul>

            <ul className="social-links">
              {/* <li>
                                <a href={this.props.facebooklink}><Icofont icon="icofont-facebook"/></a>
                            </li>
                            <li>
                                <a href={this.props.twitterlink}><Icofont icon="icofont-twitter"/></a>
                            </li>
                            <li>
                                <a href={this.props.instagramlink}><Icofont icon="icofont-instagram"/></a>
                            </li> */}
              {/* <li>
                                <a href={this.props.linkedinlink}><Icofont icon="icofont-linkedin"/></a>
                            </li> */}
              {/* <li>
                                <a href={this.props.pinterestlink}><Icofont icon="icofont-pinterest"/></a>
                            </li> */}
            </ul>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

SideNav.PropsTypes = {
  logotext: PropTypes.string,
  logotextspan: PropTypes.string,
  content: PropTypes.string,
  locationbolt: PropTypes.string,
  locationnormal: PropTypes.string,
  phonebolt: PropTypes.string,
  phonenormal: PropTypes.string,
  mailbolot: PropTypes.string,
  mailnormal: PropTypes.string,
  facebooklink: PropTypes.string,
  twitterlink: PropTypes.string,
  instagramlink: PropTypes.string,
  linkedinlink: PropTypes.string,
  pinterestlink: PropTypes.string,
};

SideNav.defaultProps = {
  logotext: 'Iron Blue Financials ',
  logotextspan: '',
  content:
    'Iron Blue helps equity investors predict listed company profit warnings. By deciphering the small print in annual reports we identify operational and financial pressures not highlighted by management teams.',
  // locationbolt: "27 Division St",
  // locationnormal: "New York, NY 10002, USA",
  // phonebolt: "+0 (321) 984 754",
  phonebolt: 'Iron Blue Financials',
  phonenormal: 'Give us a call',
  mailbolot: 'research@ironblue.co.uk',
  mailnormal: '24/7 online support',
  facebooklink: '#',
  twitterlink: '#',
  instagramlink: '#',
  linkedinlink: 'Add LinkdIn link',
  pinterestlink: '#',
};

export default SideNav;
