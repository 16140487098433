import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ForgetPassword from './ForgetPassword';
import AxiosInstance from '../api/axios';
import * as utils from '../utils/index';
import { routePathNames } from '../utils/constants';
import Emitter from '../login-event-emitter';
import { TOAST_OPTIONS } from '../utils/toast';

// eslint-disable-next-line react/function-component-definition
export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassworrd] = useState('');
  const navigate = useNavigate();
  const { handleModalOpen } = props;
  // useEffect(() => {
  //     if ((utils.getAccessToken() != null) || (utils.getAccessToken() != undefined)) {
  //         let role = utils.getRole();
  //         routeToPage(role);
  //     }
  // }, []);

  const checkAdminToken = () => {
    AxiosInstance.post('tokenCheck', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const responseData = response.data;
        if (response.data.auth === true) {
          navigate(routePathNames.ADMIN_USERS);
        } else {
          handleModalOpen(true);
          if (utils.getAccessToken() != null || utils.getAccessToken() !== undefined) {
            const role = utils.getRole();
          }
        }
      })
      .catch(function (error) {
        toast.info(error.response.data.message, TOAST_OPTIONS);
        return error;
        // errorNotification(error, 500);
      });
  };

  const checkUserToken = () => {
    AxiosInstance.post('tokenCheck', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const responseData = response.data;
        if (response.data.auth === true) {
          navigate(routePathNames.USER_COMPANIES);
        } else {
          handleModalOpen(true);
          if (utils.getAccessToken() != null || utils.getAccessToken() !== undefined) {
            const role = utils.getRole();
          }
        }
      })
      .catch(function (error) {
        toast.info(error.response.data.message, TOAST_OPTIONS);
        return error;
        // errorNotification(error, 500);
      });
  };

  const routeToPage = (role) => {
    if (role === 'admin') {
      setTimeout(() => {
        checkAdminToken();
      }, 2000);
    } else {
      setTimeout(() => {
        checkUserToken();
      }, 2000);
    }
  };

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage = message.response.data.message;
    const code = message.response.status;

    if (displayMessage === 'undefined') {
      toast.error('Something went wrong', TOAST_OPTIONS);
    } else if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
    setEmail('');
    setPassworrd('');
  };

  const notificationMessage = (message, statusValue) => {
    const status = statusValue;
    const displayMessage = message;
    if (status === 200) {
      toast.success(displayMessage, TOAST_OPTIONS);
    } else if (status === 406) {
      toast.warning(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  async function login(e) {
    e.preventDefault();
    const body = {
      email: email.trim(),
      password: password.trim(),
    };
    await AxiosInstance.post('signIn', body, {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const { code, token, message } = response.data;
        localStorage.setItem('token', JSON.stringify(token));
        toast.success(message, TOAST_OPTIONS);
        // notificationMessage(message, code);
        if (code === 200) {
          const role = utils.getRole(token);
          if (Emitter) {
            Emitter.emit('login-role', role);
          }
          routeToPage(role);
        } else {
          toast.success(response.message, TOAST_OPTIONS);
          // notificationMessage(message, code);
        }
      })
      .catch(function (error) {
        handleModalOpen(true);
        toast.info(error.response.data.message, TOAST_OPTIONS);
        // errorNotification(error, 500);
      });
  }

  const fomrStyle = { padding: '40px' };
  return (
    <form style={fomrStyle}>
      <div className="mb-3">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label style={{ marginBottom: 8 }}>Email</label>
        <input
          type="email"
          className="form-control loginInput"
          placeholder="Enter your email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="mb-3">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label style={{ marginBottom: 8 }}>Password</label>
        <input
          type="password"
          className="form-control loginInput"
          placeholder="Enter password"
          onChange={(e) => setPassworrd(e.target.value)}
        />
      </div>

      {/* <div className="mb-3">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input "
            id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            Remember me
          </label>
        </div>
      </div> */}

      <div className="d-grid">
        <button
          type="submit"
          onClick={login}
          className="btn btn-dark btn-primary_submit shadow-none"
          style={{ backgroundColor: '#21309D' }}
        >
          Sign In
        </button>
      </div>
      <p className="forgot-password text-end">
        {' '}
        <a href={routePathNames.FORGOT_PASSWORD}>Forgot Password?</a>
      </p>
    </form>
  );
}
