import { Component } from 'react';
import { Link } from 'react-scroll';
import { Navbar, Nav, Container } from 'react-bootstrap';
import SideNav from './SideNav';
// import logoImg from "../assets/img/PrimaryLogoRZ.png";
import logoImg from '../assets/logo/logo-blue.jpg';
import LoginModal from './LoginModal';
import Emitter from '../login-event-emitter';

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
  }

  componentDidMount() {
    const elem = document.getElementById('navbar');
    document.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        elem.classList.add('is-sticky');
      } else {
        elem.classList.remove('is-sticky');
      }
    });
    const scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: 'smooth',
      });
    };
    // eslint-disable-next-line react/no-unused-state
    this.setState({ scrollWithOffset });
  }

  handleModalOpen() {
    if (Emitter) {
      Emitter.emit('login-model', true);
    }
    this.setState((prevState) => {
      return {
        modalOpen: !prevState.modalOpen,
      };
    });
  }

  // eslint-disable-next-line class-methods-use-this
  closeNavbar() {
    if (window.matchMedia('screen and (max-width: 991px)').matches) {
      document.getElementById('collaspe-btn').click();
    }
  }

  render() {
    return (
      <Navbar
        sticky="top"
        id="navbar"
        bg="light"
        expand="lg"
        className="navbar navbar-expand-lg navbar-light bg-light mui-fixed"
        collapseOnSelect
      >
        <Container>
          <Navbar.Brand>
            <Link to="home" spy offset={-70} duration={800}>
              {/* <span>T</span>heme */}
              <img src={logoImg} alt="IronBlue" />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" id="collaspe-btn" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Item>
                <Link
                  activeClass="active"
                  to="home"
                  spy
                  offset={-70}
                  duration={800}
                  className="nav-link"
                  onClick={this.closeNavbar}
                >
                  Home
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  activeClass="active"
                  to="about"
                  spy
                  offset={-70}
                  duration={800}
                  className="nav-link"
                  onClick={this.closeNavbar}
                >
                  About Us
                </Link>
              </Nav.Item>

              {/* <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="services"
                                        spy
                                        
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        Services
                                    </Link>
                                </Nav.Item> */}

              <Nav.Item>
                <Link
                  activeClass="active"
                  to="team"
                  spy
                  offset={-70}
                  duration={800}
                  className="nav-link"
                  onClick={this.closeNavbar}
                >
                  Team
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  activeClass="active"
                  to="portfolio"
                  spy
                  offset={-70}
                  duration={800}
                  className="nav-link"
                  onClick={this.closeNavbar}
                >
                  Careers
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  activeClass="active"
                  to="blog"
                  spy
                  offset={-70}
                  duration={800}
                  className="nav-link"
                  onClick={this.closeNavbar}
                >
                  Sample Research
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  activeClass="active"
                  to="home"
                  spy
                  offset={-70}
                  duration={800}
                  className="nav-link"
                  onClick={this.handleModalOpen}
                >
                  Sign In
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  activeClass="active"
                  to="contact"
                  spy
                  offset={-70}
                  duration={800}
                  className="nav-link"
                  onClick={this.closeNavbar}
                >
                  Contact Us
                </Link>
              </Nav.Item>

              <Nav.Item>
                {/* SideNav: src/components/SideNav */}
                <SideNav />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <LoginModal modalOpen={this.state.modalOpen} handleModalOpen={this.handleModalOpen} />
        </Container>
      </Navbar>
    );
  }
}

export default NavBar;
