import { useState, useEffect } from 'react';
import UserHeader from './UserHeader';
import AxiosInstance from '../api/axios';

// eslint-disable-next-line react/function-component-definition
export default function UserView() {
  const [getSectorList, setGetsectorList] = useState([]);
  const [company, setCompany] = useState([]);
  const [selectedSectorId, setSelectedSectorId] = useState('');

  const loadCompany = async () => {
    AxiosInstance.get('company/?fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        setCompany(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const getSectorListApi = () => {
    AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC', {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        // return finalRes
        setGetsectorList(finalRes);
        setSelectedSectorId(finalRes[0].id);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  const getCompanyBySectorId = () => {
    const url = `/sector/getCompanyBySectorId/${selectedSectorId}?fieldName=name&order=ASC`;
    AxiosInstance.get(url, {
      headers: { Accept: 'application/json' },
    })
      .then(function (response) {
        const finalRes = response.data.data;
        setCompany(finalRes);
        // return finalRes
        setGetsectorList(finalRes);
      })
      .catch(function (error) {
        console.log('==>Errr sector', error);
        //    setError(error)
      });
  };

  useEffect(() => {
    // loadCompany()
    // getCompanyBySectorId();
    getSectorListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <UserHeader getSectorList={getSectorList} company={company} />
      {/* <userViewTable /> */}
    </div>
  );
}
