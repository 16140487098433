import { useState } from 'react';
import clsx from 'clsx';
import { Box, Typography, AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import AdminNavBar from './AdminNavBar';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import MoreIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import AxiosInstance from '../api/axios';
import { routePathNames } from '../utils/constants';
import whiteLogo from '../assets/logo/logo-blue.jpg';

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: 'none',
    '@media (min-width: 1024px)': {
      display: 'flex',
      textAlign: 'right',
    },
  },
  root: {
    // paddingBottom: "4%"
  },
  navLink: {
    lineHeight: '1.75',
    letterSpacing: ' 0.02857em',
  },
  mobileMenuButton: {
    '@media (min-width: 1024px)': {
      display: 'none',
    },
  },
}));

const useStylesIcon = makeStyles((theme) => ({
  sectionDesktopIcon: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const mystyle = {
  backgroundColor: '#21309D',
};

const buttonStyle = {
  color: '#ffffff',
  backgroundColor: '#21309d',
  borderColor: '#21309d',
};

// eslint-disable-next-line react/function-component-definition
export default function Header(props) {
  const navigate = useNavigate();

  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const [mobileMenuAnchorE1, setMobileMenuAnchorE1] = useState(null);
  const [value, setValue] = useState('');
  const isMobileMenuOpen = Boolean(mobileMenuAnchorE1);
  const [sectName, setSectName] = useState('');
  const {
    getSectorList,
    company,
    userSelectedSectorId,
    SetUserSelectedSectorId,
    setUserSelectedCompnyId,
    getCompanyBySectorId,
    setselectedCompanyResult,
    setTableHead,
  } = props;
  const openMobileMenu = (event) => {
    setMobileMenuAnchorE1(event.currentTarget);
  };

  const signOut = () => {
    localStorage.clear();
    navigate(routePathNames.HOMEPAGE);
  };

  const handleSelect = (e) => {
    setValue(e);
  };

  const onClickSector = (e) => {
    SetUserSelectedSectorId(e);
    const title = 'sectors';
    setTableHead(title);
    // eslint-disable-next-line no-shadow
    const getCompanyBySectorId = () => {
      const url = `/sector/getCompanyBySectorId/${e}?fieldName=name&order=ASC`;
      AxiosInstance.get(url, {
        headers: { Accept: 'application/json' },
      })
        .then(function (response) {
          const finalRes = response.data.data;
          // setCompany(finalRes);
          // return finalRes
          setselectedCompanyResult(finalRes);
        })
        .catch(function (error) {
          console.log('==>Errr sector', error);
          //    setError(error)
        });
    };

    getCompanyBySectorId();
  };

  const onClickCompany = (e) => {
    setUserSelectedCompnyId(e);
    getCompanyBySectorId();
  };
  const handleSectorClick = (item) => {
    const url = `${routePathNames.USER_SECTOR}/${item.id}`;
    navigate(url);
  };
  const closeMobileMenu = (event) => {
    setMobileMenuAnchorE1(null);
  };

  const mobileMenu = (
    <Menu anchorEl={mobileMenuAnchorE1} id="mobile-menu" keepMounted open={isMobileMenuOpen}>
      <Box style={{ float: 'right', padding: '2%' }}>
        <CloseIcon style={{ float: 'right' }} onClick={closeMobileMenu} />
      </Box>
      <MenuItem component={Link} to={routePathNames.USER_RESEARCH}>
        Latest Research
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.USER_ALPHA_GENERATION}>
        Alpha Generation
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.USER_COMPANIES}>
        Companies
      </MenuItem>
      {/* In the future we should uncomment this menu Item Sectors */}
      {/* <MenuItem component={Link} to={routePathNames.USER_SECTOR}>
        Sectors
      </MenuItem> */}
      {/* <DropdownButton
                                        alignRight
                                        title="Sectors"
                                        id="dropdown-menu-align-right"
                                        className="dropdownBtnMob"
                                        // onSelect={onClickSector}
                                    >
                                    <div className="menu-style">
                                        {getSectorList.map(element => (
                                            <Dropdown.Item onClick={() => handleSectorClick(element)} eventKey={element.id}>{element.name}</Dropdown.Item>
                                        ))}
                                    </div>
                                    </DropdownButton> */}
      <MenuItem component={Link} to={routePathNames.OUR_APPROACH}>
        Our Approach
      </MenuItem>
      <MenuItem component={Link} to={routePathNames.CONTACT_US}>
        Contact Us
      </MenuItem>
      <MenuItem onClick={signOut}>Sign Out</MenuItem>
    </Menu>
  );
  return (
    <div className={classes.root}>
      <AppBar style={mystyle} position="static">
        <Toolbar style={{ display: 'flex' }}>
          <a href={routePathNames.USER_SECTOR}>
            <img src={whiteLogo} style={{ display: 'flex' }} alt="IronBlue" />
          </a>
          <Typography variant="h5" style={{ flexGrow: 1 }}>
            <div
              className={classes.sectionDesktop}
              style={{
                float: 'right',
                textAlignLast: 'center',
                overflowY: 'hidden',
              }}
            >
              <Box className="navHedaer">
                {' '}
                <NavLink
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.USER_RESEARCH}
                  end
                >
                  <span className="MuiButton-label"> Latest Research</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.USER_ALPHA_GENERATION}
                  end
                >
                  <span className="MuiButton-label"> Alpha Generation</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.USER_COMPANIES}
                  end
                >
                  <span className="MuiButton-label"> Companies</span>
                </NavLink>
              </Box>
              {/* In the future we should uncomment this menu Item Sectors */}
              {/* <DropdownButton
                title="Sectors"
                id="dropdown-menu-align-right"
                // onSelect={onClickSector}
              >
                <div className="menu-style">
                  {getSectorList.map((element, index) => (
                    <Dropdown.Item onClick={() => handleSectorClick(element)} eventKey={element.id} key={index}>
                      {element.name}
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton> */}
              {/* <DropdownButton
                                    alignRight
                                    title="Companies"
                                    id="dropdown-menu-align-right"
                                    onSelect={onClickCompany}
                                    className="dropdownBtn"
                                >
                                    {company.map(element => (
                                        <Dropdown.Item eventKey={element.id}>{element.name}</Dropdown.Item>
                                    ))}
                                </DropdownButton> */}

              <Box className="navHedaer">
                {' '}
                <NavLink
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.OUR_APPROACH}
                  end
                >
                  <span className="MuiButton-label"> Our Approach</span>
                </NavLink>
              </Box>
              <Box className="navHedaer">
                {' '}
                <NavLink
                  className={clsx(
                    'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-colorInherit',
                    classes.navLink,
                  )}
                  to={routePathNames.CONTACT_US}
                  end
                >
                  <span className="MuiButton-label"> Contact Us</span>
                </NavLink>
              </Box>
              <Button color="inherit" onClick={signOut}>
                <Tooltip title="Sign Out" arrow>
                  <ExitToAppIcon />
                </Tooltip>
              </Button>

              {/* <Button color="inherit" component={Link} onClick={closeMobileMenu} to={routePathNames.USER_COMPANIES}>Companies</Button>
                                <Button color="inherit" component={Link} onClick={closeMobileMenu} to={routePathNames.OUR_APPROACH}>Our Approach </Button>
                                <Button color="inherit" component={Link} onClick={closeMobileMenu} to={routePathNames.CONTACT_US}>Contact Us</Button>
                                <Button color="inherit" onClick={signOut} >
                                    <Tooltip title="Sign Out" arrow><ExitToAppIcon /></Tooltip>
                                </Button> */}
            </div>
          </Typography>
          <div className={classes.mobileMenuButton}>
            <IconButton style={{ padding: '12px' }} color="inherit" onClick={openMobileMenu}>
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {mobileMenu}
    </div>
  );
}
