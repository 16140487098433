import { createTheme as createMuiTheme } from '@mui/material/styles';
import { alpha, colors } from '@mui/material';

export const createTheme = () => {
  const defaultMuiFontFamily = ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',');

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960, // default mui v5 is `900`
        lg: 1280, // default mui v5 is `1200`
        xl: 1920, // default mui v5 is `1536`
      },
    },
    palette: {
      primary: {
        main: colors.indigo[500], // #3f51b5
      },
    },
    typography: {
      body1: {
        fontFamily: ['Quicksand', 'sans-serif'].join(','),
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard', // default mui v5 is `outlined`
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard', // default mui v5 is `outlined`
        },
      },
      MuiTab: {
        styleOverrides: {
          textColorInherit: {
            opacity: '0.7', // default mui v5 is `0.6`
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: defaultMuiFontFamily, // default mui v5 is used from `typography.body1`
            '&.Mui-selected': {
              backgroundColor: alpha('#000000', 0.08), // default mui v5 is `alpha(colors.indigo[500], 0.08)`
              '&:hover': {
                backgroundColor: alpha('#000000', 0.08), // default mui v5 is `alpha(colors.indigo[500], 0.08)`
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: defaultMuiFontFamily,
            color: alpha('#000000', 0.54), // default mui v5 is `alpha('#000000', 0.6)`
          },
        },
      },
    },
  });
};
