import _ from 'lodash';
import moment from 'moment';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';

import Loader from '../BackdropLoader';
import CompanyPositionChanges from '../CompanyPositionChanges';

const QueuedReportsStep = (props) => {
  const { reports, fullScreen, handleClose, maxWidth, handlePrev, handleNext } = props;

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open
      aria-labelledby="responsive-dialog-title"
      maxWidth={maxWidth}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '640px',
            maxHeight: '750px',
          },
        },
      }}
    >
      <Box>
        <DialogTitle id="responsive-dialog-title">
          <Typography
            variant="h6"
            style={{
              textAlign: 'Left',
              paddingBottom: '10px',
              paddingLeft: '1%',
              paddingRight: '3%',
            }}
            component="span"
          >
            Step 1 of 2: Queued Reports
          </Typography>
          <CloseIcon style={{ float: 'right', cursor: 'pointer', marginTop: '4px' }} onClick={handleClose} />
        </DialogTitle>
      </Box>
      <DialogContent
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
        dividers
      >
        <Grid container>
          <Grid
            item
            sx={{
              borderBottom: {
                xs: '1px solid #e0e0e0',
                sm: '1px solid #e0e0e0',
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            {reports.map((report, index) => {
              const reportTime = moment(`${report.date_change.split('T')[0]} ${report.time}`);
              return (
                <Box
                  style={{
                    textAlign: 'Left',
                    paddingRight: '3%',
                    marginTop: '30px',
                    paddingLeft: '2%',
                  }}
                >
                  <span style={{ fontWeight: '600' }}>
                    A report on company {report.company.name} with a score change from {report.company.latest_score} to{' '}
                    {report.latest_score} is already submitted for publication on{' '}
                    {reportTime.format('YYYY-MM-DD HH:mm')} GMT, which will make the following changes:
                  </span>
                  <CompanyPositionChanges
                    topDecile={report.position_changes?.topDecile || 'No changes'}
                    topQuartile={report.position_changes?.topQuartile || 'No changes'}
                    bottomQuartile={report.position_changes?.bottomQuartile || 'No changes'}
                    bottomDecile={report.position_changes?.bottomDecile || 'No changes'}
                    showDivider={false}
                  />
                  {index < reports.length - 1 && <Divider style={{ borderColor: '#8c8b8b' }} />}
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            className="btn btn-dark btn-primary_submit cancelButton shadow-none"
            style={{
              lineHeight: 1.5,
              color: '#21309d',
              fontWeight: '600',
              outlineStyle: 'none !important',
              backgroundColor: '#ffffff !important',
              fontFamily: 'Quicksand',
              borderRadius: '18px',
              borderColor: '#21309d',
              border: '2px solid #21309d',
            }}
            onClick={handlePrev}
            color="secondary"
          >
            <span className="MuiButton-label">Cancel</span>
          </Button>
          <Button
            onClick={handleNext}
            className=" btn btn-dark btn-primary_submit submitButton shadow-none"
            color="primary"
            autoFocus
          >
            <span className="MuiButton-label">Proceed</span>
          </Button>
        </>
      </DialogActions>
      {/* <Loader setOpen={setOpen} open={open} /> */}
    </Dialog>
  );
};

export default QueuedReportsStep;
