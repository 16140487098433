import { useEffect } from 'react';

const scriptSrc = process.env.REACT_APP_COOKIEYES_SCRIPT_SRC;

const useInitCookieYes = () => {
  const id = 'cookieyes';

  useEffect(() => {
    if (!scriptSrc || document.getElementById(id)) {
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = id;
    script.src = scriptSrc;
    document.head.appendChild(script);
  }, []);
};

export default useInitCookieYes;
