import { Helmet } from 'react-helmet';
import Header from '../Dashboard/header';
import ResearchContent from './ResearchContent';

const ResearchAdminView = () => {
  return (
    <>
      <Helmet>
        <title>Latest Research - Iron Blue Financials</title>
      </Helmet>
      <Header />
      <ResearchContent />
    </>
  );
};

export default ResearchAdminView;
