import { withStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
} from '@mui/material';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// eslint-disable-next-line react/function-component-definition
export default function CustomizedDialogs({ open, setOpen, isTC }) {
  // const [Open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        {isTC === true && (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000', fontFamily: 'Calibri' }}>
                {' '}
                Terms & Conditions
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  About Iron Blue
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Iron Blue Financials Ltd is a private limited company registered in England and Wales with company
                  number 13316147. Our registered office is at 5 Poole Road, Bournemouth, England, BH2 5QL. We are
                  authorised and regulated by the UK’s Financial Conduct Authority (FCA) with registration number
                  953091.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Our Terms & Conditions
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  These Terms apply to all sections of our website (www.ironblue.co.uk) and associated research. By
                  accessing the website and/or research you agree to be bound by these Terms & Conditions. This applies
                  to Iron Blue clients and non-clients.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  www.ironblue.co.uk Website Operation
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  While our website is core to our business model (being the main method for clients to access our
                  research) and we are committed to maintaining it, we make no warranty or representation that the
                  website will be accessible at all times and we do not accept any responsibility for, and will not be
                  liable for any loss or damage arising out of or in connection with, the website being temporarily
                  unavailable or restricted for any reason or if access to the website is slow. We will not be
                  responsible for any loss you incur if the website does not work as you expect or if it contains errors
                  or defects. We accept no liability for any damage of any description caused either directly or
                  indirectly to you as a result of the provision of our research to you via the website or other means,
                  including but not limited to, damage resulting from malicious software (also known as malware such as
                  spyware, ransomware, viruses or other such sources) save in the case of our negligence, wilful default
                  or fraud. We accept no liability for the content of third party websites should we provide links to
                  them on www.ironblue.co.uk.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Website Access
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  The website and its contents are directed only at Professional Clients or Eligible Clients (as defined
                  by the FCA). The website and its contents are unsuitable and should not accessed by anyone who does
                  not fall into one of these definitions. The website is to be accessed within the UK only and it is not
                  to be accessed by persons located in countries where obtaining the research is prohibited under local
                  law. We accept no responsibility for the use of the website (or research posted on it) or access to
                  the website where it is used or accessed from outside the UK. All costs incurred in accessing the
                  website are your responsibility and you must keep your username and password confidential.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Misuse Of The Website
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  You must not attempt to gain unauthorised access to the password-protected section of the website or
                  knowingly or intentionally introduce to the website malicious software. You must comply with all local
                  and international laws when using the website.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Intellectual Property
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Iron Blue retains ownership of all Intellectual Property Rights in the research and website. Unless
                  agreed by written contract, this information may not be copied, reproduced, distributed or stored in
                  any form or any means without the express written consent of Iron Blue.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Research On www.ironblue.co.uk Website
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Neither Iron Blue nor any of our members, officers or employees shall have any liability (whether
                  direct or indirect and whether arising in contract, tort, equity or otherwise) to you for or in
                  connection with the research provided to you on this website. More detailed agreements around use of
                  the research will be included in terms of business when clients contract with Iron Blue.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Governing Law
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Any dispute or claim (including non-contractual disputes or claims) arising out of or in connection
                  with these Terms & Conditions shall be governed by and construed in accordance with the laws of
                  England. These Terms & Conditions were created on 16 July 2021 and last updated on 16 July 2021. We
                  reserve the right to update these Terms & Conditions and the services provided on our website at any
                  time and the updated version will be available on our website.
                </span>{' '}
              </Typography>
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleClose} color="primary">
             Save changes
           </Button> */}
            </DialogActions>
          </>
        )}
        {isTC === false && (
          <>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <Typography variant="h5" style={{ paddingTop: '2%', color: '#000000', fontFamily: 'Calibri' }}>
                Privacy Policy
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  This section outlines Iron Blue’s privacy policy in respect of users of this website, our research and
                  related services. Please read this section carefully so that you understand your rights regarding your
                  personal data and how we collect and use it.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  The terms “you”, “your” and “yours” when used in this privacy policy refers to any visitor to or user
                  of this website or any Iron Blue client. The term “we” includes Iron Blue, its employees and its
                  subcontractors.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Personal Data We Collect
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Most of the Personal Data we collect about you is provided directly by you. This includes the
                  following types of data:
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      {' '}
                      • Identity information including your name, employer’s name and address, email address, telephone
                      number, website log-in and contact preferences
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      • KYC and other regulatory information we are required to collect
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      • Investor profile and country/sectors of interest provided by you
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      We also monitor and retain information regarding your Iron Blue website activity, research
                      consumption and details of our interactions with you including emails and other written or verbal
                      correspondence.
                    </span>
                  </Typography>
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  How We Use Your Information
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  We collect and use information about you if you use Iron Blue’s website or interact with us directly.
                  We may, for example, keep track of your stocks or industries of interest. Iron Blue may use analytics
                  to track the usage patterns of its website and readership of its research. Iron Blue’s employees or
                  contractors have access to your Personal Data but only if this is relevant to their job. If Iron
                  Blue’s regulators demand access to your data, we will provide it. In addition, the information will be
                  held on the servers of Iron Blue’s website hosting partner (AWS) and this could be anywhere in the
                  world. We will retain your Personal Data for as long as our business relationship continues. We will
                  however not retain information any longer than is necessary to comply with legal obligations and other
                  business purposes.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Your Rights
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Under UK GDPR, you have the right to:
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      • Ask for a copy of your Personal Data and details of our handling of it
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      {' '}
                      • Request deletion of your Personal Data if retention of it by us is no longer necessary.
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      {' '}
                      • Correct or update the Personal Data.
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      {' '}
                      • Request restriction of processing of your Personal Data.
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      {' '}
                      • Withdraw consent previously granted at any time.
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      {' '}
                      • Have all our data on you transferred to a third party.
                    </span>
                  </Typography>
                  <Typography gutterBottom>
                    {' '}
                    <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                      Please contact us at research@ironblue.co.uk. We will respond to your requests within 30 days.
                    </span>
                  </Typography>
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  You also have the right to lodge a complaint regarding our processing of your data with the relevant
                  supervisory authority. In the UK, you can visit the Information Commissioner’s office website
                  (https://ico.org.uk).
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  This policy was created on 16 July 2021 and last updated on 16 July 2021.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Retention Period
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  Personal and website activity data will be held no longer than necessary.
                </span>{' '}
              </Typography>

              <Typography gutterBottom>
                <Typography
                  variant="h5"
                  style={{
                    paddingTop: '2%',
                    color: '#000000',
                    fontFamily: 'Calibri',
                    fontSize: '1.3rem',
                    fontWeight: 500,
                  }}
                >
                  Changes To Our Policy
                </Typography>
                <span style={{ color: '#000000', fontFamily: 'Calibri' }}>
                  {' '}
                  We reserve the right to update this privacy policy at any time. The updated policy will be available
                  on our website.
                </span>{' '}
              </Typography>
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button> */}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
