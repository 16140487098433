import { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import 'react-modal-video/css/modal-video.min.css';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import LoginModal from '../LoginModal';
import Emitter from '../../login-event-emitter';

const fomrStyle = {
  padding: '60px !important',
  marginTop: '2rem !important',
};

class BannerTwo extends Component {
  constructor() {
    super();
    this.state = {
      // isOpen: false,
      modalOpen: false,
      videoModalOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.openVideoModal = this.openVideoModal.bind(this);
    this.closeVideoModal = this.closeVideoModal.bind(this);
  }

  closeVideoModal() {
    this.setState({
      videoModalOpen: false,
    });
  }

  openVideoModal() {
    this.setState({
      videoModalOpen: true,
    });
  }

  openModal() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ isOpen: true });
  }

  render() {
    const handleModalOpen = () => {
      if (Emitter) {
        Emitter.emit('login-model', true);
      }
      this.setState((prevState) => {
        return {
          modalOpen: !prevState.modalOpen,
        };
      });
      // this.setState((prevState) => {
      //    return{
      //       modalOpen: !prevState.modalOpen
      //    }
      // })
    };

    return (
      <>
        <LoginModal modalOpen={this.state.modalOpen} handleModalOpen={handleModalOpen} />
        <div id="home" className="main-banner item-bg-two">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="hero-content">
                  <h5 className="animate__animated animate__fadeInDown animate__slow">{this.props.toptitle}</h5>

                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    {this.props.mainlefttitle} <span>{this.props.mainspantitle}</span> {this.props.mainrighttitle}
                  </h1>

                  <p className="animate__animated animate__zoomIn animate__slow">{this.props.content}</p>
                  <button
                    type="button"
                    onClick={handleModalOpen}
                    className="btn btn-primary animate__animated animate__fadeInDown animate__slow"
                  >
                    {' '}
                    Sign In
                  </button>
                  <button
                    type="button"
                    onClick={this.openVideoModal}
                    className="video-btn animate__animated animate__fadeInDown animate__slow"
                  >
                    <Icofont icon="icofont-play-alt-2" /> {this.props.videobtn}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="down_arrow">
            <Link activeClass="scroll_down" to="about" spy smooth offset={-70} duration={800} className="scroll_down" />
          </div>

          {/* <div className="animation-box1">
                        <img src={this.props.animationImg1} alt="sliderimage" />
                    </div>
                    <div className="animation-box2">
                        <img src={this.props.animationImg2} alt="sliderimage" />
                    </div>
                    <div className="animation-box3">
                        <img src={this.props.animationImg3} alt="sliderimage" />
                    </div>
                    <div className="animation-box4">
                        <img src={this.props.animationImg4} alt="sliderimage" />
                    </div>
                    <div className="animation-box6">
                        <img src={this.props.animationImg5} alt="sliderimage" />
                    </div> */}

          <div className="creative-box1" />
        </div>
        <Modal
          dialogClassName="video-modal"
          style={fomrStyle}
          show={this.state.videoModalOpen}
          onHide={this.closeVideoModal}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: '15px',
            }}
          >
            <span
              role="button"
              title="Close Video"
              tabIndex={0}
              className="video-icons-style"
              onClick={this.closeVideoModal}
              onKeyPress={() => {}}
            >
              <AiOutlineCloseSquare />
            </span>
            <ReactPlayer
              url="https://ironbluevideo.s3.us-east-2.amazonaws.com/ironblue1.mp4"
              width="60%"
              height="20%"
              controls
              playing
            />
          </span>
        </Modal>
      </>
    );
  }
}

BannerTwo.propTypes = {
  // animationImg1: PropTypes.string,
  // animationImg2: PropTypes.string,
  // animationImg3: PropTypes.string,
  // animationImg4: PropTypes.string,
  // animationImg5: PropTypes.string,
  toptitle: PropTypes.string,
  mainlefttitle: PropTypes.string,
  mainspantitle: PropTypes.string,
  mainrighttitle: PropTypes.string,
  content: PropTypes.string,
  // leftbtn: PropTypes.string,
  // leftbtnlink: PropTypes.string,
  // videoId: PropTypes.string,
  videobtn: PropTypes.string,
};

BannerTwo.defaultProps = {
  // animationImg1: require("../../assets/img/1.png"),
  // animationImg2: require("../../assets/img/2.png"),
  // animationImg3: require("../../assets/img/1.png"),
  // animationImg4: require("../../assets/img/5.png"),
  // animationImg5: require("../../assets/img/6.png"),
  toptitle: 'INDEPENDENT. DIFFERENTIATED. DETAILED.',
  mainlefttitle: 'ACCOUNTING &',
  mainspantitle: 'GOVERNANCE',
  mainrighttitle: 'EQUITY RESEARCH',
  content:
    'Iron Blue helps equity investors predict listed company profit warnings. By deciphering the small print in annual reports we identify operational and financial pressures not highlighted by management teams.',
  // leftbtn: 'Sign In',
  // leftbtnlink: '/resetPassword',
  // videoId: 'vr0qNXmkUJ8',
  videobtn: 'Watch Video',
};

export default BannerTwo;
