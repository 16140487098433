import { makeStyles } from '@mui/styles';
import { TableHead as MuiTableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

const useStyles = makeStyles(() => ({
  sortLabel: {
    paddingLeft: '14px',
    '& svg': {
      width: '18px',
      marginRight: '-4px',
    },
  },
}));

const TableHead = (props) => {
  const { columns, order, orderBy, setOrder, setOrderBy } = props;

  const classes = useStyles();

  const toggleSort = (name) => {
    if (orderBy === name) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrder('desc');
    }
    setOrderBy(name);
  };

  return (
    <MuiTableHead>
      <TableRow loader="bubble-spin" color="#21309D" size={9}>
        {columns.map((column) => (
          <TableCell
            key={column.name}
            align={column.align || 'center'}
            sortDirection={orderBy === column.name ? order : null}
          >
            {column.sortable ? (
              <TableSortLabel
                className={classes.sortLabel}
                active={orderBy === column.name}
                direction={orderBy === column.name ? order : 'desc'}
                onClick={() => {
                  toggleSort(column.name);
                }}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
