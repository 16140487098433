import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { toast } from 'react-toastify';
import Loader from './BackdropLoader';
import AxiosInstance from '../../api/axios';
import { TOAST_OPTIONS } from '../../utils/toast';

// eslint-disable-next-line react/function-component-definition
export default function DeleteSectorReport(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    openDeleteModel,
    setOpenDeleteModel,
    deleteRecord,
    loadSector,
    setOpen,
    open,
    editRecord,
    setReportArray,
    setOpenPopUp,
  } = props;
  const [getSectorList, setSectors] = useState([]);
  const handleClickOpen = () => {
    setOpenDeleteModel(true);
  };

  const handleClose = () => {
    setOpenDeleteModel(false);
  };

  const errorNotification = (message, statusValue) => {
    const status = 500;
    const displayMessage =
      message && message.response && message.response.data && message.response.data.message
        ? message.response.data.message
        : 'Something Went Wrong';
    const code = message.response.status;
    if (code === 406) {
      toast.info(displayMessage, TOAST_OPTIONS);
    } else {
      toast.error(displayMessage, TOAST_OPTIONS);
    }
  };

  const notificationMessage = async (message, statusValue) => {
    if (statusValue === 200) {
      await toast.success(message, TOAST_OPTIONS);
    } else {
      toast.error(message, TOAST_OPTIONS);
    }
  };

  function reportUpdate() {
    const loadSectorList = async () => {
      AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC')
        .then(function (response) {
          const finalRes = response.data.data;
          setSectors(finalRes);
          const sectorIndex = getSectorList.findIndex((obj) => obj.id === Number(editRecord.id));
          const sectorObject = getSectorList[sectorIndex];
          const name = sectorObject && sectorObject.name ? sectorObject.name : '';
          setReportArray(name);
        })
        .catch(function (error) {
          console.log('==>Errr sector', error);
          //    setError(error)
        });
    };
  }

  const deleteSectorReord = (e) => {
    e.preventDefault();
    setOpen(true);

    if (deleteRecord != null) {
      const { reportId } = deleteRecord;
      AxiosInstance.delete(`sector/report/${reportId}`, {
        headers: { Accept: 'application/json' },
      })
        .then(function (response) {
          const code = response.status;
          const { message } = response.data;
          notificationMessage(message, code);
          setOpenDeleteModel(false);
          loadSector();
          setOpen(false);
          AxiosInstance.get('sector/user?offset=1&fieldName=name&order=ASC')
            .then(function (res) {
              const finalRes = res.data.data;
              setSectors(finalRes);
              const sectorIndex = finalRes.findIndex((obj) => obj.id === Number(editRecord.id));
              const sectorObject = finalRes[sectorIndex];
              const reportList = sectorObject && sectorObject.reports ? sectorObject.reports : '';
              if (reportList.length === 0) {
                setOpenPopUp(false);
              }
              setReportArray(reportList);
            })
            .catch(function (error) {
              console.log('==>Errr sector', error);
              //    setError(error)
            });
        })
        .catch(function (error) {
          errorNotification(error, 500);
          setOpenDeleteModel(false);
        });
    } else {
      toast.error('Something Went wrong', TOAST_OPTIONS);
    }
  };

  return (
    <div>
      <Dialog
        open={openDeleteModel}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">Delete Report</DialogTitle>
        <DialogContent dividers>
          <DialogContentText style={{ color: '#000000' }}>
            Do you really want to delete sector report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteSectorReord}
            style={{ lineHeight: 'normal', width: '20%', borderColor: '#21309d', border: '2px solid #21309d' }}
            className=" btn btn-dark btn-primary_submit submitButton shadow-none"
            color="primary"
            autoFocus
          >
            Ok
          </Button>
          <Button
            className="btn btn-dark btn-primary_submit cancelButton shadow-none"
            style={{
              lineHeight: 'normal',
              width: '20%',
              color: '#21309d',
              outlineStyle: 'none !important',
              backgroundColor: '#ffffff !important',
              fontWeight: 'bold',
              fontFamily: 'Quicksand',
              borderRadius: '18px',
              borderColor: '#21309d',
              border: '2px solid #21309d',
            }}
            onClick={handleClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
        <Loader setOpen={setOpen} open={open} />
      </Dialog>
    </div>
  );
}
